import { SetStateAction, useRef, useState } from 'react'
import { InstructionFormValues } from './types'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import {
  useCreateInstruction,
  useGetInstructionLists,
} from '../../../../../../services/api/instructions/instructions'
import {
  InstructionPhaseDTO,
  PrescriptionItemDTO,
  PrescriptionItemPhaseDTO,
  ProtocolPhaseDTO,
  SimplifiedInstructionDTO,
} from '../../../../../../services/api/types'
import { useQueryClient } from 'react-query'
import { useGetInstructionPhases } from '../../../../../../services/api/instruction-phases/instruction-phases'
import _ from 'lodash'
import { useClickOutside } from '../../../../../../utils/hooks'
import { isMultiPhases } from '../utils'
import useScrollDropdownIntoView from '../hooks'

const CreateInstructionFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const useInstructionDropdownLogic = (
  onInstructionSelect: (
    phases: PrescriptionItemPhaseDTO[] | ProtocolPhaseDTO[],
    instructionId: string
  ) => void,
  onSelectedUsedInstructionIdChange: (value: string) => void,
  onIsMultiPhasesCheckedChange: (value: boolean) => void,
  phases?: PrescriptionItemPhaseDTO[] | ProtocolPhaseDTO[],
  productId?: string,
  chosenProductItems?: PrescriptionItemDTO[],
  selectedUsedInstructionId?: string
) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const queryClient = useQueryClient()

  useClickOutside({ ref: dropdownRef, onClick: () => setIsDropdownOpen(false) })
  useScrollDropdownIntoView({ isDropdownOpen, dropdownRef })

  const formik = useFormik<InstructionFormValues>({
    initialValues: {
      name: '',
    },
    validationSchema: CreateInstructionFormSchema,
    onSubmit: values => {
      // Handle form submission
      handleAddInstructionClick(values)
    },
  })

  const { data: instructions } = useGetInstructionLists(productId, {
    query: {
      queryKey: ['instructionLists', productId],
      enabled: !!productId,
      onSuccess: handleFetchInstructionsSuccess,
    },
  })

  useGetInstructionPhases(selectedUsedInstructionId, {
    query: {
      enabled: !!selectedUsedInstructionId,
      onSuccess: handleFetchInstructionPhasesSuccess,
    },
  })

  const { mutateAsync: createInstruction } = useCreateInstruction({
    mutation: {
      onSuccess: data => {
        handleCreateInstructionSuccess(data.id)
      },
      onError: handleCreateInstructionError,
    },
  })

  // Functions

  const resetInstructionNameInput = () => {
    formik.setFieldValue('name', '')
  }

  function handleFetchInstructionsSuccess(data: SimplifiedInstructionDTO[]) {
    const defaultInstruction = data.find(instruction => instruction.isDefault)
    const isProductChosen = chosenProductItems?.some(
      item => item.product.id === productId
    )

    if (defaultInstruction && !isProductChosen && !selectedUsedInstructionId) {
      onSelectedUsedInstructionIdChange(defaultInstruction.id)
    }
  }

  function handleFetchInstructionPhasesSuccess(data: InstructionPhaseDTO[]) {
    onInstructionSelect(data, selectedUsedInstructionId)
    if (isMultiPhases(data)) {
      onIsMultiPhasesCheckedChange(true)
    } else {
      onIsMultiPhasesCheckedChange(false)
    }
  }

  function handleCreateInstructionSuccess(instructionId: string) {
    toast('Le dosage personnalisé a été créé avec succès')
    queryClient.invalidateQueries(['instructionLists', productId])
    onSelectedUsedInstructionIdChange(instructionId)
    setIsDropdownOpen(false)
    resetInstructionNameInput()
  }

  function handleCreateInstructionError() {
    toast('Il y a eu un problème lors de la création du dosage personnalisé')
  }

  async function handleAddInstructionClick(values: InstructionFormValues) {
    if (!productId) return
    await createInstruction({
      productId,
      data: {
        name: values.name,
        phases,
      },
    })
  }

  function handleIsDropdownOpenChange(value: SetStateAction<boolean>) {
    setIsDropdownOpen(value)
    resetInstructionNameInput()
  }

  async function handleInstructionSelect(instructionId: string) {
    onSelectedUsedInstructionIdChange(instructionId)
    setIsDropdownOpen(false)
  }

  function getInstructions() {
    return instructions
      ? _.sortBy(instructions, [
          instruction => !instruction.isDefault,
          instruction => -new Date(instruction.createdAt),
        ])
      : []
  }

  return {
    isDropdownOpen,
    onIsDropdownOpenChange: handleIsDropdownOpenChange,
    formik,
    instructions: getInstructions(),
    onInstructionTemplateSelect: handleInstructionSelect,
    dropdownRef,
  }
}

export default useInstructionDropdownLogic
