/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type IntakeAnyPeriodType =
  | 'ONCE_PER_DAY'
  | 'TWICE_PER_DAY'
  | 'THREE_TIMES_PER_DAY'
  | 'FOUR_TIMES_PER_DAY'
  | 'FIVE_TIMES_PER_DAY'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntakeAnyPeriodType = {
  ONCE_PER_DAY: 'ONCE_PER_DAY' as IntakeAnyPeriodType,
  TWICE_PER_DAY: 'TWICE_PER_DAY' as IntakeAnyPeriodType,
  THREE_TIMES_PER_DAY: 'THREE_TIMES_PER_DAY' as IntakeAnyPeriodType,
  FOUR_TIMES_PER_DAY: 'FOUR_TIMES_PER_DAY' as IntakeAnyPeriodType,
  FIVE_TIMES_PER_DAY: 'FIVE_TIMES_PER_DAY' as IntakeAnyPeriodType,
  UNKNOWN: 'UNKNOWN' as IntakeAnyPeriodType,
}
