import { ProductItemLineProps } from './props'
import { useGetProductByIdOrHandle } from '../../../../../../services/api/product/product'
import {
  PhaseTable,
  ProductItem,
  ProductTitle,
  ProductUnit,
  TableCell,
} from './style'
import {
  mapPhasePDFDurationToLabel,
  mapPhasePeriodToLabel,
} from '../../../../../../components/prescription/product-list/productCardMarketplace/product-phases/utils'
import { IntakePeriodType } from '../../../../../../services/api/types'
import {
  ProductImage,
  ProductImageContainer,
  ProductInfo,
  ProductNameContainer,
} from '../style'
import Intake from './intake'
import { getProductUnit } from '../../../../../../components/prescription/product-list/productCardMarketplace/product-phases/intake-line/utils'

const ProductItemLine = ({
  item,
  onColumnRefsUpdate,
  columnWidths,
}: ProductItemLineProps) => {
  const { data: product } = useGetProductByIdOrHandle(item.productHandle)

  const productUnit = getProductUnit(2, product)

  return (
    <ProductItem>
      <ProductInfo>
        <ProductImageContainer>
          <ProductImage src={product?.coverImage} alt='' />
        </ProductImageContainer>
        <ProductNameContainer>
          <ProductTitle>{product?.name}</ProductTitle>
          <ProductUnit>{`Quantité en ${productUnit}`}</ProductUnit>
        </ProductNameContainer>
      </ProductInfo>
      <PhaseTable>
        <tbody>
          {item.phases.map((phase, phaseIndex) => (
            <tr key={phaseIndex}>
              {[
                phase.name,
                mapPhasePDFDurationToLabel(phase.duration),
                mapPhasePeriodToLabel(phase.period),
                <Intake
                  intakes={phase.intakes}
                  period={IntakePeriodType.MORNING}
                />,
                <Intake
                  intakes={phase.intakes}
                  period={IntakePeriodType.NOON}
                />,
                <Intake
                  intakes={phase.intakes}
                  period={IntakePeriodType.AFTERNOON}
                />,
                <Intake
                  intakes={phase.intakes}
                  period={IntakePeriodType.EVENING}
                />,
                <Intake
                  intakes={phase.intakes}
                  period={IntakePeriodType.BEDTIME}
                />,
                <Intake intakes={phase.intakes} />,
              ].map((content, colIndex) => (
                <TableCell
                  key={colIndex}
                  ref={el => onColumnRefsUpdate(phaseIndex + 1, colIndex, el)}
                  style={{
                    width: columnWidths[colIndex] || 'auto',
                  }}
                  isFirstColumn={colIndex === 0}
                  isLastLine={phaseIndex === item.phases.length - 1}
                >
                  {content}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </PhaseTable>
    </ProductItem>
  )
}

export default ProductItemLine
