import { DeletePrescriptionModalProps } from './props'
import { ConfirmationDialog } from '../../.../../../../ui'

const DeletePrescriptionModal = ({
  setIsOpen,
  isOpen,
  onClick,
}: DeletePrescriptionModalProps) => {
  function onClose() {
    setIsOpen(false)
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirmClick={onClick}
      width='33rem'
      title='En supprimant cette recommandation, votre client ne pourra plus y
          accéder'
      buttonText='Supprimer'
      showCloseButton
    />
  )
}

export default DeletePrescriptionModal
