/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { ProtocolPhaseDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getProtocolProductPhases = (
  protocolId: number,
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolPhaseDTO[]>(
    {
      url: `/protocols/${protocolId}/products/${productId}/phases`,
      method: 'get',
    },
    options
  )
}

export const getGetProtocolProductPhasesQueryKey = (
  protocolId: number,
  productId: string
) => [`/protocols/${protocolId}/products/${productId}/phases`]

export const useGetProtocolProductPhases = <
  TData = AsyncReturnType<typeof getProtocolProductPhases>,
  TError = unknown
>(
  protocolId: number,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getProtocolProductPhases>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetProtocolProductPhasesQueryKey(protocolId, productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getProtocolProductPhases>
  > = () => getProtocolProductPhases(protocolId, productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getProtocolProductPhases>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(protocolId && productId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}
