import { IntakePeriodDropdownProps } from './props'
import {
  Checkbox,
  Container,
  Dropdown,
  PeriodOption,
  PeriodOptionWrapper,
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  TriggerButton,
} from './style'
import { useRef, useState } from 'react'
import lodash from 'lodash'
import {
  getFilteredIntakeSpecificTimingPeriodOptions,
  hasLineAnAnyIntakePeriod,
  renderTriggerButtonName,
} from './utils'
import { useClickOutside } from '../../../../../../../utils/hooks'
import useScrollDropdownIntoView from '../../hooks'
import { Option } from '../../../../../../../utils/constants'
import {
  IntakeAnyPeriodType,
  IntakePeriodType,
  PrescriptionItemPhaseIntakeLineDTO,
  ProtocolIntakeLineDTO,
} from '../../../../../../../services/api/types'
import IconChevronDown from '../../../../../../ui/icon/icons/IconChevronDown'
import IconCalendar from '../../../../../../ui/icon/icons/IconCalendar'
import { getIntakePeriodFrequencyOptions } from '../../utils'
import IconClock from '../../../../../../ui/icon/icons/IconClock'
import IconStroke from '../../../../../../ui/icon/icons/IconStroke'

const IntakePeriodDropdown = ({
  onIntakePeriodSelect,
  phaseIndex,
  intakeIndex,
  selectedPeriods,
  intakes,
  intakePeriodTriggerButtonName,
}: IntakePeriodDropdownProps) => {
  // Attributes
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>()

  const hasIntakesAnAnyIntakePeriod = intakes.some(intake =>
    intake.lines.some(line => hasLineAnAnyIntakePeriod(line))
  )

  const hasIntakeAnAnyIntakePeriod = intakes[intakeIndex].lines.some(line =>
    hasLineAnAnyIntakePeriod(line)
  )

  const showIntakeAnyPeriodSection =
    (hasIntakeAnAnyIntakePeriod && hasIntakesAnAnyIntakePeriod) ||
    !hasIntakesAnAnyIntakePeriod

  useClickOutside({ onClick: () => setIsDropdownOpen(false), ref: dropdownRef })
  useScrollDropdownIntoView({ isDropdownOpen, dropdownRef })

  // Functions
  function handleFrequencyOptionClick(option: Option) {
    setIsDropdownOpen(false)
    onIntakePeriodSelect(phaseIndex, intakeIndex, [
      { period: option.value as keyof typeof IntakePeriodType },
    ])
  }

  const toggleTimeSelect = (option: Option) => {
    const isSelected = selectedPeriods.some(
      period => period.period === option.value
    )
    let newSelectedPeriods: (
      | PrescriptionItemPhaseIntakeLineDTO
      | ProtocolIntakeLineDTO
    )[] = []
    if (isSelected) {
      newSelectedPeriods = selectedPeriods.filter(
        period => period.period !== option.value
      )
    } else {
      const order = [
        IntakePeriodType.MORNING,
        IntakePeriodType.NOON,
        IntakePeriodType.AFTERNOON,
        IntakePeriodType.EVENING,
        IntakePeriodType.BEDTIME,
      ]
      newSelectedPeriods = [
        ...selectedPeriods.filter(
          period =>
            !Object.values(IntakeAnyPeriodType).includes(
              period.period as keyof typeof IntakeAnyPeriodType
            )
        ),
        {
          period: option.value as keyof typeof IntakePeriodType,
        },
      ]
      newSelectedPeriods = lodash.sortBy(newSelectedPeriods, period =>
        lodash.indexOf(order, period.period)
      )
    }
    onIntakePeriodSelect(phaseIndex, intakeIndex, newSelectedPeriods)
  }

  // Render
  return (
    <Container>
      <TriggerButton onClick={() => setIsDropdownOpen(true)}>
        {intakePeriodTriggerButtonName}
        <IconChevronDown />
      </TriggerButton>

      <Dropdown ref={dropdownRef} $isOpen={isDropdownOpen}>
        {showIntakeAnyPeriodSection && (
          <Section>
            <SectionHeader>
              <IconCalendar height={18} width={18} fill='#666666' />
              <SectionTitle>A tout moment</SectionTitle>
            </SectionHeader>
            <SectionContent>
              {getIntakePeriodFrequencyOptions().map(option => (
                <PeriodOptionWrapper>
                  <PeriodOption
                    key={option.value}
                    isSelected={selectedPeriods.some(
                      item => item.period === option.value
                    )}
                    onClick={() => handleFrequencyOptionClick(option)}
                  >
                    {option.label}
                  </PeriodOption>
                </PeriodOptionWrapper>
              ))}
            </SectionContent>
          </Section>
        )}
        <Section borderTop={showIntakeAnyPeriodSection}>
          <SectionHeader>
            <IconClock height={18} width={18} fill='#666666' />
            <SectionTitle>Horaires spécifiques</SectionTitle>
          </SectionHeader>
          <SectionContent>
            {getFilteredIntakeSpecificTimingPeriodOptions(
              intakes,
              intakeIndex
            ).map(option => (
              <PeriodOptionWrapper>
                <PeriodOption
                  key={option.value}
                  onClick={() => toggleTimeSelect(option)}
                >
                  <Checkbox
                    isChecked={selectedPeriods.some(
                      item => item.period === option.value
                    )}
                  >
                    {selectedPeriods.some(
                      item => item.period === option.value
                    ) && <IconStroke height={10} width={10} stroke='#fff' />}
                  </Checkbox>
                  {option.label}
                </PeriodOption>
              </PeriodOptionWrapper>
            ))}
          </SectionContent>
        </Section>
      </Dropdown>
    </Container>
  )
}

export default IntakePeriodDropdown
