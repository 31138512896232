/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { ProductInformationDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getProductInformationsByProductId = (
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductInformationDTO[]>(
    { url: `/products/${productId}/informations`, method: 'get' },
    options
  )
}

export const getGetProductInformationsByProductIdQueryKey = (
  productId: string
) => [`/products/${productId}/informations`]

export const useGetProductInformationsByProductId = <
  TData = AsyncReturnType<typeof getProductInformationsByProductId>,
  TError = unknown
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getProductInformationsByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetProductInformationsByProductIdQueryKey(productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getProductInformationsByProductId>
  > = () => getProductInformationsByProductId(productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getProductInformationsByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const copyAllProductsToAlgolia = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/algolia/import-products`, method: 'post' },
    options
  )
}

export const useCopyAllProductsToAlgolia = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof copyAllProductsToAlgolia>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof copyAllProductsToAlgolia>,
    TVariables
  > = () => {
    return copyAllProductsToAlgolia(requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof copyAllProductsToAlgolia>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions)
}
