import { NrvCellProps } from './props'

export const NrvCell = ({ nrv }: NrvCellProps) => {
  return (
    <div>
      {nrv?.percentages?.map((percentage, index) => (
        <div key={index}>{`${nrv?.value} (${percentage}%)`}</div>
      ))}
    </div>
  )
}
