import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  StyleSheet,
  Text,
} from '@react-pdf/renderer'
import { Color } from '../../../utils/constants'
import BookletFooter from './BookletFooter'
import BookletHeader from './BookletHeader'
import BookletProProducts from './BookleMeProducts'
import { IBookletProps } from './props'
import BookletIntakesTable from './booket-intakes-table'
import { ProductWithTakes } from '../../../pages/prescriptions/new-prescription-marketplace/types'

const styles = StyleSheet.create({
  page: { paddingTop: 20, paddingBottom: 20 },
  section: { marginTop: 8, marginBottom: 8, marginLeft: 20, marginRight: 20 },
  sectionQuestion: { margin: 3 },
  title: { color: Color.DEEP_SEA, fontSize: 14 },
  subTitle: { color: '#777', fontSize: 12 },
  answer: { color: '#333', fontSize: 10, marginLeft: 10, marginTop: 3 },
  productListWrapper: {
    flexDirection: 'column',
    gap: 10,
  },
  productListTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingHorizontal: '20px',
  },
})

export const Pdf = ({
  products,
  prescriberFirstName,
  prescriberLastName,
  title,
  customMessage,
  discountPercentage,
  discountTotal,
  total,
  prescriptionId,
  qrCodeUrl,
  prescription,
  lastStatusPhases,
}: IBookletProps) => {
  const {
    affiliatedProducts,
    nonAffiliatedProducts,
  }: {
    affiliatedProducts: ProductWithTakes[]
    nonAffiliatedProducts: ProductWithTakes[]
  } = products.reduce(
    (acc, product) => {
      if (product.product.brand.isAffiliated) {
        acc.affiliatedProducts.push(product)
      } else {
        acc.nonAffiliatedProducts.push(product)
      }
      return acc
    },
    { affiliatedProducts: [], nonAffiliatedProducts: [] }
  )

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <BookletHeader
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          title={title}
          customMessage={customMessage}
          prescriptionId={prescriptionId}
          prescription={prescription}
        />
        <View
          style={{
            marginTop: '-20px',
          }}
        >
          {nonAffiliatedProducts.length > 0 && (
            <View style={styles.productListWrapper}>
              <Text style={styles.productListTitle}>
                {`Produit${
                  nonAffiliatedProducts.length > 1 ? 's' : ''
                } disponible${nonAffiliatedProducts.length > 1 ? 's' : ''}`}
                :
              </Text>
              {nonAffiliatedProducts.map(product => (
                <BookletProProducts
                  key={product.product.id}
                  product={product}
                  discountPercentage={discountPercentage}
                />
              ))}
            </View>
          )}
          {affiliatedProducts.length > 0 && (
            <View style={styles.productListWrapper}>
              <Text style={styles.productListTitle}>
                {`Produit${
                  affiliatedProducts.length > 1 ? 's' : ''
                } non disponible${
                  affiliatedProducts.length > 1 ? 's' : ''
                } à la vente sur Simplycure:`}
              </Text>
              {affiliatedProducts.map(product => (
                <BookletProProducts
                  key={product.product.id}
                  product={product}
                  discountPercentage={discountPercentage}
                />
              ))}
            </View>
          )}
        </View>

        <BookletFooter
          discountTotal={discountTotal}
          discountPercentage={discountPercentage}
          total={total}
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          prescriptionId={prescriptionId}
          dataUrl={qrCodeUrl}
        />
      </Page>
      <Page size='A4' style={styles.page}>
        <BookletIntakesTable
          lastStatusPhases={lastStatusPhases}
          products={products}
        />
      </Page>
    </Document>
  )
}

export const Doc = ({
  products,
  prescriberFirstName,
  prescriberLastName,
  title,
  customMessage,
  discountPercentage,
  discountTotal,
  total,
  prescriptionId,
  qrCodeUrl,
}) => {
  return (
    <PDFDownloadLink
      document={
        <Pdf
          products={products}
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          title={title}
          customMessage={customMessage}
          discountPercentage={discountPercentage}
          discountTotal={discountTotal}
          total={total}
          prescriptionId={prescriptionId}
          qrCodeUrl={qrCodeUrl}
        />
      }
      fileName={`filename.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Chargement...' : 'Exporter le fichier'
      }
    </PDFDownloadLink>
  )
}
