import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { PricingSectionProps } from './props'

const PricingSection = ({
  product,
  discountPercentage,
  quantity,
}: PricingSectionProps) => {
  const variantPrice = product?.takeInfo?.product?.variantPrice
  const isAffiliated = product?.product?.brand?.isAffiliated
  const discountedPrice =
    variantPrice - (variantPrice * discountPercentage) / 100

  const totalPrice = isAffiliated
    ? variantPrice * quantity
    : discountedPrice * quantity

  const showDiscountedPrice = !isAffiliated && discountPercentage

  return (
    <View style={styles.pricingSection}>
      <View style={{ alignItems: 'flex-end' }}>
        <Text
          style={
            showDiscountedPrice ? styles.originalPrice : styles.productVariantPrice
          }
        >
          {`${isAffiliated ? 'Prix indicatif: ' : ''}${variantPrice?.toFixed(2)}€`}
        </Text>
        {showDiscountedPrice && (
          <Text style={styles.discountedPrice}>
            {discountedPrice?.toFixed(2)}€ ({discountPercentage}%)
          </Text>
        )}
      </View>
      <Text style={styles.totalPrice}>
        Total pour {quantity} unité
        {quantity > 1 ? 's' : ''}: {totalPrice?.toFixed(2)}€
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  pricingSection: {
    width: '20%',
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },
  productVariantPrice: {
    fontSize: '9px',
    marginBottom: '5px',
  },
  originalPrice: {
    fontSize: '9px',
    textDecoration: 'line-through',
    marginBottom: '5px',
  },
  discountedPrice: {
    fontSize: '9px',
    marginBottom: '5px',
  },
  totalPrice: {
    fontSize: '9px',
    fontWeight: 'bold',
    marginTop: 'auto',
  },
})

export default PricingSection
