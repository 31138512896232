import styled from 'styled-components/macro'

export const PhasesContainer = styled.div`
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  background: #f8f9fc;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`

export const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const PhaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PhaseActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const PhaseWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 1rem;
  background-color: #f2f4f9;
  gap: 0.75rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid #e7e9f4;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: flex-start;
`

export const PhaseDetailsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  height: fit-content;
`

export const VerticalSeparator = styled.div`
  width: 0.0625rem;
  border-radius: 6.25rem;
  background-color: #d9dbe6;
  align-self: stretch;
`

export const AddPhaseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 0.25rem;
`

export const AddPhaseButton = styled.button`
  border-width: 0;
  color: #65b293;
  background-color: #f8f9fc;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

export const HorizontalSeparator = styled.div`
  height: 0.0625rem;
  border-radius: 6.25rem;
  background-color: #d9dbe6;
  justify-self: stretch;
  flex: 1;
`

export const IntakesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const AddIntakeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const AddIntakeButton = styled.button`
  border-width: 0;
  color: #8f95b2;
  background-color: #f2f4f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

export const ColoredTextDiv = styled.div`
  color: #393360;
`

export const customStyles = {
  control: base => ({
    ...base,
    width: 'var(--dynamic-width-phase-duration)',
    minWidth: '7rem',
    transition: 'width 0.2s ease',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'none',
    fontFamily: 'Inter',
    fontSize: '.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    cursor: 'pointer',
  }),
  menu: provided => ({
    ...provided,
    border: '.0625rem solid #E7E9F4',
    borderRadius: '.5rem',
    boxShadow:
      '0rem .125rem .5rem 0rem rgba(40, 41, 61, 0.04), 0rem 1.625rem 2.125rem 0rem rgba(96, 97, 112, 0.06)',
    fontFamily: 'Poppins',
    fontSize: '.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
    width: '11rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#F7F9FD' : 'transparent', // Hover color
    color: state.isFocused ? '#000' : '#333', // Optional text color change
    borderRadius: '.25rem', // Rounded corners for the hover effect
    cursor: 'pointer',
    marginTop: '0.25rem',
  }),
}

export const customStylesWithBorders = {
  control: base => ({
    ...base,
    width: 'var(--dynamic-width-phase-period)',
    minWidth: '7rem',
    transition: 'width 0.2s ease',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'none',
    fontFamily: 'Inter',
    fontSize: '.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
  }),
  menu: provided => ({
    ...provided,
    border: '.0625rem solid #E7E9F4',
    borderRadius: '.5rem',
    boxShadow:
      '0rem .125rem .5rem 0rem rgba(40, 41, 61, 0.04), 0rem 1.625rem 2.125rem 0rem rgba(96, 97, 112, 0.06)',
    fontFamily: 'Poppins',
    fontSize: '.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
    width: '10rem',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#F7F9FD' : 'transparent', // Hover color
    color: state.isFocused ? '#000' : '#333', // Optional text color change
    borderRadius: '.25rem',
    cursor: 'pointer',
    marginTop: '0.25rem',
  }),
}

export const customStylesIntakePeriod = {
  control: base => ({
    ...base,
    width: 'var(--dynamic-width-intake-period)',
    border: '1px solid #E6E5EB',
    transition: 'width 0.2s ease',
    borderRadius: '1.25rem',
  }),
}
