import styled from 'styled-components/macro'

export const Container = styled.div`
  border: 0.0625rem solid #e7e9f4;
  border-radius: 0.75rem;
  padding: 1.5rem 1.5rem 1.75rem 1.5rem;
  background: #f8f9fc;
`

export const Header = styled.div`
  margin-bottom: 0.9375rem;
  font-size: 1rem;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem 0rem 0.375rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Stroke, #00855a);
  background: var(--White-500, #fff);
  font-size: 0.75rem;
  p {
    color: #3a845e;
  }
`
