import styled from 'styled-components/macro'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardHeader = styled.div`
  border-bottom: 0.0625rem solid #ddd;
  text-align: start;
  font-size: 1.5rem;
`

export const CardTitle = styled.h1`
  padding: 1.5rem;
  color: #080038;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-size: ${props => props.fontSize || '1.25rem'};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const CardContent = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  max-height: 35rem;
  overflow: auto;
`

export const ConfirmButtonWrapper = styled.div`
  padding: 1.5rem 1rem;
`

export const ConfirmButton = styled.button`
  width: 100%;
  background-color: ${props => props.backgroundColor || '#ff4b55'};
  color: white;
  border: none;
  border-radius: 0.75rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e6424b;
  }
`
