import { ConfirmationDialog } from '../../../../../components/ui'
import { DeleteInstructionModalProps } from './props'
import { useDeleteInstruction } from '../../../../../services/api/instruction/instruction'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const DeleteInstructionModal = ({
  isModalOpen,
  onIsDeleteInstructionModalOpenChange,
  instructionDeletionInfo,
  onSelectedUsedInstructionDelete,
  draft,
}: DeleteInstructionModalProps) => {
  const queryClient = useQueryClient()
  const { mutateAsync: deleteInstruction } = useDeleteInstruction({
    mutation: {
      onSuccess: handleDeleteInstructionSuccess,
      onError: handleDeleteInstructionError,
    },
  })

  function handleDeleteInstructionSuccess() {
    queryClient.invalidateQueries([
      'instructionLists',
      instructionDeletionInfo?.productId,
    ])
    toast("L'instruction a été supprimée avec succès")
    onIsDeleteInstructionModalOpenChange(false)
  }

  function handleDeleteInstructionError() {
    toast(
      "Un problème est survenu lors de la suppression de l'instruction. Veuillez réessayer"
    )
  }

  function handleDeleteInstructionConfirmClick() {
    if (!instructionDeletionInfo) return
    deleteInstruction({
      instructionId: instructionDeletionInfo.id,
    })
    const isSelectedUsedInstructionChanging =
      draft.takes[instructionDeletionInfo.takeIndex].items[
        instructionDeletionInfo.itemIndex
      ].instructionId !== instructionDeletionInfo.nextSelectedUsedInstructionId

    if (isSelectedUsedInstructionChanging) {
      onSelectedUsedInstructionDelete(
        instructionDeletionInfo.nextSelectedUsedInstructionId
      )
    }
  }

  return (
    <ConfirmationDialog
      isOpen={isModalOpen}
      onClose={() => onIsDeleteInstructionModalOpenChange(false)}
      onConfirmClick={handleDeleteInstructionConfirmClick}
      width='25rem'
      title='Êtes-vous certain de vouloir supprimer ce dosage ?'
      buttonText='Confirmer'
      fontSize='1.25rem'
      buttonBackgroundColor='#F46363'
      showCloseButton
    />
  )
}

export default DeleteInstructionModal
