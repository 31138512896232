import { IntakeLineContainer } from './style'
import { AdjustmentItem } from '../style'
import { IntakeLineProps } from './props'
import { renderIntakeInstruction } from './utils'

const IntakeLine = ({ intake, product }: IntakeLineProps) => {
  return (
    <IntakeLineContainer>
      <AdjustmentItem>
        {renderIntakeInstruction(intake, product)}
      </AdjustmentItem>
    </IntakeLineContainer>
  )
}

export default IntakeLine
