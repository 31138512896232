import styled from 'styled-components/macro'

export const IntakeQuantity = styled.div`
  color: #393360;
`
export const IntakeUnit = styled.div`
  color: #6b6688;
`

export const IntakeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
`

export const NoIntakeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceccd7;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`
