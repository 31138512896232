import { PrescriptionTakeDTO } from '../../services/api/types'
import { getProductVariantId } from '../../utils/data'
import { getProductByIdOrHandle } from '../../services/api/product/product'
import {
  PackagingType,
  CreatePrescriptionDTO,
  CreateProtocolDTO,
} from '../../services/api/types'

import {
  CreatePrescriptionTakeDTO,
  ProductDTO,
  BrandInstructionTiming,
} from '../../services/api/types'
import {
  setItemNote,
  setItemInstructionQuantity,
  setItemInstructionTiming,
  setItemInstructionDuration,
  setItemQuantity,
  setItemVariantTitle,
} from '../protocoles/NewProtocole/utils'
import { PrescriptionValueToChange } from '../../utils/constants'
import { ProductService } from '../../services/product.service'
import { toast } from 'react-hot-toast'
import { getPrescriptionProductPhases } from '../../services/api/prescription-phases/prescription-phases'

export const lineItemsForCart = async (
  prescriptionId: number,
  takes: PrescriptionTakeDTO[],
  recommendedDuration: string = '',
  packagingType: PackagingType = 'BOTH'
) => {
  if (!takes?.length) return

  return await Promise.all(
    takes?.flatMap((take, takeIndex) => {
      return take?.items.map(async item => {
        let variantId
        let product
        // if from reco page to create cart with correct variant
        if (recommendedDuration)
          // Get correct variant / price
          product = await getProductByIdOrHandle(item.product.handle, {
            packagingType,
          })

        const shopifyProduct = await ProductService.getSingle(
          item.product.handle
        ).catch(err => {
          toast.error(`${item.product.handle}`)
        })

        // If the Shopify product is not found or there was an error, skip processing this item.
        if (!shopifyProduct) return

        const phases = await getPrescriptionProductPhases(
          prescriptionId,
          product?.id
        )
        const structuredPhases = phases.map(phase => ({
          _name: phase.name,
          _duration: phase.duration,
          _period: phase.period,
          _intakes: phase.intakes.map(intake => ({
            _quantity: intake.quantity,
            _periods: intake.lines.map(line => ({
              _period: line.period,
            })),
          })),
        }))

        // Set item for shopify
        let attributes = [
          {
            key: '_handle',
            value: item.product.handle,
          },
          {
            key: '_nomPrise',
            value: take.label ? take.label : `PRISE #${takeIndex}`,
          },
          { key: '_prise', value: `${takeIndex}` },
          {
            key: '_phases',
            value: JSON.stringify(structuredPhases),
          },
        ]

        if (product?.takeFormat)
          attributes = [
            ...attributes,
            {
              key: '_takeFormat',
              value: product?.takeFormat,
            },
          ]

        if (item?.instructionDuration)
          attributes = [
            ...attributes,
            {
              key: '_instructionDuration',
              value: `${item?.instructionDuration}`,
            },
          ]
        if (item?.instructionQuantity)
          attributes = [
            ...attributes,
            {
              key: '_instructionQuantity',
              value: `${item?.instructionQuantity}`,
            },
          ]
        if (item?.instructionTiming)
          attributes = [
            ...attributes,
            { key: '_instructionTiming', value: `${item?.instructionTiming}` },
          ]
        if (item?.note) attributes.push({ key: 'notes', value: item.note })

        variantId = getProductVariantId(product, recommendedDuration)

        return {
          quantity: item.quantity,
          merchandiseId: variantId ? variantId : item?.product?.variantId,
          attributes: attributes,
        }
      })
    })
  )
}

export function setValueInPrescription(
  draft: CreatePrescriptionDTO | CreateProtocolDTO,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  product: ProductDTO,
  value: string | number | BrandInstructionTiming,
  takeIndex: number,
  prescriptionValueToChange: PrescriptionValueToChange
) {
  let takes: CreatePrescriptionTakeDTO[]
  switch (prescriptionValueToChange) {
    case PrescriptionValueToChange.INSTRUCTION_DURATION: {
      takes = setItemInstructionDuration(
        [...draft.takes],
        product,
        value as string,
        takeIndex
      )
      break
    }
    case PrescriptionValueToChange.INSTRUCTION_QUANTITY: {
      takes = setItemInstructionQuantity(
        [...draft.takes],
        product,
        value as number,
        takeIndex
      )
      break
    }
    case PrescriptionValueToChange.INSTRUCTION_TIMING: {
      takes = setItemInstructionTiming(
        [...draft.takes],
        product,
        value as BrandInstructionTiming,
        takeIndex
      )
      break
    }
    case PrescriptionValueToChange.ITEM_QUANTITY: {
      takes = setItemQuantity(
        [...draft.takes],
        product,
        value as number,
        takeIndex
      )
      break
    }
    case PrescriptionValueToChange.ITEM_VARIANT_TITLE: {
      takes = setItemVariantTitle(
        [...draft.takes],
        product,
        value as string,
        takeIndex
      )
      break
    }
    case PrescriptionValueToChange.NOTE: {
      takes = setItemNote([...draft.takes], product, value as string, takeIndex)
    }
  }
  setFieldValue && setFieldValue('takes', takes)
}
