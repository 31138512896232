import { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/portail'
import PageTitle from '../../components/section/page-title'
import { Container, Content, PageTitleWrapper, Wrapper } from './style'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { ViewMode } from '../../utils/constants'

import Discount from './discount-message'
import UrlProductDialog from '../../components/product/url-product-dialog'
import PatientOnboarding from '../../components/patient-catalog-onboarding/modal'
import Modal from './access-modal'
import CodePrescriberDialog from '../../components/prescriber/code-prescriber-dialog'
import Products from '../../components/products-v2'

const PatientProductsPage = () => {
  //Attributes
  const [isOpen, setIsOpen] = useState(false)
  const [isPatientOnboardingOpen, setIsPatientOnboardingOpen] = useState(false)

  //Return
  return (
    <Layout
      nav={[
        {
          url: '/patient/recommendations',
          text: 'Mes recommandations',
        },
        // {
        //   url: '/patient/orders',
        //   text: 'Historique de commande',
        // },
        {
          url: '/patient/catalog',
          text: 'Catalogue',
        },
      ]}
      showCart
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Catalogue</title>
      </Helmet>
      <Container>
        <PageTitle
          title={
            <PageTitleWrapper>
              <Wrapper>
                <p>Notre catalogue</p>
                <CodePrescriberDialog />
              </Wrapper>
            </PageTitleWrapper>
          }
        />
        <Content>
          <Products
            viewModeProduct={ViewMode.GRID}
            from='productPage'
            enableFavorite={false}
            enableAddToCart={true}
            isPatientCatalog
            allowProductUrl={true}
            excludeAffiliated={true}
          />
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setIsPatientOnboardingOpen={setIsPatientOnboardingOpen}
          />
        </Content>
      </Container>
      <UrlProductDialog />
      <PatientOnboarding
        open={isPatientOnboardingOpen}
        setOpen={setIsPatientOnboardingOpen}
      />
    </Layout>
  )
}

export default PatientProductsPage
