import styled, { keyframes, css } from 'styled-components/macro'
import { Color } from '../../../utils/constants'
import { Icon } from '../../ui/icon/style'

const spin = keyframes`
  100% { 
    transform: rotate(360deg);
  } 
`

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  opacity: 1;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  padding: 10px 20px 0px 20px;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4rem;
`

export const Statuses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const Title = styled.h3`
  font-size: 16px;
  margin: 10px 0 20px 0;
`

export const P = styled.p`
  font-size: 14px;
  margin-right: 5px;
`

export const MarkAsSent = styled.button`
  display: ${props => (props.isDisabled ? 'none' : 'block')};
  font-size: 14px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${Color.DEEP_SEA};
  > ${Icon} {
    display: inline-block;
    animation: ${props =>
      props.isLoading
        ? css`
            ${spin} 1s linear infinite
          `
        : 'none'};
  }
`

export const Consulted = styled.p`
  font-size: 12px;
  margin-left: 5px;
  opacity: 0.6;

  a {
    color: black;
  }
`

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const HeaderIcon = styled.div`
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
`

export const SendCtaWrapper = styled.div`
  width: 350px;
  margin-bottom: 20px;
`
