import {
  BodyWrapper,
  Container,
  HeaderWrapper,
  ListDescription,
  ListTitle,
} from './styles'
import ProductItem from '../../../components/prescription/marketplace-prescription-products-list/product'
import { AffiliatedItemsListProps } from './props'

const AffiliatedItemsList = ({
  takes,
  isPatientCatalog,
  prescriptionId,
  affiliatedItemCount,
}: AffiliatedItemsListProps) => {
  return (
    <Container>
      <HeaderWrapper>
        <ListTitle>
          {`${affiliatedItemCount} Produit${
            affiliatedItemCount > 1 ? 's' : ''
          } non disponible${
            affiliatedItemCount > 1 ? 's' : ''
          } à la vente sur Simplycure`}
        </ListTitle>
        <ListDescription>
          Les articles suivant de votre recommandation ne sont pas disponibles à
          la vente sur Simplycure. Vous pourrez les acheter directement sur le
          site du laboratoire via le lien fourni.
        </ListDescription>
      </HeaderWrapper>
      {takes?.map((take, index) => (
        <BodyWrapper key={index}>
          {take?.items?.map(item => (
            <ProductItem
              item={item}
              abilityToManageQuantity={false}
              isPatientCatalog={isPatientCatalog}
              prescriptionId={prescriptionId}
              isAffiliatedItem
            />
          ))}
        </BodyWrapper>
      ))}
    </Container>
  )
}

export default AffiliatedItemsList
