import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { AlternateContainer, ButtonWrapper, Container, Input } from './style'
import IconButton from '../../../../../ui/icon-button'
import { IconCheck } from '../../../../../ui/icon/icons'
import Pencil from '../../../../../ui/icon/icons/Pencil'
import { useClickOutside } from '../../../../../../utils/hooks'
import { NameInputProps } from './props'

const NameInput = ({ value, handle }: NameInputProps) => {
  //Attributes
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState<string>(value)
  const [isHovering, setIsHovering] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  useClickOutside({
    ref,
    onClick: () => setIsOpen(false),
  })

  //Function
  function handleValidateName() {
    if (!name) return

    handle(name)
    setIsOpen(false)
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
  }

  useEffect(() => {
    setName(value)
  }, [value])

  //Render
  return (
    <div style={{ alignSelf: 'center' }} ref={ref}>
      {isOpen ? (
        <Container>
          <Input placeholder={value} onChange={handleInputChange} />
          <IconButton
            bgColor={name ? '#65B293' : '#CECCD7'}
            width='1.25rem'
            height='1.25rem'
            onClick={handleValidateName}
            borderRadius='0.125rem'
          >
            {() => (
              <IconCheck fill={'#FFFFFF'} width='.625rem' height='.625rem' />
            )}
          </IconButton>
        </Container>
      ) : (
        <AlternateContainer
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => setIsOpen(true)}
        >
          <>{value}</>
          {isHovering && (
            <ButtonWrapper>
              <IconButton bgColor={'transparent'} height='.625rem'>
                {() => <Pencil width='.625rem' height='.625rem' />}
              </IconButton>
            </ButtonWrapper>
          )}
        </AlternateContainer>
      )}
    </div>
  )
}

export default NameInput
