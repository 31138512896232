import {
  ProductDTO,
  TakeFormatType,
} from '../../../../../../services/api/types'
import { getTakeFormatName } from '../../../../../../utils/helpers'

export function isTakeFormatSplittable(format: TakeFormatType) {
  return (
    format === TakeFormatType.TABLETS ||
    format === TakeFormatType.TABLESPOON ||
    format === TakeFormatType.TEASPOON ||
    format === TakeFormatType.CAP ||
    format === TakeFormatType.POD ||
    format === TakeFormatType.BAG ||
    format === TakeFormatType.TABLETS
  )
}

export function getProductUnitIncrementation(format: TakeFormatType) {
  return isTakeFormatSplittable(format) ? 0.5 : 1
}

export function getProductUnit(quantity: number, product: ProductDTO) {
  return getTakeFormatName(quantity, product?.takeFormat)
}

export function renderIntakePeriodDeterminant(intakePeriodString: string) {
  if (/^(au|\d)/.test(intakePeriodString)) {
    // case when the following word starts with 'au' or a number
    return ''
  }
  if (/^[aeiouAEIOU]/.test(intakePeriodString)) {
    // case when the following word starts with a voyel
    return "l'"
  }
  // case when the following word starts with a consonant
  return 'le'
}
