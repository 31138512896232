import { SVGProps } from 'react'

const IconMinus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ?? '12'}
      height={props.height ?? '12'}
      viewBox='0 0 12 12'
      fill='none'
      {...props}
    >
      <path
        d='M2.5 6H9.5'
        stroke={props.color ?? '#8F95B2'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconMinus
