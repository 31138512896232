import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const TopContainer = styled.div`
  background: #f2f4f9;
  display: flex;
  padding: 20px;
  position: relative;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

export const BottomContainer = styled.div`
  background-color: #f8f9fc;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding: 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

export const Container = styled.div`
  border-radius: 12px;
  border: 0.0625rem solid #e7e9f4;
`

export const Left = styled.div`
  margin-right: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: row;
    margin-bottom: 1.25rem;
  }
  gap: 6px;
`

export const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Img = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 0.3125rem;
  object-fit: cover;
  ${MediaQuery.M_AND_DOWN} {
    width: 5rem;
    height: 5rem;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 0.3125rem;
  }
  ${MediaQuery.M_AND_DOWN} {
    position: absolute;
    top: 0.9375rem;
    right: 1.875rem;
    margin-right: -0.75rem;
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  font-size: 0.9375rem;
  width: 1.75rem;
  height: 1.75rem;
  background: ${props => (props.background ? props.background : '')};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const DescritpionWrapper = styled.div`
  margin-top: 0.625rem;
  padding-top: 1.25rem;
  background: #ffffff;
  border: 0.0625rem solid #e9eaf0;
  border-radius: 0.5rem;
  padding: 0.75rem;
`
export const DescritpionTextInput = styled.div`
  color: #080038;
  font-size: 0.8125rem;
  width: 100%;
  min-height: auto;
  border: none;
  outline: none;
  resize: none;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const HeaderLeft = styled.div``

export const QuantityWrapperFlex = styled.div`
  display: flex;
  align-items: center;
`

export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: -0.625rem;
  }
`

export const Name = styled.h3`
  margin-bottom: 1.25rem;

  ${MediaQuery.M_AND_DOWN} {
    font-size: 0.9375rem;
    margin: 0;
  }
`
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1.25rem;

  ${MediaQuery.M_AND_DOWN} {
    margin: 1.25rem 0 0.625rem 0;
    align-items: flex-start;
  }
`

export const Price = styled.div`
  font-size: 1rem;
  margin-bottom: 0.3125rem;
`

export const Units = styled.div`
  font-size: 0.75rem;
  color: #8f95b2;
`

export const Title = styled.h5`
  margin: 0rem 0 0.9375rem 0;
`

export const QuantityHandleWrapper = styled.div`
  font-size: 0.625rem;
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: 0.4375rem 0.625rem;
  background: #ffffff;
  border: 0.0625rem solid #e7e9f4;
  border-radius: 6.25rem;
`

export const QuantityHandlerMinus = styled.div`
  cursor: pointer;
  font-size: 0.8125rem;
  padding: 0 0.3125rem;
`
export const QuantityHandlerPlus = styled.div`
  cursor: pointer;
  font-size: 0.8125rem;
  padding: 0 0.3125rem;
`

export const QuantityHandleNumber = styled.div`
  margin: 0 0.3125rem;
  font-size: 0.75rem;
`

export const QuantityChoiceWrapper = styled.div`
  flex-wrap: wrap;
  margin-top: -0.25rem;
`

export const QuantityChoice = styled.span`
  white-space: nowrap;
  cursor: pointer;
  margin-right: 0.625rem;
  margin-bottom: 0.1875rem;
  padding: 0.375rem 0.625rem;
  background-color: ${props => (props.selected ? '#65B293' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 0.0625rem solid #e7e9f4;
  border-radius: 6.25rem;
  font-size: 0.625rem;

  ${MediaQuery.M_AND_DOWN} {
    margin-right: 0.3125rem;
  }
`

export const AdjustmentWrapper = styled.div`
  margin: 0.625rem 0 0rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 0.625rem;
  }
`

export const AdjustmentItem = styled.span`
  white-space: nowrap;
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  background: #ffffff;
  border: 0.0625rem solid #e7e9f4;
  border-radius: 6.25rem;
  font-size: 0.625rem;
  color: #8f95b2;
  ${MediaQuery.M_AND_DOWN} {
    font-size: 0.5625rem;
  }
  height: 100%;
`

export const AdjustmentItemQuantity = styled.span`
  cursor: pointer;
  margin: 0 0.25rem;
  font-size: 0.75rem;
`

export const ReturnIcon = styled.div`
  margin-left: 0.625rem;
  cursor: pointer;
  color: #8f95b2;
`

export const ArrowDown = styled.span`
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  cursor: pointer;
`

export const SubMenu = styled.div`
  height: 12.5rem;
  overflow-y: scroll;
  margin-top: 0.625rem;
  padding: 1.25rem 1.25rem;
  background-color: white;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  border-radius: 0.75rem;
  & > * :not(:last-child) {
    margin-bottom: 0.625rem;
  }
  a {
    text-decoration: none;
    color: inherit;
    outline: none;
    font-size: 0.875rem;
    opacity: 0.8;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.1875rem;
  min-width: 6.25rem;
`
export const MenuItemInput = styled.input`
  margin-right: 0.3125rem;
  cursor: pointer;
  &[type='checkbox'] {
    appearance: none;
    width: 0.9375rem;
    height: 0.9375rem;
    background-color: #fff;
    border: 0.0625rem solid #00875c;
    border-radius: 0.1875rem;
    margin-right: 0.3125rem;
    position: relative;
    top: 0.125rem;
  }

  &[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00875c;
    border-left: 0.125rem solid #00875c;
    border-bottom: 0.125rem solid #00875c;
  }
`
export const MenuItemLabel = styled.label`
  cursor: pointer;
  margin-top: 0.1875rem;
  font-size: 0.8125rem;
  opacity: 0.7;
`

export const BrandImg = styled.img`
  object-fit: contain;
  width: 3.4375rem;
  margin-left: 0.625rem;
  margin-top: -1.125rem;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const MobileBrandImg = styled.img`
  display: none;
  object-fit: contain;
  width: 3.4375rem;
  margin-left: 0.625rem;
  margin-top: -1.125rem;

  ${MediaQuery.M_AND_DOWN} {
    display: block;
    margin-left: 0rem;
    margin-top: 0rem;
    margin-bottom: 0.625rem;
    height: 1.5625rem;
  }
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const Select = styled.select`
  background: white;
  border-radius: 0.9375rem;
  padding: 0.3125rem;
  border: 0.0625rem solid #eee;
  font-size: 0.625rem;
  margin: 0 0.5rem;
  outline: none;
`

export const SeeMore = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
`

export const DesktopPricing = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const MobilePricing = styled.div`
  display: none;
  ${MediaQuery.M_AND_DOWN} {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  float: right;
  margin-top: 0.9375rem;
  color: #c53030;
  border: 0.0625rem solid #fc8181;
  padding: 0.125rem 0.625rem;
  border-radius: 0.3125rem;
  background-color: #fff5f5;
  p {
    font-size: 0.625rem;
  }
  span {
    margin-top: 0.1875rem;
    margin-right: 0.3125rem;
    font-size: 0.9375rem;
  }
`

export const MobileNameWrapper = styled.div`
  display: none;
  margin-left: 0.625rem;
  flex-direction: column;
  ${MediaQuery.M_AND_DOWN} {
    display: flex;
  }
`

export const PhaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const PhaseLine = styled.div`
  display: flex;
  gap: 0.25rem;
`
export const IntakeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`
export const AffiliationLinkWrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #050022;
  padding: 0.25rem 0.75rem;
  color: #fff;
  text-decoration: none;
  gap: 0.25rem;
  height: 2.25rem;
`
export const AffiliationText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const NotesAndAffiliationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`
