/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type InstructionType = 'LABORATORY' | 'CUSTOM'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InstructionType = {
  LABORATORY: 'LABORATORY' as InstructionType,
  CUSTOM: 'CUSTOM' as InstructionType,
}
