import { ChangeEvent } from 'react'
import {
  DeleteIntakeButton,
  InputAndTakesContainer,
  IntakeDetailsWrapper,
  IntakeQuantity,
  IntakeQuantityInput,
  IntakeQuantityModifierButton,
  IntakeWrapper,
} from './style'
import IconMinus from '../../../../../ui/icon/icons/IconMinus'
import { IconPlusVariant } from '../../../../../ui/icon/icons'
import { isMultiPhases } from '../utils'
import IconCrossVariant from '../../../../../ui/icon/icons/IconCrossVariant'
import { IntakeLineProps } from './props'
import IntakePeriodDropdown from './intake-period-dropdown'
import { ColoredTextDiv } from '../style'
import {
  getProductUnit,
  getProductUnitIncrementation,
  renderIntakePeriodDeterminant,
} from './utils'
import { renderTriggerButtonName } from './intake-period-dropdown/utils'

const IntakeLine = ({
  intake,
  intakeIndex,
  phaseIndex,
  product,
  intakes,
  phases,
  onIntakeQuantityChange,
  onIntakePeriodSelect,
  onIntakeDelete,
}: IntakeLineProps) => {
  const intakePeriodTriggerButtonName = renderTriggerButtonName(intake.lines)
  const intakePeriodDeterminant = renderIntakePeriodDeterminant(
    intakePeriodTriggerButtonName.toLowerCase()
  )

  return (
    <IntakeWrapper>
      <IntakeDetailsWrapper>
        <ColoredTextDiv> Prendre </ColoredTextDiv>
        <IntakeQuantity>
          {/* Intake Quantity Controls */}
          <IntakeQuantityModifierButton
            onClick={() =>
              onIntakeQuantityChange(
                phaseIndex,
                intakeIndex,
                intake.quantity -
                  getProductUnitIncrementation(product?.takeFormat)
              )
            }
          >
            <IconMinus width={12} height={12} color='#8F95B2' />
          </IntakeQuantityModifierButton>
          <InputAndTakesContainer>
            <IntakeQuantityInput
              type='number'
              value={intake.quantity}
              min={0}
              style={{
                width: '2rem',
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onIntakeQuantityChange(
                  phaseIndex,
                  intakeIndex,
                  e.target.valueAsNumber
                )
              }
            />
            <div>{getProductUnit(intake.quantity, product)}</div>
          </InputAndTakesContainer>
          <IntakeQuantityModifierButton
            onClick={() =>
              onIntakeQuantityChange(
                phaseIndex,
                intakeIndex,
                intake.quantity +
                  getProductUnitIncrementation(product?.takeFormat)
              )
            }
          >
            <IconPlusVariant width={12} height={12} color='#8F95B2' />
          </IntakeQuantityModifierButton>
        </IntakeQuantity>
        {intakePeriodDeterminant !== '' && (
          <ColoredTextDiv>{intakePeriodDeterminant}</ColoredTextDiv>
        )}
        <div>
          <IntakePeriodDropdown
            onIntakePeriodSelect={onIntakePeriodSelect}
            phaseIndex={phaseIndex}
            intakeIndex={intakeIndex}
            selectedPeriods={intake.lines}
            intakes={intakes}
            intakePeriodTriggerButtonName={intakePeriodTriggerButtonName}
          />
        </div>
      </IntakeDetailsWrapper>
      {isMultiPhases(phases) && (
        <DeleteIntakeButton
          onClick={() => onIntakeDelete(phaseIndex, intakeIndex)}
        >
          <IconCrossVariant height={24} width={24} />
        </DeleteIntakeButton>
      )}
    </IntakeWrapper>
  )
}

export default IntakeLine
