import { View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { IBookletFooterProps } from './props'
import PricingSection from './pricing-section'

const BookletProProducts = ({
  product,
  discountPercentage,
}: IBookletFooterProps) => {
  const quantity = product?.takeInfo?.quantity

  const note = product?.takeInfo?.note

  // Render
  return (
    <View style={styles.wrapper} wrap={false}>
      <Image style={styles.productImage} src={product?.product?.coverImage} />

      <View style={styles.productDetails}>
        <Text style={styles.productName}>
          {quantity} x {product?.product?.name}
        </Text>
        {note && <Text style={styles.loremIpsumText}>{note}</Text>}
      </View>
      <PricingSection
        product={product}
        discountPercentage={discountPercentage}
        quantity={quantity}
      />
    </View>
  )
}

export default BookletProProducts

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#F8F9FC',
    border: '1px solid #e7e9f4',
    borderRadius: '10px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '10px 10px',
    margin: '0px 20px 10px 15px',
    minHeight: 'auto',
  },
  productImage: {
    width: '40px',
    height: '40px',
    borderRadius: '10px',
  },
  productDetails: {
    width: '60%',
    minHeight: 'auto',
  },
  productName: {
    fontSize: '11px',
    fontWeight: 'bold',
    marginBottom: '10px',
    width: '100%',
  },
  prescriberInstructions: {
    fontSize: '9px',
    marginBottom: '5px',
  },
  loremIpsumText: {
    fontSize: '8px',
    backgroundColor: 'white',
    padding: '5px',
    borderRadius: '3px',
  },
})
