import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { MenuContainer } from './style'
import { Dropdown, NavLink } from '../../../../components/ui'

import {
  ButtonWrapper,
  RecommandationPageHeader,
  BreadCrumbsContainer,
} from './style'
import { useHistory } from 'react-router'
import { Button, Icon } from '../../../../components/ui'
import { PrescriptionHeaderProps } from './props'
import {
  useDeletePrescription,
  useArchivePrescriptionById,
  usePushPrescriptionStatus,
} from '../../../../services/api/prescriptions/prescriptions'
import { getPatient } from '../../../../services/api/patients/patients'
import { getPrescriptionStatus } from '../../../../services/api/prescriptions/prescriptions'
import {
  PrescriptionDTO,
  PrescriptionStatus,
  PushPrescriptionStatus,
} from '../../../../services/api/types'
import { useGetMe } from '../../../../utils/hooks/getMe'

import {
  getRecommendationLink,
  processTake,
  translatePatientSex,
} from '../../../../utils/helpers'
import axios from 'axios'
import DeletePrescriptionModal from '../../../../components/prescription/prescriptions-container/prescription-row/delete-prescription-modal'

const PrescriptionHeader = ({
  prescription,
  patient,
}: PrescriptionHeaderProps) => {
  // Attributes
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  function refetPrescription() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/prescriptions/${prescription?.id}`,
    ])
  }

  const lastStatus = prescription?.statuses?.[0]

  const { mutateAsync: deletePrescription } = useDeletePrescription({
    mutation: {
      onSuccess: () => {
        history.push('/prescriptions')
      },
      onError: () => {
        toast.error(
          `Il y a eu une erreur dans la supression de la recommandation, merci de réessayer.`
        )
      },
    },
  })

  const { mutateAsync: archivePrescription } = useArchivePrescriptionById({
    mutation: {
      onSuccess: () => {
        toast.success('La recommandation à été archivée avec succès!')
        history.replace(`/prescriptions?nav=${PrescriptionStatus.ARCHIVED}`)
      },
      onError: () => {
        toast.error("La recommandation n'a pas pu être archivée, réessayez.")
      },
    },
  })

  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        toast.success(`L'email a été envoyé avec succès !`)
        refetPrescription()
      },
      onError: () => {
        toast.error(
          'Une erreur est survenue lors de la mise à jours du status de la recommandation.'
        )
      },
    },
  })

  // Functions
  async function sendMail(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    const data = {
      patient_lastname: prescription?.patient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      prescriber_firstname: prescriber?.lastName,
      prescriber_lastname: prescriber?.lastName,
      patient_email: prescription?.patient?.email,
      patient_firstname: prescription?.patient?.firstName,
      patient_phonenumber: prescription?.patient?.phone,
      custom_message: prescription?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      purchase_url: getRecommendationLink(prescription?.id),
      promo_code: prescription?.discount?.code,
      discount: prescription?.discount?.percentage,
    }

    axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .then(() => {
        onPushSentStatus(prescription)
      })
      .catch(error => {
        toast.error(error.code)
      })
  }

  async function onPushSentStatus(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const prescriptionStatusDTO = await getPrescriptionStatus(
      prescription?.id,
      lastStatus?.id
    )

    const newTakes = await Promise.all(
      prescriptionStatusDTO.takes.map(take =>
        processTake(take, prescription?.id)
      )
    )

    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescription?.id,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  const RenderSendMailButtons = () => {
    if (
      lastStatus?.status === PrescriptionStatus.CREATED ||
      lastStatus?.status === PrescriptionStatus.MODIFIED
    ) {
      return (
        <NavLink variant={undefined} onClick={() => sendMail(prescription)}>
          Envoyer
        </NavLink>
      )
    }

    if (lastStatus?.status === PrescriptionStatus.SENT) {
      return (
        <NavLink variant={undefined} onClick={() => sendMail(prescription)}>
          Renvoyer
        </NavLink>
      )
    }
  }

  // Render
  return (
    <RecommandationPageHeader>
      <BreadCrumbsContainer>
        <Link to='/prescriptions'>Recommandations</Link>
        <Icon name='angle-right' />
        <span>
          Recommandation pour {patient?.firstName} {patient?.lastName}
        </span>
      </BreadCrumbsContainer>
      <ButtonWrapper>
        <Dropdown
          content={
            <MenuContainer>
              {RenderSendMailButtons()}
              {lastStatus?.status === PrescriptionStatus.TO_RELAUNCH && (
                <NavLink
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: `?step=2&prescriptionId=${prescription?.id}&action=relaunch`,
                  }}
                  variant={undefined}
                >
                  Renouveler
                </NavLink>
              )}
              {
                <NavLink
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: `?step=1&prescriptionId=${prescription?.id}&action=duplicate`,
                  }}
                  variant={undefined}
                >
                  Dupliquer
                </NavLink>
              }
              {(lastStatus?.status === PrescriptionStatus.CREATED ||
                lastStatus?.status === PrescriptionStatus.MODIFIED) && (
                <NavLink
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: `?step=2&prescriptionId=${prescription?.id}&action=update&patientId=${prescription?.patient?.id}`,
                  }}
                  variant={undefined}
                >
                  Modifier
                </NavLink>
              )}
              {lastStatus?.status === PrescriptionStatus.TO_RELAUNCH && (
                <NavLink
                  onClick={() => {
                    archivePrescription({
                      prescriptionId: prescription?.id,
                    })
                  }}
                  variant={undefined}
                >
                  Archiver
                </NavLink>
              )}

              <NavLink onClick={() => setIsModalOpen(true)} variant={undefined}>
                Supprimer
              </NavLink>
            </MenuContainer>
          }
        >
          <Button
            iconLeft='menu'
            appearance='primary'
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Actions
          </Button>
        </Dropdown>
      </ButtonWrapper>

      <DeletePrescriptionModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        onClick={() =>
          deletePrescription({
            prescriberId: prescriber?.id,
            prescriptionId: prescription?.id,
          })
        }
      />
    </RecommandationPageHeader>
  )
}

export default PrescriptionHeader
