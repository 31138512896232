import ProductCardMarketplace from '..'
import Button from '../../../../ui/button'
import IconDrugList from '../../../../ui/icon/icons/IconDrugList'
import { ProductsWrapper, ProductsWrapperList } from '../../style'

import { ProductsContainerProps } from './props'
import {
  Header,
  HeaderActions,
  IntakesTableButton,
  Container,
  Title,
  TitleWrapper,
} from './styles'
const ProductsContainer = ({
  onIntakesTableModalOpenChange,
  setIsSliderProductDialogOpen,
  takes,
  originalTakes,
  removeProductFromDraftTake,
  onInstructionDeletionInfoChange,
  onIsDeleteInstructionModalOpenChange,
  onTakesItemsChange,
  statuses,
  lastStatus,
  setDraftChanged,
  setValueInPrescription,
  title,
  description,
  showHeaderActions = true,
}: ProductsContainerProps) => {
  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          {description}
        </TitleWrapper>
        {showHeaderActions && (
          <HeaderActions>
            <IntakesTableButton
              onClick={() => onIntakesTableModalOpenChange(true)}
            >
              <IconDrugList />
            </IntakesTableButton>
            <Button
              appearance='primary'
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
              onClick={() => setIsSliderProductDialogOpen(true)}
            >
              Ajouter un produit
            </Button>
          </HeaderActions>
        )}
      </Header>
      {takes?.map((take, takeIndex: number) => (
        <ProductsWrapper key={takeIndex}>
          <ProductsWrapperList>
            {take?.items
              ?.map((item, itemIndex: number) => (
                <ProductCardMarketplace
                  removeProductFromDraftTake={removeProductFromDraftTake}
                  key={item?.productHandle}
                  takeIndex={takeIndex}
                  item={item}
                  setValueInPrescription={setValueInPrescription}
                  setDraftChanged={setDraftChanged}
                  statuses={statuses}
                  lastStatus={lastStatus}
                  onTakesItemsChange={onTakesItemsChange}
                  itemIndex={originalTakes[takeIndex].items.findIndex(
                    i => i.productHandle === item.productHandle
                  )}
                  onIsDeleteInstructionModalOpenChange={
                    onIsDeleteInstructionModalOpenChange
                  }
                  onInstructionDeletionInfoChange={
                    onInstructionDeletionInfoChange
                  }
                />
              ))
              ?.reverse()}
          </ProductsWrapperList>
        </ProductsWrapper>
      ))}
    </Container>
  )
}
export default ProductsContainer
