import { SVGProps } from 'react'

const IconTargetMarker = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      {...props}
    >
      <path
        d='M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM8.25 3.75L5 5L3.75 8.25L7 7L8.25 3.75ZM6 6.5C5.86739 6.5 5.74021 6.44732 5.64645 6.35355C5.55268 6.25979 5.5 6.13261 5.5 6C5.5 5.86739 5.55268 5.74021 5.64645 5.64645C5.74021 5.55268 5.86739 5.5 6 5.5C6.13261 5.5 6.25979 5.55268 6.35355 5.64645C6.44732 5.74021 6.5 5.86739 6.5 6C6.5 6.13261 6.44732 6.25979 6.35355 6.35355C6.25979 6.44732 6.13261 6.5 6 6.5Z'
        fill={props.color ?? '#6B6688'}
      />
    </svg>
  )
}

export default IconTargetMarker
