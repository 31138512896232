import { IntakesTableModalProps } from './props'
import ProductItemLine from './product-item-line'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  HeadersTable,
  ProductList,
} from './style'
import { Dialog } from '../../../../../components/ui'
import { HeaderCell } from './product-item-line/style'
import { useEffect, useRef, useState } from 'react'

export default function IntakesTableModal({
  isModalOpen,
  items,
  onClose,
}: IntakesTableModalProps) {
  const columnRefs = useRef<Array<Array<HTMLTableCellElement | null>>>([])
  const [columnWidths, setColumnWidths] = useState<number[]>([])

  const headers = [
    '',
    'Durée',
    'Moment',
    'Matin',
    'Midi',
    'Après-midi',
    'Soir',
    'Au coucher',
    'Fréquence',
  ]

  useEffect(() => {
    const columnCount = headers.length
    const widths: number[] = Array(columnCount).fill(0)

    // Measure the widths of each column
    columnRefs.current.forEach(row => {
      row.forEach((cell, colIndex) => {
        if (cell) {
          // Compare the cell width to the current max width for the column and store the max width
          widths[colIndex] = Math.max(widths[colIndex], cell.offsetWidth)
        }
      })
    })

    setColumnWidths(widths)
  }, [items, headers.length])

  function handleColumnRefsUpdate(
    rowIndex: number,
    colIndex: number,
    cell: HTMLTableCellElement | null
  ) {
    if (!columnRefs.current[rowIndex]) {
      columnRefs.current[rowIndex] = []
    }
    // Store each cells
    columnRefs.current[rowIndex][colIndex] = cell
  }

  return (
    <Dialog
      type='default'
      isOpen={isModalOpen}
      onClose={onClose}
      width='50%'
      showCloseButton
    >
      <Card>
        <CardHeader>
          <CardTitle>Tableau de prise</CardTitle>
        </CardHeader>
        <CardContent>
          <HeadersTable>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{
                    width: columnWidths[index],
                    minWidth: '4rem',
                  }}
                  ref={el => handleColumnRefsUpdate(0, index, el)}
                >
                  <HeaderCell
                    isFirstColumn={index === 0}
                    isIntakePeriod={index > 2}
                  >
                    {header}
                  </HeaderCell>
                </th>
              ))}
            </tr>
          </HeadersTable>
          <ProductList>
            {items?.map((item, index) => (
              <ProductItemLine
                key={index}
                item={item}
                onColumnRefsUpdate={handleColumnRefsUpdate}
                columnWidths={columnWidths}
              />
            ))}
          </ProductList>
        </CardContent>
      </Card>
    </Dialog>
  )
}
