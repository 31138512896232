import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  ButtonDefault,
  ButtonOutline,
  ButtonMinimal,
  ButtonUltraMinimal,
  ButtonPrimary,
  ButtonRed,
  Calendly,
} from './style'
import Icon from '../icon'
import { Color } from '../../../utils/constants'

const Buttons = {
  'ultra-minimal': ButtonUltraMinimal,
  default: ButtonDefault,
  outline: ButtonOutline,
  minimal: ButtonMinimal,
  primary: ButtonPrimary,
  red: ButtonRed,
  calendly: Calendly,
}

const getButtonComponent = appearance => Buttons[appearance]

const ButtonEl = ({
  appearance,
  children,
  iconLeft,
  isDisabled,
  isLoading,
  isActive,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const Button = getButtonComponent(appearance)
  const iconColor = appearance === 'primary' ? 'white' : undefined
  const iconColorHover = appearance === 'primary' ? Color.DEEP_SEA : undefined

  return (
    <Button
      {...props}
      $hasMarginRight={!!children}
      $isDisabled={isDisabled || isLoading}
      $isLoading={isLoading}
      $isActive={isActive}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {iconLeft && !isLoading && (
        <Icon name={iconLeft} color={isHovering ? iconColorHover : iconColor} />
      )}
      {isLoading && <Icon name='loading' />}
      {children}
    </Button>
  )
}

ButtonEl.propTypes = {
  appearance: PropTypes.oneOf(Object.keys(Buttons)),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

ButtonEl.defaultProps = {
  appearance: 'default',
  isDisabled: false,
  isLoading: false,
  isActive: false,
}

export default ButtonEl
