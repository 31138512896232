import { Text, View } from '@react-pdf/renderer'
import { BooketIntakesTableProps } from './props'
import {
  mapPhasePDFDurationToLabel,
  mapPhasePeriodToLabel,
} from '../../product-list/productCardMarketplace/product-phases/utils'
import IntakeCell from './intake-cell'
import { IntakePeriodType } from '../../../../services/api/types'
import { styles } from './style'
import { getProductUnit } from '../../product-list/productCardMarketplace/product-phases/intake-line/utils'

const headers = [
  'Phases',
  'Durée',
  'Moment',
  'Matin',
  'Midi',
  'A-M',
  'Soir',
  'Coucher',
  'Fréquence',
]

const BookletIntakesTable = ({
  lastStatusPhases,
  products,
}: BooketIntakesTableProps) => {
  return (
    <View style={styles.intakesTableContainer}>
      <Text style={styles.title}>Tableau de prise</Text>
      <View style={styles.tablesContainer}>
        {/* Header Row */}
        <View style={[styles.table, styles.tableHeader]}>
          <View style={[styles.tableRow, styles.centeredCell]}>
            {headers.map((header, headerIndex) => (
              <View
                key={header}
                style={[
                  styles.tableCell,
                  headerIndex !== 0 &&
                    headerIndex !== 1 &&
                    headerIndex !== 2 &&
                    styles.centeredCell,
                  headerIndex !== 0 &&
                    headerIndex !== 1 &&
                    headerIndex !== 2 &&
                    styles.boldCell,
                ]}
              >
                <Text style={styles.headerText}>{header}</Text>
              </View>
            ))}
          </View>
        </View>
        {/* Medication Name */}
        {lastStatusPhases.map(productWithPhase => {
          const product = products.find(
            product => product.product.id === productWithPhase.itemId
          )?.product
          const productUnit = getProductUnit(2, product)
          return (
            <View
              style={styles.productContainer}
              key={productWithPhase.itemId}
              wrap={false}
            >
              <View style={styles.productNameContainer}>
                <Text style={styles.productName}>{product.name}</Text>
                <Text
                  style={styles.productUnit}
                >{`Quantité en ${productUnit}`}</Text>
              </View>

              <View style={styles.table}>
                {productWithPhase.phases.map((phase, phaseIndex) => (
                  <View
                    key={phaseIndex}
                    style={[
                      styles.tableRow,
                      phaseIndex !== productWithPhase.phases.length - 1 &&
                        styles.dashedBottomBorder,
                    ]}
                  >
                    {[
                      <Text style={styles.regularCell}>{phase?.name}</Text>,
                      <Text style={styles.regularCell}>
                        {mapPhasePDFDurationToLabel(phase?.duration)}
                      </Text>,
                      <Text style={styles.regularCell}>
                        {mapPhasePeriodToLabel(phase?.period)}
                      </Text>,
                      <IntakeCell
                        intakes={phase.intakes}
                        period={IntakePeriodType.MORNING}
                      />,
                      <IntakeCell
                        intakes={phase.intakes}
                        period={IntakePeriodType.NOON}
                      />,
                      <IntakeCell
                        intakes={phase.intakes}
                        period={IntakePeriodType.AFTERNOON}
                      />,
                      <IntakeCell
                        intakes={phase.intakes}
                        period={IntakePeriodType.EVENING}
                      />,
                      <IntakeCell
                        intakes={phase.intakes}
                        period={IntakePeriodType.BEDTIME}
                      />,
                      <IntakeCell intakes={phase.intakes} />,
                    ].map((content, colIndex) => (
                      <View
                        style={[
                          styles.tableCell,
                          colIndex === 0 && styles.phaseName,
                          colIndex !== 0 &&
                            colIndex !== 1 &&
                            colIndex !== 2 &&
                            styles.centeredCell,
                        ]}
                        key={colIndex}
                      >
                        {content}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default BookletIntakesTable
