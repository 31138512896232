import styled from 'styled-components/macro'

export const ProductImageContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.25rem;
`

export const ProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`

export const ProductImage = styled.img`
  width: auto;
  height: 1.5rem;
  border-radius: 0.25rem;
  object-fit: cover;
`

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`

export const ProductName = styled.div`
  color: #393360;
  font-family: 'Gill Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

export const ProductTable = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border: 0.0625rem solid #65b293;
  background-color: #f8f9fc;
`

export const ProductPhaseLine = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: ${props =>
    props.isLastPhaseLine ? '0 dashed #e7e9f4' : '1px dashed #e7e9f4'};
  font-family: 'Gill Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-feature-settings: 'liga' off, 'clig' off;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`
export const CardHeader = styled.div`
  border-bottom: 0.0625rem solid #ddd;
  text-align: center;
  text: 1.5rem;
`
export const CardContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 35rem;
  overflow: scroll;
`

export const CardTitle = styled.h1`
  padding: 1.5rem;
  color: #080038;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow: auto;
`

export const HeadersTable = styled.table`
  border-radius: 0.25rem;
  border: 1px solid #e7e9f4;
  width: 100%;
  background-color: #f8f9fc;
`
export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
