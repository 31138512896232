import {
  CatalogNotFoundWrapper,
  Content,
  FilterButton,
  HeaderCatalogueHeader,
  NoFoundWrapper,
  ProductsContainer,
  SpinnerBottomContainer,
  SpinnerContainer,
  SpinnerContainerLoading,
  Table,
  TableWithFilters,
  UpButton,
  UpButtonContainer,
  WrapperFilters,
  WrapperHeaderCatalogue,
  WrapperSearchbar,
  WrapperTableContent,
} from './style'
import { Spinner, NoFound } from '../ui'
import { PackagingType } from '../../services/api/types'
import { ProductsProps } from './props'
import ProductPreviewCard from '../product/product-preview-card/ProductPreviewCard'
import { ViewMode } from '../../utils/constants'
import ProductItem from '../product/favorites/product-item'
import RequestProduct from './request-product'
import { useLogicProducts } from './logic'
import ProductSearchBar from './product-search'
import ResultBar from './result-bar'
import Filters from './filters'
import { LuFilter } from 'react-icons/lu'
import { FilterMobileDialog } from './mobile-filters-dialog'
import { useRef, useState } from 'react'

const Products = ({
  SIZE = 20,
  packagingType = PackagingType.ORIGINAL,
  addProductWithTake,
  onProductClicked,
  viewModeProduct,
  takes,
  from,
  selectedHandle,
  setSelectedHandle,
  recommendedDuration,
  setTakeChoices,
  getVariantName,
  takeChoices,
  isFavorite,
  enableFavorite,
  setIsInnerDialogOpen,
  enableAddToCart,
  doNotAllowProductToBeAdded,
  enableMoreInfo,
  isPatientCatalog,
  allowProductUrl,
  type,
  favoriteId,
  favoriteLists,
  refetchCurrentFavoriteList,
  excludeAffiliated,
}: ProductsProps) => {
  //Attributes
  const isMobile = window.innerWidth < 768
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const wrapperTableContentRef = useRef(null)

  //Functions
  function scrollToTopOfTableContent() {
    wrapperTableContentRef.current?.scrollIntoView({
      top: 0,
      behavior: 'smooth',
    })
  }

  // Logic
  const {
    isFiltersUsedBoolean,
    isSuccess,
    productsToDisplay,
    hasNextPage,
    ref,
    productDetailsModal,
    setProductDetailsModal,
    allFavoriteListsHandles,
    isFetchingNextPage,
    clearAllFilters,
    values,
    setFieldValue,
    search,
    changeSearch,
    debouncedSearch,
    totalAmount,
    allBrands,
    allProductTypes,
    isLoading,
    allProductTags,
    allProductLabels,
    allIngredients,
    allTakeFormat,
    allAllergens,
    onSortBy,
  } = useLogicProducts({ packagingType, SIZE, excludeAffiliated })

  // Render
  return (
    <>
      <Content>
        {/* Searchbar */}
        <WrapperHeaderCatalogue>
          <HeaderCatalogueHeader>
            <WrapperSearchbar>
              <ProductSearchBar
                search={search}
                debouncedSearch={debouncedSearch}
                setSearch={changeSearch}
                packagingType={packagingType}
                onProductClicked={onProductClicked}
                values={values}
                setFieldValue={setFieldValue}
                isMobile={isMobile}
              />
              <FilterButton onClick={() => setIsDialogOpen(true)}>
                <LuFilter size={13} style={{ color: '#65B293' }} />
                Filtres
              </FilterButton>
            </WrapperSearchbar>
            <ResultBar
              totalAmount={totalAmount}
              values={values}
              setFieldValue={setFieldValue}
              clearFilter={clearAllFilters}
              onSortBy={onSortBy}
            />
          </HeaderCatalogueHeader>
        </WrapperHeaderCatalogue>
        {isDialogOpen && (
          <>
            <FilterMobileDialog
              isOpen={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              title='Filtres'
            >
              <Filters
                values={values}
                allBrands={allBrands}
                allProductTypes={allProductTypes}
                allProductTags={allProductTags}
                allProductLabels={allProductLabels}
                allIngredients={allIngredients}
                allTakeFormat={allTakeFormat}
                allAllergens={allAllergens}
                setFieldValue={setFieldValue}
              />
            </FilterMobileDialog>
          </>
        )}
        <TableWithFilters>
          <WrapperFilters>
            <Filters
              values={values}
              allBrands={allBrands}
              allProductTypes={allProductTypes}
              allProductTags={allProductTags}
              allProductLabels={allProductLabels}
              allIngredients={allIngredients}
              allTakeFormat={allTakeFormat}
              allAllergens={allAllergens}
              setFieldValue={setFieldValue}
            />
          </WrapperFilters>
          <WrapperTableContent ref={wrapperTableContentRef}>
            {isLoading && (
              <SpinnerContainerLoading>
                <Spinner fullScreen={undefined} />
              </SpinnerContainerLoading>
            )}
            <Table>
              {((isSuccess && !isFiltersUsedBoolean) ||
                isFiltersUsedBoolean) && (
                <ProductsContainer $mode={ViewMode.GRID}>
                  {productsToDisplay ? (
                    productsToDisplay?.map((product, index) => {
                      const total = (index / SIZE) * 100
                      let hasRef = false

                      if (
                        total % 20 === 0 &&
                        ((hasNextPage && !isFiltersUsedBoolean) ||
                          isFiltersUsedBoolean)
                      ) {
                        hasRef = true
                      }

                      return isFavorite ? (
                        <ProductItem
                          key={product.handle}
                          ref={hasRef ? ref : undefined}
                          product={product}
                          mode={viewModeProduct}
                          setIsInnerDialogOpen={setIsInnerDialogOpen}
                        />
                      ) : (
                        <ProductPreviewCard
                          key={product.handle}
                          mode={viewModeProduct}
                          hasDetailModalOpen={
                            productDetailsModal === product.handle
                          }
                          product={product}
                          closeDetailModal={() => setProductDetailsModal('')}
                          ref={hasRef ? ref : undefined}
                          onClick={quantity =>
                            onProductClicked(product, quantity)
                          }
                          takes={takes}
                          addProduct={addProductWithTake}
                          from={from}
                          selectedHandle={selectedHandle}
                          setSelectedHandle={setSelectedHandle}
                          setTakeChoices={setTakeChoices}
                          getVariantName={getVariantName}
                          takeChoices={takeChoices}
                          bookmarks={allFavoriteListsHandles?.includes(
                            product.handle
                          )}
                          enableFavorite={enableFavorite}
                          setIsInnerDialogOpen={setIsInnerDialogOpen}
                          enableAddToCart={enableAddToCart}
                          doNotAllowProductToBeAdded={
                            doNotAllowProductToBeAdded
                          }
                          enableMoreInfo={enableMoreInfo}
                          isPatientCatalog={isPatientCatalog}
                          allowProductUrl={allowProductUrl}
                          type={type}
                          favoriteId={favoriteId}
                          favoriteLists={favoriteLists}
                          refetchCurrentFavoriteList={
                            refetchCurrentFavoriteList
                          }
                        />
                      )
                    })
                  ) : (
                    <></>
                  )}
                </ProductsContainer>
              )}
            </Table>
            {isSuccess && !productsToDisplay?.length && (
              <CatalogNotFoundWrapper>
                <NoFoundWrapper>
                  <NoFound
                    title='Aucun Produit'
                    descritpion='Nous n’avons trouvé aucun résultat à votre recherche'
                    clearAllFilters={() => clearAllFilters()}
                  />
                  <RequestProduct />
                </NoFoundWrapper>
              </CatalogNotFoundWrapper>
            )}
            <SpinnerBottomContainer>
              {isFetchingNextPage && <Spinner fullScreen={undefined} />}
            </SpinnerBottomContainer>
          </WrapperTableContent>
        </TableWithFilters>
      </Content>
      {productsToDisplay?.length <= 6 && (
        <UpButtonContainer>
          <UpButton onClick={scrollToTopOfTableContent}>
            Voir tous les résultats ({totalAmount ?? 0})
          </UpButton>
        </UpButtonContainer>
      )}
    </>
  )
}

export default Products
