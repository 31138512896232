import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
`

export const TriggerButton = styled.button<{
  isInstructionSelected: boolean
}>`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  background: #f8f9fc;
  border: 0;
  border-radius: 0.375rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  cursor: pointer;
  color: ${props => (props.isInstructionSelected ? '#393360' : '#65B293')};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: all 100ms ease-in-out;
`
export const Dropdown = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  z-index: 10;
  min-width: 15.75rem;
  max-height: 14rem;
`

export const InputGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e9eaf0;
  padding: 0.5rem;
`

export const Input = styled.input`
  flex: 1;
  padding: 0;
  border: 0;
  font-size: 0.875rem;

  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::placeholder {
    color: #ceccd7;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:focus {
    outline: none;
  }
`

export const AddButton = styled.button`
  background: #65b293;
  color: white;
  border: none;
  border-radius: 0.125rem;
  padding: 0.125rem 0.375rem;
  font-size: 0.75rem;
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem;

  &:enabled:hover {
    background: #3a845e;
    cursor: pointer;
  }

  &:disabled {
    background: #ceccd7;
  }
`

export const ListWrapper = styled.div`
  overflow-y: auto;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  gap: 0.25rem;
`

export const ListItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background-color: '#fff';
  border-radius: 0.25rem;
  justify-content: space-between;
  &:hover {
    background-color: #f7f9fd;
  }
`

export const IconWrapper = styled.div<{ $purple?: boolean }>`
  color: ${props => (props.$purple ? '#6366f1' : '#666')};
`

export const RadioButton = styled.input`
  width: 0.75rem;
  height: 0.75rem;
  margin: 0;
  cursor: pointer;
  appearance: auto; /* Use browser's native appearance */
  accent-color: green; /* Set the desired color for the radio button */
`

export const ItemText = styled.span`
  flex: 1;
  font-size: 0.875rem;
  color: #080038;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem;
`

export const MenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #333;
  }
  display: flex;
  align-items: center;
`

export const ActionDropdown = styled.div<{
  $isOpen: boolean
  top: number
  left: number
}>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.25rem;
  padding: 8px;
  position: fixed;
  left: ${props => (props.left ? `${props.left}px` : '100%')};
  top: ${props => (props.top ? `${props.top}px` : '0')};
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
  z-index: 20;
`

export const ActionItem = styled.button<{ $danger?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 0.5rem;
  border: none;
  background: none;
  color: #080038;
  font-size: 0.875rem;
  cursor: pointer;
  text-align: left;

  &:hover {
    border-radius: 0.25rem;
    background: #f7f9fd;
    color: ${props => (props.$danger ? '#F46363' : '#2ED477')};
    svg path {
      fill: ${props => (props.$danger ? '#F46363' : '#2ED477')};
    }
  }
`

export const HorizontalSeparator = styled.hr`
  border: none;
  border-top: 0.125rem solid #e9eaf0;
`
