import { Container, Title, Description, Button, ButtonWrapper } from './style'
import { usePushPrescriptionStatus } from '../../../../services/api/prescriptions/prescriptions'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { PushPrescriptionStatus } from '../../../../services/api/types'
import toast from 'react-hot-toast'
import { refetchPrescription } from '../../../../utils/helpers/refetch'
import { useQueryClient } from 'react-query'
import { SendCtaProps } from './props'
import { processTake } from '../../../../utils/helpers'

const SendCta = ({
  lastStatus,
  prescriptionId,
  status,
  setIsOpen,
}: SendCtaProps) => {
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        refetchPrescription(queryClient, prescriber?.id, prescriptionId)
      },
    },
  })

  // Functions
  async function onPushSentStatus() {
    const newTakes = await Promise.all(
      lastStatus.takes.map(take => processTake(take, prescriptionId))
    )

    toast
      .promise(
        pushStatus({
          prescriberId: prescriber?.id,
          prescriptionId: prescriptionId,
          data: {
            status: PushPrescriptionStatus.SENT,
            takes: newTakes,
          },
        }),
        {
          loading: 'en cours...',
          success: 'La prescription a bien été marquée comme "envoyée"',
          error: "Une erreur s'est produite ",
        },
        {
          duration: 3000,
        }
      )
      .then(() => setIsOpen && setIsOpen(false))
  }

  return (
    <Container>
      <Title>💡 Marquer la recommandation comme "envoyée"</Title>
      <Description>
        Vous pouvez changer le statut de la recommandation vers "envoyée" si
        vous décidez de la partager autrement que par notre envoi d'email et
        SMS. Cela vous permettra de savoir si votre recommandation a été
        consultée par votre client ou non.
      </Description>

      <ButtonWrapper>
        <Button onClick={onPushSentStatus}>Marquer comme "envoyée"</Button>
      </ButtonWrapper>
    </Container>
  )
}

export default SendCta
