import { SVGProps } from 'react'
const IconLinkS = ({ fill, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      {...props}
    >
      <path
        d='M5 7.5C5 7.66576 5.06585 7.82473 5.18306 7.94194C5.30027 8.05915 5.45924 8.125 5.625 8.125H9.375C9.54076 8.125 9.69973 8.05915 9.81694 7.94194C9.93415 7.82473 10 7.66576 10 7.5C10 7.33424 9.93415 7.17527 9.81694 7.05806C9.69973 6.94085 9.54076 6.875 9.375 6.875H5.625C5.45924 6.875 5.30027 6.94085 5.18306 7.05806C5.06585 7.17527 5 7.33424 5 7.5ZM6.25 9.375H4.375C3.87772 9.375 3.40081 9.17746 3.04917 8.82583C2.69754 8.47419 2.5 7.99728 2.5 7.5C2.5 7.00272 2.69754 6.52581 3.04917 6.17417C3.40081 5.82254 3.87772 5.625 4.375 5.625H6.25C6.41576 5.625 6.57473 5.55915 6.69194 5.44194C6.80915 5.32473 6.875 5.16576 6.875 5C6.875 4.83424 6.80915 4.67527 6.69194 4.55806C6.57473 4.44085 6.41576 4.375 6.25 4.375H4.375C3.5462 4.375 2.75134 4.70424 2.16529 5.29029C1.57924 5.87634 1.25 6.6712 1.25 7.5C1.25 8.3288 1.57924 9.12366 2.16529 9.70971C2.75134 10.2958 3.5462 10.625 4.375 10.625H6.25C6.41576 10.625 6.57473 10.5592 6.69194 10.4419C6.80915 10.3247 6.875 10.1658 6.875 10C6.875 9.83424 6.80915 9.67527 6.69194 9.55806C6.57473 9.44085 6.41576 9.375 6.25 9.375ZM10.625 4.375H8.75C8.58424 4.375 8.42527 4.44085 8.30806 4.55806C8.19085 4.67527 8.125 4.83424 8.125 5C8.125 5.16576 8.19085 5.32473 8.30806 5.44194C8.42527 5.55915 8.58424 5.625 8.75 5.625H10.625C11.1223 5.625 11.5992 5.82254 11.9508 6.17417C12.3025 6.52581 12.5 7.00272 12.5 7.5C12.5 7.99728 12.3025 8.47419 11.9508 8.82583C11.5992 9.17746 11.1223 9.375 10.625 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H10.625C11.4538 10.625 12.2487 10.2958 12.8347 9.70971C13.4208 9.12366 13.75 8.3288 13.75 7.5C13.75 6.6712 13.4208 5.87634 12.8347 5.29029C12.2487 4.70424 11.4538 4.375 10.625 4.375V4.375Z'
        fill={fill ?? '#393360'}
      />
    </svg>
  )
}
export default IconLinkS
