import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Spinner, Button, NoFound } from '../../components/ui'
import PrescriberContactDialog from '../../components/reco/prescriber-contact-dialog'
import Layout from '../../components/layout/reco'
import {
  BodySection,
  Container,
  Content,
  Left,
  MainTitle,
  Note,
  Right,
  SecondaryTitle,
  OrderSummary,
  FooterBar,
  FilesWrapper,
  FilesWrapperIcon,
  FilesWrapperContent,
  FilesWrapperTitle,
  FileList,
  File,
  PricingLine,
  Highlight,
  Commission,
  TotalPricingLine,
  NoteWrapper,
  NoteWrapperHeader,
  NoteTitle,
  ShippingProgressBarWrapper,
  ProductListContainer,
} from './style'
import { ImAttachment } from 'react-icons/im'
import { getCart } from '../../store/cart'
import {
  useInitCart,
  useAddToCart,
  useUpdateCart,
  useCartLinesRemove,
  useFetchCart,
  useCartAttributesUpdate,
} from '../../utils/hooks'
import PrescriptionProductsList from '../../components/prescription/prescription-products-list'
import MarketplacePrescriptionProductsList from '../../components/prescription/marketplace-prescription-products-list'
import ProductsUpsellsDialog from '../../components/product/products-upsells-dialog'
import PaymentSettings from '../../components/payment/payment-settings'
import BoxInfo from '../../components/reco/box-info'
import { lineItemsForCart } from '../prescriptions/utils'
import {
  mapSubscriptionLines,
  mapOneTimePurchaseLines,
  mapShopifyCartToPrescriptionTakeDTOs,
  getCartAttributes,
  getLineItemsForCart,
  getMissingItems,
  createAttribute,
} from './utils'
import Modal from '../../components/reco-onboarding/modal'
import {
  selectDefaultDuration,
  getMonthlyPrice,
  checkBoxProductRules,
  isSubscription,
  translatePatientSex,
  displayRecommendedDuration,
  calculateDiscountPercentage,
} from '../../utils/helpers'
import { PageType } from '../../utils/constants'
import {
  useCalculPrescriptionAmounts,
  useGetPrescriptionFiles,
  useGetPrescriptionStatus,
  useGetPublicPrescriptionById,
  usePatchPrescriptionStatusVisitedAt,
} from '../../services/api/prescriptions/prescriptions'
import Arguments from '../../components/public-reco/arguments'
import Faq from '../../components/public-reco/faq'
import Testimonials from '../../components/public-reco/testimonials'
import Footer from '../../components/public-reco/footer'
import {
  PrescriptionAmountsDTO,
  PrescriptionStatus,
  PrescriptionTakeDTO,
} from '../../services/api/types'
import { useGetPatient } from '../../services/api/patients/patients'
import { selectPackagingTypeFromPrescriptionType } from '../../utils/helpers'
import { getLastNonSentOrOrderedStatusId } from '../prescriptions/new-prescription-marketplace'
import ShippingProgressBar from '../../components/payment/payment-settings/pricing/shipping-progress-bar'
import { freeShippingThreshold } from '../../utils/constants'
import AffiliatedItemsList from './affiliated-items-list'
import AffiliationInfoBanner from './affiliation-info-banner'
import { getProductLaboratoryInstructionPhases } from '../../services/api/instruction-phases/instruction-phases'

const RecommendationPage = ({ match, location }) => {
  // Attributes
  const image1Src =
    'https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/Vector.png?alt=media&token=d48d87a8-9ed1-4a2b-9e96-d99272e5b995'
  // Path params
  const prescriptionId = Number(match.params.prescriptionId)
  const { goToCheckout } = queryString.parse(location.search)
  const [takes, setTakes] = useState<PrescriptionTakeDTO[]>([])
  // used for forced component re-render
  const [, setIsTakesLoading] = useState(false)

  const { data: files } = useGetPrescriptionFiles(prescriptionId, {
    query: {
      enabled: !!prescriptionId,
    },
  })

  const { mutateAsync: patchPrescriptionStatusVisitedAt } =
    usePatchPrescriptionStatusVisitedAt()

  // Fetch data
  const { data: prescription, isLoading: prescriptionLoading } =
    useGetPublicPrescriptionById(prescriptionId, {
      query: {
        enabled: !!prescriptionId,
      },
    })

  const { data: patient } = useGetPatient(
    prescription?.prescriberId,
    prescription?.patientId,
    {
      query: {
        enabled: !!prescription?.prescriberId && !!prescription?.patientId,
      },
    }
  )

  const isMarketPlace = prescription?.prescriptionType === 'MARKETPLACE'

  const isOrdered =
    prescription?.statuses?.find(
      s => s.status === PrescriptionStatus.ORDERED
    ) !== undefined

  const { data: lastStatus } = useGetPrescriptionStatus(
    prescription?.id,
    getLastNonSentOrOrderedStatusId(prescription?.statuses),
    {
      query: {
        enabled: !!prescription?.id && !!prescription?.statuses?.[0]?.id,
      },
    }
  )

  const isTakesMoreThanThreeProducts = !!lastStatus?.takes?.find(
    i => i?.items?.length >= 2
  )

  const prescriberFirstName = prescription?.prescriberFirstName
  const prescriberLastName = prescription?.prescriberLastName
  const discountCode = prescription?.discount?.code
  const discountCodePercentage = prescription?.discount?.percentage

  const [isPrescriberContactDialogOpen, setIsPrescriberContactDialogOpen] =
    useState(false)

  // Cart store
  const { createCart, loading: createCartLoading } = useInitCart()
  const { fetchCart, loading: fetchCartLoading } = useFetchCart()
  const { cartAttributesUpdate, loading: cartAttributesUpdateLoading } =
    useCartAttributesUpdate()
  const { addToCart, loading: addToCartLoading } = useAddToCart()
  const { updateCart, loading: updateCartLoading } = useUpdateCart()
  const { cartLinesRemove } = useCartLinesRemove()
  const cart = useSelector((state: any) => getCart(state))

  const cartItemCount = cart?.lines?.edges?.length

  const missingItems = getMissingItems(lastStatus, cart)

  // States
  const [hasRun, setHasRun] = useState(false)
  const [isOpenDialogMoreProducts, setIsOpenDialogMoreProducts] =
    useState(false)
  const [isCartSet, setIsCartSet] = useState(false)

  let checkoutUrl = prescription && cart?.checkoutUrl
  if (discountCode) {
    checkoutUrl += `?discount=${discountCode}&attributes[prescription_id]=${prescriptionId}`
  } else {
    checkoutUrl += `?attributes[prescription_id]=${prescriptionId}`
  }

  if (prescription?.patientLastName)
    checkoutUrl += `&checkout[shipping_address][last_name]=${prescription?.patientLastName}`

  if (prescription?.patientFirstName)
    checkoutUrl += `&checkout[shipping_address][first_name]=${prescription?.patientFirstName}`

  if (prescription?.patientEmail)
    checkoutUrl += `&checkout[email]=${prescription.patientEmail}`

  const [amounts, setAmounts] = useState<PrescriptionAmountsDTO>()

  const discountPercentage = calculateDiscountPercentage(
    Number(cart?.estimatedCost?.totalAmount?.amount),
    Number(cart?.estimatedCost?.subtotalAmount?.amount)
  )

  const {
    affiliatedItemTakes,
    affiliatedItemCount,
  }: {
    affiliatedItemTakes: PrescriptionTakeDTO[]
    affiliatedItemCount: number
  } = lastStatus?.takes?.reduce(
    (acc, take) => {
      const filteredItems = (take.items ?? []).filter(
        item => item.product.isAffiliated
      )

      if (filteredItems.length) {
        acc.affiliatedItemTakes.push({
          ...take,
          items: filteredItems,
        })
      }

      acc.affiliatedItemCount += filteredItems.length
      return acc
    },
    { affiliatedItemTakes: [], affiliatedItemCount: 0 }
  ) || { affiliatedItemTakes: [], affiliatedItemCount: 0 }

  // Functions
  const addVariantToCart = async (
    productId,
    merchandiseId,
    takeNumber,
    handle,
    takeName,
    quantity = 1,
    instructionDuration = '',
    instructionQuantity = '',
    instructionTiming = '',
    takeFormat?: null
  ) => {
    const phases = await getProductLaboratoryInstructionPhases(productId)
    const structuredPhases = phases.map(phase => ({
      _name: phase.name,
      _duration: phase.duration,
      _period: phase.period,
      _intakes: phase.intakes.map(intake => ({
        _quantity: intake.quantity,
        _periods: intake.lines.map(line => ({
          _period: line.period,
        })),
      })),
    }))
    let attributes = [
      {
        key: '_handle',
        value: handle,
      },
      {
        key: '_prise',
        value: takeNumber ?? '/',
      },
      {
        key: '_nomPrise',
        value: takeName ?? '/',
      },
      {
        key: '_phases',
        value: JSON.stringify(structuredPhases),
      },
    ]
    if (takeFormat)
      attributes = [...attributes, { key: '_takeFormat', value: takeFormat }]
    if (instructionDuration)
      attributes = [
        ...attributes,
        { key: '_instructionDuration', value: `${instructionDuration}` },
      ]
    if (instructionQuantity)
      attributes = [
        ...attributes,
        { key: '_instructionQuantity', value: `${instructionQuantity}` },
      ]
    if (instructionTiming)
      attributes = [
        ...attributes,
        { key: '_instructionTiming', value: `${instructionTiming}` },
      ]
    return addToCart({
      variables: {
        lines: [
          {
            merchandiseId: merchandiseId,
            quantity,
            attributes,
          },
        ],
        cartId: cart.id,
      },
    }).then(() => {
      setIsOpenDialogMoreProducts(true)
    })
  }

  const updateVariantToCart = (cartId, cartLineId, qty, attributes) => {
    updateCart({
      variables: {
        cartId: cartId,
        lines: {
          id: cartLineId,
          quantity: qty,
          attributes,
        },
      },
    })
  }

  const removeVariantsToCart = (cartId, lineIds) => {
    return cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: lineIds,
      },
    })
  }

  // Update product prices to subscription prices
  const updateSubscription = async () => {
    if (!cart?.id) return

    // Get lines for shopify
    const lines = await mapSubscriptionLines(
      cart,
      selectPackagingTypeFromPrescriptionType(prescription?.prescriptionType)
    )

    updateCart({
      variables: {
        cartId: cart.id,
        lines,
      },
    })
  }

  // Update product prices to one time prices
  const updateOneTimePurchase = async duration => {
    if (!cart?.id) return

    // Get lines for shopify
    const lines = await mapOneTimePurchaseLines(
      cart,
      duration,
      selectPackagingTypeFromPrescriptionType(prescription?.prescriptionType)
    )

    return updateCart({
      variables: {
        cartId: cart.id,
        lines,
      },
    })
  }

  const createNewCart = async () => {
    const attributes = getCartAttributes(prescriptionId)
    const takes = lastStatus?.takes?.map(take => ({
      ...take,
      items: take.items.filter(item => !item.product.isAffiliated),
    }))

    const lineItems = await getLineItemsForCart(takes, prescription)

    let cartInput: any = {
      lines: lineItems?.filter(item => item !== null && item !== undefined),
      attributes: attributes,
    }
    if (discountCode && discountCodePercentage > 0) {
      cartInput.discountCodes = [discountCode]
    }
    createCart({
      variables: {
        cartInput: cartInput,
      },
    })
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=28420'
    script.id = 'gorgias-chat-widget-install-v2'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const { mutateAsync: calculatePrice } = useCalculPrescriptionAmounts()

  useEffect(() => {
    async function fetchAndSetAmounts() {
      try {
        const result = await calculatePrice({
          data: {
            recommendedDuration: prescription?.recommendedDuration,
            prescriptionType: prescription?.prescriptionType,
            products: lastStatus?.takes?.flatMap(take =>
              take?.items?.map(item => ({
                variantTitle: item?.variantTitle,
                quantity: item?.quantity,
                productHandle: item?.product?.handle,
              }))
            ),
          },
        })
        setAmounts(result) // Assuming the result object has the fetched amounts in a data property
      } catch (error) {
        // Handle error accordingly
      }
    }

    fetchAndSetAmounts()
  }, [lastStatus, calculatePrice])

  // Effects
  useEffect(() => {
    // Set cookie for Shopify
    if (prescriptionId) {
      let expires = 'max-age=' + 30 * 24 * 60 * 60
      document.cookie = 'reco=' + prescriptionId + '; ' + expires + '; path=/'
    }
  }, [prescriptionId])

  useEffect(() => {
    cart &&
      prescription?.prescriptionType !== 'MARKETPLACE' &&
      checkBoxProductRules(
        cart,
        getMonthlyPrice(cart),
        cartLinesRemove,
        addToCart,
        isTakesMoreThanThreeProducts,
        selectDefaultDuration(cart)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  // Set default correct prices
  useEffect(() => {
    if (
      isCartSet ||
      !lastStatus?.takes?.length ||
      !prescription?.recommendedDuration?.length
    )
      return

    const fetchAndUpdateCart = async () => {
      if (
        `${localStorage.getItem('cartId')}${prescriptionId}` ===
        localStorage.getItem('sessionId')
      ) {
        fetchCart({
          variables: { cartId: localStorage.getItem('cartId') },
        })
      } else {
        const takes = lastStatus?.takes?.map(take => ({
          ...take,
          items: take.items.filter(item => !item.product.isAffiliated),
        }))

        // Map products to Shopify Items
        const lineItems = await lineItemsForCart(
          prescription?.id,
          takes,
          prescription?.recommendedDuration,
          selectPackagingTypeFromPrescriptionType(
            prescription?.prescriptionType
          )
        )

        const patientFirstname = prescription?.patientFirstName
        const patientLastname = prescription?.patientLastName

        // Get attributes
        const attributes = [
          {
            key: 'prescription_id',
            value: `${prescriptionId}`,
          },
          {
            key: 'prescriber_firstname',
            value: `${prescription?.prescriberFirstName}`,
          },
          {
            key: 'prescriber_lastname',
            value: `${prescription?.prescriberLastName}`,
          },
          ...createAttribute('patient_firstname', patientFirstname),
          ...createAttribute('patient_lastname', patientLastname),
          {
            key: 'patient_email',
            value: `${prescription?.patientEmail}`,
          },
          {
            key: 'prescriber_id',
            value: `${prescription?.prescriberId}`,
          },
        ]

        if (prescription?.customMessage) {
          attributes.push({
            key: 'custom_message',
            value: `${prescription?.customMessage}`,
          })
        }

        // Check prescription has already been ordered
        if (isOrdered) {
          attributes.push({
            key: 're_ordered',
            value: 'true',
          })
        }

        // Create cart
        let cartInput: any = {
          lines: lineItems?.filter(item => item !== null && item !== undefined),
          attributes: attributes,
        }
        if (discountCode && discountCodePercentage > 0) {
          cartInput.discountCodes = [discountCode]
        }
        createCart({
          variables: {
            cartInput: cartInput,
          },
        })
      }

      setIsCartSet(true)
    }

    fetchAndUpdateCart()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription, lastStatus])

  useEffect(() => {
    if (!prescription?.prescriptionType) return

    const fetchData = async () => {
      setIsTakesLoading(true)
      try {
        const mappedData = await mapShopifyCartToPrescriptionTakeDTOs(
          cart,
          JSON.parse(JSON.stringify(lastStatus?.takes)),
          selectPackagingTypeFromPrescriptionType(
            prescription?.prescriptionType
          )
        )
        setTakes(mappedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsTakesLoading(false)
      }
    }
    fetchData()
  }, [cart, lastStatus, prescription?.prescriptionType])

  // Shopify automatically deletes the cart when the customer completes their checkout
  // Create a new checkout if customer completes his checkout
  useEffect(() => {
    if (hasRun || cart !== null) return

    createNewCart()
    setHasRun(true)
  }, [cart])

  useEffect(() => {
    if (goToCheckout === 'true' && cart?.checkoutUrl) {
      window.location.href = cart?.checkoutUrl
    }
  }, [cart, goToCheckout])

  useEffect(() => {
    patchPrescriptionStatusVisitedAt({
      prescriptionId: prescriptionId,
    })
  }, [])

  // Render
  return (
    <Layout
      setIsOpenDialogMoreProducts={setIsOpenDialogMoreProducts}
      from='recommendation'
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Checkout prescription</title>
      </Helmet>
      <Container>
        {prescription && (
          <PrescriberContactDialog
            isOpen={isPrescriberContactDialogOpen}
            onClose={() => setIsPrescriberContactDialogOpen(false)}
            prescriberFirstName={prescription?.prescriberFirstName}
            prescriberLastName={prescription?.prescriberLastName}
            prescriberEmail={prescription?.prescriberEmail}
            prescriberId={prescription?.prescriberId}
            prescriptionId={prescription?.id}
            patientFirstName={prescription?.patientFirstName}
            patientLastName={prescription?.patientLastName}
            patientEmail={prescription?.patientEmail}
            customMessage={prescription?.customMessage}
          />
        )}
        <Modal
          prescriberName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          prescriptionFirstname={prescription?.patientFirstName}
          cart={cart}
          isTakesMoreThanThreeProducts={isTakesMoreThanThreeProducts}
          isMarketPlace={isMarketPlace}
          patientEmail={prescription?.patientEmail}
          checkoutUrl={checkoutUrl}
        />
        <Content>
          {prescriptionLoading && <Spinner fullScreen={undefined} />}
          {!prescriptionLoading && prescription && (
            <div>
              {affiliatedItemCount > 0 && (
                <AffiliationInfoBanner
                  affiliatedItemCount={affiliatedItemCount}
                  totalPrice={amounts?.affiliatedTotal}
                />
              )}
              <BodySection>
                <Left>
                  {!isMarketPlace && isTakesMoreThanThreeProducts && (
                    <BoxInfo />
                  )}
                  <OrderSummary>
                    {prescription?.patientLastName ? (
                      <MainTitle>
                        Bonjour {translatePatientSex(patient?.sexe)}{' '}
                        {prescription?.patientLastName}, voici votre
                        recommandation
                        <br />
                      </MainTitle>
                    ) : (
                      <MainTitle>Bonjour, voici votre recommandation</MainTitle>
                    )}
                    {prescriberFirstName && (
                      <MainTitle>
                        par{' '}
                        <span style={{ color: '#03865C' }}>
                          {prescriberFirstName} {prescriberLastName}
                        </span>
                      </MainTitle>
                    )}
                    {(prescription.customMessage || prescription?.title) && (
                      <NoteWrapper>
                        <NoteWrapperHeader>
                          Notes de{' '}
                          <span style={{ color: '#03865C' }}>
                            {prescriberFirstName} {prescriberLastName}
                          </span>
                        </NoteWrapperHeader>
                        <Note>
                          <NoteTitle>{prescription.title}</NoteTitle>
                          {prescription.customMessage || '-'}
                        </Note>
                      </NoteWrapper>
                    )}
                    {files?.length > 0 && (
                      <FilesWrapper>
                        <FilesWrapperIcon>
                          <ImAttachment />
                        </FilesWrapperIcon>
                        <FilesWrapperContent>
                          <FilesWrapperTitle>
                            Télécharger les fichiers que {prescriberFirstName}{' '}
                            vous a envoyés
                          </FilesWrapperTitle>
                          <FileList>
                            {files.map((file, index) => (
                              <File
                                key={index}
                                href={file.fileUrl}
                                target='_blank'
                              >
                                Fichier {index + 1}
                              </File>
                            ))}
                          </FileList>
                        </FilesWrapperContent>
                      </FilesWrapper>
                    )}
                    {!isMarketPlace && (
                      <PrescriptionProductsList
                        prescriberName={prescriberFirstName}
                        cart={cart}
                        updateVariantToCart={async (
                          nodeId,
                          quantity,
                          attributes
                        ) =>
                          updateVariantToCart(
                            cart.id,
                            nodeId,
                            quantity,
                            attributes
                          )
                        }
                        removeVariantsToCart={async nodeIds =>
                          removeVariantsToCart(cart.id, nodeIds)
                        }
                        isLoading={!!(createCartLoading || fetchCartLoading)}
                        takes={takes}
                        recommendedDuration={prescription.recommendedDuration}
                        titleInCure={
                          <SecondaryTitle>
                            Vos produits en sachets{' '}
                          </SecondaryTitle>
                        }
                        titleOutCure={
                          <SecondaryTitle>
                            Vos produits hors sachets
                          </SecondaryTitle>
                        }
                        abilityToManageQuantity
                      />
                    )}
                    {isMarketPlace && (
                      <ProductListContainer>
                        <MarketplacePrescriptionProductsList
                          isPatientCatalog={true}
                          takes={takes}
                          cart={cart}
                          updateVariantToCart={async (
                            nodeId,
                            quantity,
                            attributes
                          ) =>
                            updateVariantToCart(
                              cart.id,
                              nodeId,
                              quantity,
                              attributes
                            )
                          }
                          removeVariantsToCart={async nodeIds =>
                            removeVariantsToCart(cart.id, nodeIds)
                          }
                          isLoading={!!(createCartLoading || fetchCartLoading)}
                          abilityToManageQuantity
                          discountPercentage={
                            prescription?.discount?.percentage
                          }
                          prescriptionId={prescription?.id}
                          missingItems={missingItems}
                        />
                        {affiliatedItemCount > 0 && (
                          <AffiliatedItemsList
                            takes={affiliatedItemTakes}
                            isPatientCatalog={true}
                            prescriptionId={prescription?.id}
                            affiliatedItemCount={affiliatedItemCount}
                          />
                        )}
                      </ProductListContainer>
                    )}

                    <ShippingProgressBarWrapper>
                      <ShippingProgressBar
                        isPublic
                        currentCartAmount={Number(
                          cart?.estimatedCost?.totalAmount?.amount
                        )}
                        freeShippingThreshold={freeShippingThreshold}
                      />
                    </ShippingProgressBarWrapper>
                  </OrderSummary>
                </Left>

                <Right>
                  <PaymentSettings
                    isMarketplacePrescription={true}
                    displayMAndDown='none'
                    updateCartLoading={updateCartLoading}
                    cartAttributesUpdateLoading={cartAttributesUpdateLoading}
                    cartAttributesUpdate={cartAttributesUpdate}
                    prescription={prescription}
                    selectDefaultDuration={selectDefaultDuration}
                    cart={cart}
                    updateSubscription={updateSubscription}
                    updateOneTimePurchase={updateOneTimePurchase}
                    isSubscription={isSubscription(cart)}
                    checkoutUrl={checkoutUrl}
                    isTakesMoreThanThreeProducts={isTakesMoreThanThreeProducts}
                    isMarketPlace={isMarketPlace}
                    discountCode={prescription?.discount?.code}
                    discountPercentage={prescription?.discount?.percentage}
                    setSliderProductDialogOpen={setIsOpenDialogMoreProducts}
                  />
                </Right>
              </BodySection>

              {isOpenDialogMoreProducts && (
                <ProductsUpsellsDialog
                  takes={takes}
                  isDialogOpen={isOpenDialogMoreProducts}
                  setIsDialogOpen={setIsOpenDialogMoreProducts}
                  addProduct={addVariantToCart}
                  recommendedDuration={selectDefaultDuration(cart)}
                  addToCartLoading={addToCartLoading}
                  prescriptionType={prescription?.prescriptionType}
                  enableFavorite={false}
                  from={PageType.RECOMMENDATION}
                />
              )}
            </div>
          )}

          {!prescriptionLoading && prescription === undefined && (
            <NoFound
              title='La recommandation a été supprimée par votre praticien'
              descritpion=''
            />
          )}

          <Arguments />
          <Testimonials />
          <Faq />

          {cart?.checkoutUrl && (
            <FooterBar>
              <PricingLine>
                <span>
                  Sous-total
                  {!isMarketPlace && (
                    <Highlight>
                      {displayRecommendedDuration(selectDefaultDuration(cart))}
                    </Highlight>
                  )}
                </span>
                <span>
                  €
                  {Number(cart?.estimatedCost.subtotalAmount.amount).toFixed(2)}
                </span>
              </PricingLine>

              {discountCode && (
                <PricingLine>
                  <span>Réduction</span>
                  <span>{discountPercentage}%</span>
                </PricingLine>
              )}

              <TotalPricingLine>
                <span>Total</span>
                <span>
                  €{Number(cart?.estimatedCost?.totalAmount?.amount).toFixed(2)}
                </span>
              </TotalPricingLine>

              <Button
                as='a'
                href={`${checkoutUrl}`}
                style={{ height: '43px', fontSize: '15px' }}
                appearance='primary'
                iconLeft={undefined}
                isDisabled={!cartItemCount}
                isLoading={undefined}
                isActive={undefined}
              >
                Continuer vers le paiement
              </Button>
            </FooterBar>
          )}
        </Content>
        <Footer />
      </Container>
    </Layout>
  )
}

export default RecommendationPage
