import {
  IntakeAnyPeriodType,
  IntakePeriodType,
  IntakeSpecificPeriodType,
  PhaseDurationType,
  PhasePeriodType,
  PrescriptionItemPhaseDTO,
  ProtocolPhaseDTO,
} from '../../../../../services/api/types'
import { Option } from '../../../../../utils/constants'
import lodash from 'lodash'

export function getPhaseDurationOptions(): Option[] {
  const options = Object.values(PhaseDurationType)

  const order = [
    PhaseDurationType.AS_NEEDED,
    PhaseDurationType.CONTINUOUS,
    PhaseDurationType.ONE_WEEK,
    PhaseDurationType.TWO_WEEKS,
    PhaseDurationType.THREE_WEEKS,
    PhaseDurationType.ONE_MONTH,
    PhaseDurationType.TWO_MONTHS,
    PhaseDurationType.THREE_MONTHS,
    PhaseDurationType.FOUR_MONTHS,
    PhaseDurationType.FIVE_MONTHS,
    PhaseDurationType.SIX_MONTHS,
    PhaseDurationType.UNTIL_SYMPTOM_RESOLUTION,
    PhaseDurationType.UNKNOWN,
  ]

  const sorted = lodash.orderBy(options, option => order.indexOf(option))

  return sorted
    .map(duration => {
      return mapPhaseDurationToOption(duration)
    })
    .filter(option => option.value !== PhaseDurationType.UNKNOWN)
}

export function mapPhaseDurationToOption(duration: PhaseDurationType): Option {
  return {
    label: mapPhaseDurationToLabel(duration),
    value: duration,
  }
}

export function mapPhaseDurationToLabel(duration: PhaseDurationType) {
  switch (duration) {
    case PhaseDurationType.ONE_WEEK:
      return 'Pendant 1 semaine'
    case PhaseDurationType.TWO_WEEKS:
      return 'Pendant 2 semaines'
    case PhaseDurationType.THREE_WEEKS:
      return 'Pendant 3 semaines'
    case PhaseDurationType.ONE_MONTH:
      return 'Pendant 1 mois'
    case PhaseDurationType.TWO_MONTHS:
      return 'Pendant 2 mois'
    case PhaseDurationType.THREE_MONTHS:
      return 'Pendant 3 mois'
    case PhaseDurationType.FOUR_MONTHS:
      return 'Pendant 4 mois'
    case PhaseDurationType.FIVE_MONTHS:
      return 'Pendant 5 mois'
    case PhaseDurationType.SIX_MONTHS:
      return 'Pendant 6 mois'
    case PhaseDurationType.CONTINUOUS:
      return 'En continu'
    case PhaseDurationType.UNTIL_SYMPTOM_RESOLUTION:
      return "Jusqu'à la résolution des symptômes"
    case PhaseDurationType.AS_NEEDED:
      return 'Au besoin'
    case PhaseDurationType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}
export function mapPhasePDFDurationToLabel(duration: PhaseDurationType) {
  switch (duration) {
    case PhaseDurationType.ONE_WEEK:
      return '1 semaine'
    case PhaseDurationType.TWO_WEEKS:
      return '2 semaines'
    case PhaseDurationType.THREE_WEEKS:
      return '3 semaines'
    case PhaseDurationType.ONE_MONTH:
      return '1 mois'
    case PhaseDurationType.TWO_MONTHS:
      return '2 mois'
    case PhaseDurationType.THREE_MONTHS:
      return '3 mois'
    case PhaseDurationType.FOUR_MONTHS:
      return '4 mois'
    case PhaseDurationType.FIVE_MONTHS:
      return '5 mois'
    case PhaseDurationType.SIX_MONTHS:
      return '6 mois'
    case PhaseDurationType.CONTINUOUS:
      return 'En continu'
    case PhaseDurationType.UNTIL_SYMPTOM_RESOLUTION:
      return "Jusqu'à la résolution des symptômes"
    case PhaseDurationType.AS_NEEDED:
      return 'Au besoin'
    case PhaseDurationType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}

export function getPhasePeriodOptions(): Option[] {
  return Object.values(PhasePeriodType)
    .map(period => {
      return mapPhasePeriodToOption(period)
    })
    .filter(option => option.value !== PhasePeriodType.UNKNOWN)
}

export function mapPhasePeriodToOption(period: PhasePeriodType): Option {
  return {
    label: mapPhasePeriodToLabel(period),
    value: period,
  }
}

export function mapPhasePeriodToLabel(period: PhasePeriodType) {
  switch (period) {
    case PhasePeriodType.AFTER_MEAL:
      return 'Après le repas'
    case PhasePeriodType.BEFORE_MEAL:
      return 'Avant le repas'
    case PhasePeriodType.DURING_MEAL:
      return 'Pendant le repas'
    case PhasePeriodType.FASTING:
      return 'À jeun'
    case PhasePeriodType.ANYTIME:
      return 'Peu importe'
    case PhasePeriodType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}

export function getIntakePeriodOptions(): Option[] {
  return Object.values(IntakePeriodType)
    .map(period => {
      return mapIntakePeriodToOption(period)
    })
    .filter(option => option.value !== IntakePeriodType.UNKNOWN)
}

export function mapIntakePeriodToOption(period: IntakePeriodType): Option {
  return {
    label: mapIntakePeriodToLabel(period),
    value: period,
  }
}

export function mapIntakePeriodToLabel(period: IntakePeriodType) {
  switch (period) {
    case IntakePeriodType.ONCE_PER_DAY:
      return '1x par jour'
    case IntakePeriodType.TWICE_PER_DAY:
      return '2x par jour'
    case IntakePeriodType.THREE_TIMES_PER_DAY:
      return '3x par jour'
    case IntakePeriodType.FOUR_TIMES_PER_DAY:
      return '4x par jour'
    case IntakePeriodType.FIVE_TIMES_PER_DAY:
      return '5x par jour'
    case IntakePeriodType.MORNING:
      return 'Matin'
    case IntakePeriodType.NOON:
      return 'Midi'
    case IntakePeriodType.AFTERNOON:
      return 'Après-midi'
    case IntakePeriodType.EVENING:
      return 'Soir'
    case IntakePeriodType.BEDTIME:
      return 'Au coucher'
    case IntakePeriodType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}

export function getIntakePeriodFrequencyOptions(): Option[] {
  return Object.values(IntakeAnyPeriodType)
    .map(period => {
      return mapIntakeAnyPeriodToOption(period)
    })
    .filter(option => option.value !== IntakeAnyPeriodType.UNKNOWN)
}

export function mapIntakeAnyPeriodToOption(
  period: IntakeAnyPeriodType
): Option {
  return {
    label: mapIntakeAnyPeriodToLabel(period),
    value: period,
  }
}

export function mapIntakeAnyPeriodToLabel(period: IntakeAnyPeriodType) {
  switch (period) {
    case IntakeAnyPeriodType.ONCE_PER_DAY:
      return '1x par jour'
    case IntakeAnyPeriodType.TWICE_PER_DAY:
      return '2x par jour'
    case IntakeAnyPeriodType.THREE_TIMES_PER_DAY:
      return '3x par jour'
    case IntakeAnyPeriodType.FOUR_TIMES_PER_DAY:
      return '4x par jour'
    case IntakeAnyPeriodType.FIVE_TIMES_PER_DAY:
      return '5x par jour'
    case IntakeAnyPeriodType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}

export function getIntakeSpecificTimingPeriodOptions(): Option[] {
  return Object.values(IntakeSpecificPeriodType)
    .map(period => {
      return mapIntakeSpecificTimingPeriodToOption(period)
    })
    .filter(option => option.value !== IntakeSpecificPeriodType.UNKNOWN)
}

export function mapIntakeSpecificTimingPeriodToOption(
  period: IntakeSpecificPeriodType
): Option {
  return {
    label: mapIntakeSpecificTimingPeriodToLabel(period),
    value: period,
  }
}

export function mapIntakeSpecificTimingPeriodToLabel(
  period: IntakeSpecificPeriodType
) {
  switch (period) {
    case IntakeSpecificPeriodType.MORNING:
      return 'Matin'
    case IntakeSpecificPeriodType.NOON:
      return 'Midi'
    case IntakeSpecificPeriodType.AFTERNOON:
      return 'Après-midi'
    case IntakeSpecificPeriodType.EVENING:
      return 'Soir'
    case IntakeSpecificPeriodType.BEDTIME:
      return 'Au coucher'
    case IntakeSpecificPeriodType.UNKNOWN:
      return 'Non spécifié'
    default:
      return ''
  }
}

export function isMultiPhases(
  phases: (PrescriptionItemPhaseDTO | ProtocolPhaseDTO)[]
) {
  return phases.length > 1 || phases[0].intakes.length > 1
}

// Scrolls the page from a starting position (start) to a target position (target) over a specified time (duration in milliseconds).
export function smoothScrollTo(
  start: number,
  target: number,
  duration: number
) {
  let startTime = null

  // Callback that calculates how much time has passed and scrolls the page accordingly
  const step = (timestamp: number) => {
    if (!startTime) startTime = timestamp
    const progress = timestamp - startTime

    // Smooths the scrolling motion by making it accelerate at the start, decelerate at the end, and move quickly in the middle
    const easeInOutCubic = (t: number) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2

    const percentage = Math.min(progress / duration, 1)
    const easedPercentage = easeInOutCubic(percentage)

    // Scroll the window vertically to the new position
    window.scrollTo(0, start + (target - start) * easedPercentage)

    // If the animation isn't finished (progress < duration), request another frame to continue the scroll.
    if (progress < duration) {
      requestAnimationFrame(step)
    }
  }

  // Start the initial loop
  requestAnimationFrame(step)
}
