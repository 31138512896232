import styled from 'styled-components/macro'

export const Container = styled.div`
  padding: 20px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.h3`
  font-size: 20px;
  color: #080038;
  text-align: center;
  margin-bottom: 16px;
`

export const SubTitle = styled.h5`
  font-size: 12px;
  text-align: center;
  color: #080038;
  margin-bottom: 30px;
  font-weight: 300;
  opacity: 0.6;
`
export const Input = styled.input`
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  flex: none;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
`

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 41px;

  background: #3a845e;
  border-radius: 8px;
  border: none;

  flex: none;
  order: 0;
  flex-grow: 0;

  color: white;
`
