import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import {
  Container,
  Header,
  FlexItem,
  Text,
  ReductionTitle,
  Footer,
  Total,
  DropdownContent,
  DropDownContentItem,
  InlineButton,
} from './style'
import toast from 'react-hot-toast'
import mixpanel from 'mixpanel-browser'
import { DropdownButton, MiniSpinner, Button } from '../../ui'
import EditDiscountCodeModal from '../../prescription/new-prescription-draft-preview/edit-discount-code-modal'
import { useCreateProtocol } from '../../../services/api/protocols/protocols'
import { useGetMe } from '../../../utils/hooks/getMe'

import BlockAccessModal from '../../../components/block-access-modal'
import ShippingProgressBar from '../../../components/payment/payment-settings/pricing/shipping-progress-bar'
import { freeShippingThreshold } from '../../../utils/constants'
import axios from 'axios'
import {
  usePushPrescriptionStatus,
  getPrescriptionById,
  getPrescriptionStatus,
} from '../../../services/api/prescriptions/prescriptions'
import {
  PushPrescriptionStatus,
  PrescriptionStatusDTO,
} from '../../../services/api/types'
import {
  translatePatientSex,
  getRecommendationLink,
  processTake,
} from '../../../utils/helpers'
import { useQueryClient } from 'react-query'
import { refetchPrescription } from '../../../utils/helpers/refetch'
import MarkedAsSent from '../../reco/marked-as-sent'
import { CreateProtocolDTOSchema } from './types'

const SummaryOrder = ({
  onPrescriptionSubmitted,
  onPrescriptionSent,
  amounts,
  firstName,
  lastName,
  draft,
  authUserId,
  type,
  protocolName,
  isAmountsLoading,
  isAmountsSuccess,
  setIsShareDialogOpen,
  productsWithTakes,
  lastStatus,
  prescription,
  patient,
  setDraftChanged,
  saveBookletPdf,
  statuses,
}: {
  onPrescriptionSubmitted?: any
  onPrescriptionSent?: any
  amounts: any
  firstName?: string
  lastName?: string
  draft?: any
  authUserId?: any
  type?: string
  protocolName?: string
  isAmountsLoading?: boolean
  isAmountsSuccess?: boolean
  setIsShareDialogOpen?: (v: boolean) => void
  productsWithTakes?: any[]
  lastStatus?: any
  prescription?: any
  patient?: any
  setDraftChanged?: (v: boolean) => void
  saveBookletPdf?: any
  statuses?: PrescriptionStatusDTO[]
}) => {
  // Attributes
  const queryClient = useQueryClient()
  const { location } = useHistory()
  const [isMarkedAsSentDialogOpen, setIsMarkedAsSentDialogOpen] =
    useState(false)
  const [isSentOnClick, setIsSentOnClick] = useState(false)
  const { prescriptionId } = queryString.parse(location.search)
  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)
  const { data: currentPrescriber } = useGetMe()
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const subTotal = amounts?.subTotal?.toFixed(2) ?? 0
  const total = amounts?.total?.toFixed(2) ?? 0
  const discountPercentage = amounts?.discount?.percentage
  const discountCode = amounts?.discount?.code
  const isPrescriptionAbleToSend =
    !!draft?.patientId && !!draft?.takes?.[0]?.items?.length

  const { mutateAsync: addProtocole } = useCreateProtocol({
    mutation: {
      onSuccess: () => {
        history.push('/protocoles')
      },
    },
  })

  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        refetchPrescription(
          queryClient,
          currentPrescriber?.id,
          prescription?.id
        )
      },
      onError: () => {},
    },
  })

  // Funtions
  async function onPushSentStatus(prescriptionId) {
    const prescriptionById = await getPrescriptionById(
      currentPrescriber?.id,
      prescriptionId
    )
    const lastStatus = await getPrescriptionStatus(
      prescriptionId,
      prescriptionById?.statuses?.[0]?.id
    )
    const newTakes = await Promise.all(
      lastStatus.takes.map(take => processTake(take, prescriptionId))
    )

    await pushStatus({
      prescriberId: currentPrescriber?.id,
      prescriptionId: prescriptionId,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  async function sendPrescription(data) {
    await toast
      .promise(
        axios
          .post(
            'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
            data
          )
          .catch(() =>
            toast.error("Une erreur est survenue lors de l'envoi de l'email.")
          ),
        {
          loading: 'en cours...',
          success: "L'email a été envoyé avec succès !",
          error:
            'Une erreur est survenue lors de la mise à jours du status de la prescription. ',
        },
        {
          duration: 3000,
        }
      )
      .then(() => onPushSentStatus(data?.prescriptionId))
  }

  async function onSendPrescription(id) {
    const prescriptionById = await getPrescriptionById(
      currentPrescriber?.id,
      id
    )
    sendPrescription({
      patient_lastname: patient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      patient_email: patient?.email,
      patient_firstname: patient?.firstName,
      patient_phonenumber: patient?.phone,
      custom_message: prescriptionById?.customMessage,
      prescriber_email: currentPrescriber?.email,
      prescriber_fullname: `${currentPrescriber?.firstName} ${currentPrescriber?.lastName}`,
      purchase_url: getRecommendationLink(id),
      promo_code: prescriptionById?.discount?.code,
      discount: prescriptionById?.discount?.percentage,
      prescriptionId: id,
    })
  }

  const onProtocoleSubmitted = async () => {
    try {
      await CreateProtocolDTOSchema.validate(draft)
    } catch (err) {
      toast.error(err.errors)
      return
    }

    addProtocole({
      prescriberId: authUserId,
      data: {
        name: protocolName,
        description: draft.description,
        recommendedDuration: draft.recommendedDuration,
        takes: draft.takes,
        purchaseOption: draft.purchaseOption,
        protocolType: 'MARKETPLACE',
      },
    })

    mixpanel.track('New protocole', {
      prescriberId: authUserId,
      prescriberFullName: `${firstName} ${lastName}`,
      name: draft.name,
      recommendedDuration: draft.recommendedDuration,
      customMessage: draft.customMessage,
      description: draft.description,
      share: draft.share,
      protocoleTakes: draft.takes,
    })
  }

  useEffect(() => {
    if (isSentOnClick && prescription?.id) {
      setIsSentOnClick(false)
      // onSendPrescription()
    }
  }, [prescription, isSentOnClick])

  function getStatusAction(statuses?: { status: string }[]): string {
    return statuses?.find(i => i.status === 'SENT') ? 'Renvoyer' : 'Envoyer'
  }

  // Render
  return (
    <Container>
      <Header>
        <FlexItem>
          <Text>Sous-total</Text>
          <Text>{subTotal ?? '0.00'}€</Text>
        </FlexItem>

        <FlexItem>
          <div>
            <ReductionTitle>Réduction :</ReductionTitle>
            <Text>
              {discountPercentage ?? '0'}% avec le code
              <strong style={{ fontWeight: 500 }}>{discountCode}</strong>
              {!currentPrescriber?.disableCommission && (
                <Button
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                  onClick={() => {
                    if (currentPrescriber?.isVerified) setIsDialogOpen(true)
                    else setIsBlockAccessModalOpen(true)
                  }}
                  style={{
                    padding: '1px 8px',
                    fontSize: '10px',
                    marginLeft: '10px',
                    fontFamily: 'Poppins',
                  }}
                >
                  Modifier
                </Button>
              )}
            </Text>
          </div>
          <Text style={{ color: '#65B293' }}>
            -{(subTotal - total)?.toFixed(2) ?? '00,00'}€
          </Text>
        </FlexItem>

        {total > 0 && (
          <ShippingProgressBar
            currentCartAmount={total}
            freeShippingThreshold={freeShippingThreshold}
            isPublic={false}
          />
        )}
      </Header>
      <Footer>
        <div>
          <Total>{total}€</Total>
        </div>

        {onPrescriptionSubmitted && (
          <>
            {isAmountsSuccess ? (
              <MiniSpinner />
            ) : (
              <>
                {type === 'protocol' ? (
                  <Button
                    iconLeft={undefined}
                    appearance='primary'
                    isDisabled={undefined}
                    isLoading={undefined}
                    isActive={undefined}
                    style={{
                      border: '1px solid #3a845e',
                    }}
                    onClick={onProtocoleSubmitted}
                  >
                    Enregistrer en protocole
                  </Button>
                ) : (
                  <DropdownButton
                    isAblelToClick={isPrescriptionAbleToSend}
                    // popupContentWidth='130px'
                    popupContentMarginRight='127px'
                    textColor='white'
                    onClick={() => {
                      if (!draft?.takes?.length) {
                        toast.error('Veuillez ajouter un produit')
                        return
                      }

                      if (!draft?.patientId) {
                        toast.error('Veuillez ajouter un client')
                        return
                      }

                      onPrescriptionSubmitted(true).then(id => {
                        setDraftChanged(false)
                        onSendPrescription(id)
                        if (!prescriptionId)
                          history.push(
                            `${history.location.pathname}${history.location.search}&prescriptionId=${id}&action=update`
                          )
                      })
                    }}
                    linkText={
                      <InlineButton>{getStatusAction(statuses)}</InlineButton>
                    }
                    DropDownContent={
                      <DropdownContent>
                        <DropDownContentItem
                          onClick={() => saveBookletPdf(true)}
                        >
                          Imprimer
                        </DropDownContentItem>

                        <DropDownContentItem
                          onClick={() => {
                            if (!draft?.takes?.length) {
                              toast.error('Veuillez ajouter un produit')
                              return
                            }
                            onPrescriptionSubmitted(true).then(id => {
                              if (!prescriptionId)
                                history.push(
                                  `${history.location.pathname}${history.location.search}&prescriptionId=${id}&action=update`
                                )
                              window.open(`/r/${id}`, '_blank')
                            })
                          }}
                        >
                          Commander
                        </DropDownContentItem>
                        {prescriptionId && (
                          <DropDownContentItem
                            onClick={() => setIsMarkedAsSentDialogOpen(true)}
                          >
                            Marquer comme envoyé
                          </DropDownContentItem>
                        )}
                      </DropdownContent>
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </Footer>
      <EditDiscountCodeModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        prescriberCode={discountCode}
        discountPercentage={discountPercentage}
      />
      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />

      <MarkedAsSent
        isOpen={isMarkedAsSentDialogOpen}
        setIsOpen={setIsMarkedAsSentDialogOpen}
        lastStatus={lastStatus}
        prescriptionId={prescriptionId}
        status={prescription?.statuses}
      />
    </Container>
  )
}

export default SummaryOrder
