import { NutritionalValues, TakeProductNrv } from './types'

export function calculateNutritionalValues(
  productNrv: TakeProductNrv[]
): NutritionalValues[] {
  if (!productNrv?.length) return []

  const data = productNrv.flatMap(value => {
    if (!value?.nrvValues) return []

    return value.nrvValues
      .map(nrvValue => {
        if (
          !nrvValue?.productNutritional ||
          !value?.phaseTotalIntakeQuantities?.length
        )
          return null

        // For each phase daily intake quantity, calculate the daily nutritional intake percentage based on the daily intake quantity and the nutritional reference value of the ingredient
        const percentages = value.phaseTotalIntakeQuantities.map(quantity =>
          getNutritionalIntakePercentage(
            quantity || 0,
            nrvValue.productNutritional.quantity || 0,
            nrvValue?.value || 0
          )
        )

        // For each phase daily intake quantity, calculate the maximum daily nutritional intake percentage based on the daily intake quantity and the nutritional reference value of the ingredient
        const percentagesMax = value.phaseTotalIntakeQuantities.map(quantity =>
          getNutritionalIntakePercentage(
            quantity || 0,
            nrvValue.productNutritional.quantity || 0,
            nrvValue?.maxValue || 0
          )
        )

        const phaseIntakeTotalQuantities = value.phaseTotalIntakeQuantities.map(
          quantity => ({
            value:
              (quantity || 0) * (nrvValue.productNutritional.quantity || 0),
            unit: nrvValue.productNutritional.unit || '',
          })
        )

        return {
          ingredientName: nrvValue?.nutritionalIntake?.name || '',
          nrv: { value: nrvValue?.value || 0, percentages: percentages || [] },
          dosageMax: {
            value: nrvValue?.maxValue || 0,
            percentages: percentagesMax || [],
          },
          phaseIntakeTotalQuantities: phaseIntakeTotalQuantities || [],
          productsName: [value?.product?.name].filter(Boolean),
        }
      })
      .filter(Boolean)
  })

  // Merge objects with the same ingredientName, add the dosageMax pourcentage and quantity.value
  const mergedData = data.reduce<NutritionalValues[]>(
    (accumulator, currentIngredient) => {
      const existing = accumulator.find(
        item => item?.ingredientName === currentIngredient?.ingredientName
      )

      if (existing) {
        // Merge the ingredients' nrv percentages for each phase. If the existing ingredient is missing a phase, add the phase with the current ingredient nrv percentage.
        existing.nrv.percentages = existing.nrv?.percentages?.map(
          (percentage, index) =>
            percentage + (currentIngredient.nrv.percentages[index] || 0)
        )
        // Merge the ingredients' nrv max dosage percentage for each phase. If the existing ingredient is missing a phase, add the phase with the current ingredient max dosage percentage.
        existing.dosageMax.percentages = existing.dosageMax?.percentages?.map(
          (percentage, index) =>
            percentage + (currentIngredient.dosageMax.percentages[index] || 0)
        )

        // For each phase of the ingredients, merge the ingredients' quantities value. If the existing ingredient is missing a phase, add the phase with the current ingredient quantity.
        currentIngredient.phaseIntakeTotalQuantities.forEach(
          (quantity, index) => {
            if (existing.phaseIntakeTotalQuantities[index]) {
              existing.phaseIntakeTotalQuantities[index].value += quantity.value
            } else {
              existing.phaseIntakeTotalQuantities[index] = quantity
            }
          }
        )

        existing.productsName.push(...currentIngredient.productsName)
      } else {
        accumulator.push({ ...currentIngredient })
      }

      return accumulator
    },
    []
  )

  return mergedData?.filter(item => item !== undefined)
}

export function getNutritionalIntakePercentage(
  quantity: number,
  productNutritionalQuantity: number,
  referenceValue: number
) {
  return Number(
    (((quantity * productNutritionalQuantity) / referenceValue) * 100).toFixed(
      0
    )
  )
}
