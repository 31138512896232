import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: sticky;
  top: 5.9375rem;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: #f8f9fc;
  border: 0.0625rem solid #e7e9f4;
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
`
