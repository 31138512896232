import { useState } from 'react'
import { Container, Left, IconWapper, Text, TabWrapper } from './style'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import Tab from './tab'
import { VrnAlertProps } from './props'
import { calculateNutritionalValues } from './utils'
import { TakeProductNrv } from './types'

const VrnAlert = ({ takes }: VrnAlertProps) => {
  // Attributes
  const [toggle, setToggle] = useState(false)
  const [productNrv, setProductNrv] = useState<TakeProductNrv[]>([])

  const overLimitItem = getOverLimitItems(productNrv)

  const isSuccess = overLimitItem?.length === 0

  // Functions
  function getOverLimitItems(productNrv: TakeProductNrv[]) {
    const allItems = calculateNutritionalValues(productNrv)

    const overLimitItems = allItems?.filter(item =>
      item.dosageMax?.percentages?.some(
        percentage => Number.isFinite(percentage) && percentage > 100
      )
    )

    return overLimitItems || []
  }

  function handleProductNrvChange(value: TakeProductNrv[]) {
    setProductNrv(value)
  }

  // Render
  return (
    <>
      <Container isSuccess={isSuccess}>
        <Left onClick={() => setToggle(!toggle)}>
          <IconWapper>
            {toggle ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </IconWapper>
          <Text>
            {toggle ? 'Cacher' : 'Afficher'} le tableau récapitulatif des
            apports:
          </Text>
        </Left>

        <TabWrapper style={{ display: toggle ? 'block' : 'none' }}>
          <Tab
            takes={takes}
            onProductNrvChange={handleProductNrvChange}
            productNrv={productNrv}
          />
        </TabWrapper>
      </Container>
    </>
  )
}

export default VrnAlert
