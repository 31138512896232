import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f9fc;
  border-radius: 0.75rem;
  margin-top: 1.25rem;
  padding: 0.9375rem 1.25rem;
  border: 0.0625rem solid #e7e9f4;
`

export const Label = styled.label`
  margin-bottom: 0.9375rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Textarea = styled.textarea`
  background: #ffffff;
  height: auto;
  min-height: 3.75rem;
  border: 0.0625rem solid #e9eaf0;
  border-radius: 0.5rem;
  outline: none;
  padding: 0.625rem;
  resize: none;
  overflow: hidden;
  font-family: system;
`
export const CustomMessageWrapper = styled.div`
  font-size: 0.875rem;
  opacity: 0.7;
`

export const FileList = styled.div``

export const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
  p {
  }
  span {
    margin-left: 0.625rem;
    font-size: 0.625rem;
    margin-top: 0.25rem;
  }
`
