import {
  Container,
  MainContent,
  Right,
  Img,
  Left,
  IconsWrapper,
  IconWrapper,
  Header,
  HeaderLeft,
  Name,
  HeaderRight,
  Units,
  Price,
  BrandImg,
  NameWrapper,
  PriceAndUnitWrapper,
  LinkWrapper,
} from './style'
import { useEffect, useState, useMemo } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
  BrandInstructionTiming,
  ProductVariantDTO,
} from '../../../../services/api/types'
import ProductPreviewCardDetailsDialog from '../../../product/product-preview-card/product-details-dialog'
import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import { ProductCardMarketplaceProps } from './props'
import ProductQuantity from './productQuantity'
import { PrescriptionValueToChange } from '../../../../utils/constants'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetProtocol } from '../../../../services/api/protocols/protocols'
import DiscountPrice from '../../../product/product-preview-card/discount-price'

import ProductPhases from './product-phases'
import IconLinkS from '../../../ui/icon/icons/IconLinkS'

const ProductCardMarketplace = ({
  item,
  removeProductFromDraftTake,
  takeIndex,
  setValueInPrescription,
  setDraftChanged,
  statuses,
  lastStatus,
  onTakesItemsChange,
  itemIndex,
  onIsDeleteInstructionModalOpenChange,
  onInstructionDeletionInfoChange,
}: ProductCardMarketplaceProps) => {
  // Attributes
  const { location } = useHistory()
  const { protocolId } = queryString.parse(location.search)
  const prescriptionId = Number(
    queryString.parse(location.search)?.prescriptionId
  )
  const { data: prescriber } = useGetMe()
  const { data: protocol } = useGetProtocol(
    prescriber?.id,
    Number(protocolId),
    {
      query: {
        enabled: !!prescriber?.id && !!protocolId,
      },
    }
  )
  const protocolIds = useMemo(() => {
    return protocol?.takes?.[0]?.items?.map(i => i?.product?.id) ?? []
  }, [protocol])

  const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)
  const quantity = item?.quantity ?? 0
  const { data: product, isFetched } = useGetProductByIdOrHandle(
    item?.productHandle
  )

  const [sortedVariants, setSortedVariants] = useState<ProductVariantDTO[]>()
  const [selectedvariant, setSelectedvariant] = useState<ProductVariantDTO>()
  const discountPercentage = prescriber?.activeDiscount?.percentage || 0
  const originalPrice = Number(selectedvariant?.price?.toFixed(2))
  const discountedPrice =
    originalPrice - (originalPrice * discountPercentage) / 100
  const isPrescriptionCreated = !!statuses?.find(s => s.status === 'CREATED')

  let isInProtocol = null
  if (protocol && product) {
    const items = protocol.takes?.[0]?.items
    if (items) {
      isInProtocol = items.find(i => i.product?.handle === product.handle)
    }
  }

  const itemsFirst = lastStatus?.[0]?.items || []
  const itemsSecond = lastStatus?.[1]?.items || []
  const mergedItems = itemsFirst.concat(itemsSecond)
  const productNotInTakes = !mergedItems.find(
    item => item?.product?.handle === product?.handle
  )

  useEffect(() => {
    if (product) {
      const variants = product?.variants
        ?.sort((a, b) => a.price - b.price)
        ?.filter(item => item?.title?.startsWith('mp'))

      setSortedVariants(variants)
      setSelectedvariant(variants?.[0])
    }
  }, [product])

  const [instructionQuantity, setInstructionQuantity] = useState(
    item?.instructionQuantity
      ? item?.instructionQuantity
      : product?.brandInstructionQuantity ?? 0
  )

  const [selectedTimings, setSelectedTimings] =
    useState<BrandInstructionTiming>(
      item?.instructionTiming
        ? item?.instructionTiming
        : product?.brandInstructionTiming
    )
  const [selectedDurations, setSelectedDurations] = useState<string>(
    item?.instructionDuration
      ? item?.instructionDuration
      : product?.brandInstructionDuration
  )

  // Functions
  const reset = () => {
    // setDraftChanged && setDraftChanged(true)
    setSelectedTimings(product?.brandInstructionTiming)
    setSelectedDurations(product?.brandInstructionDuration)
    setInstructionQuantity(product?.brandInstructionQuantity)
    setValueInPrescription(
      product,
      product?.brandInstructionTiming,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_TIMING
    )
    setValueInPrescription(
      product,
      product?.brandInstructionDuration,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_DURATION
    )
    setValueInPrescription(
      product,
      product?.brandInstructionQuantity,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_QUANTITY
    )
  }

  function handleDetailsModalOpenClick(e: MouseEvent) {
    e.preventDefault()
    setIsDetailsModalOpen(true)
  }

  function getChosenProductItems() {
    if (lastStatus) {
      return lastStatus[0].items
    }
    if (protocol?.takes) {
      return protocol.takes[0].items
    }
    return undefined
  }

  function handleDraftChangedChange(value: boolean) {
    setDraftChanged?.(value)
  }

  // Hooks
  useEffect(() => {
    if (instructionQuantity && product) {
      setValueInPrescription(
        product,
        instructionQuantity,
        takeIndex,
        PrescriptionValueToChange.INSTRUCTION_QUANTITY
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionQuantity, product])

  useEffect(() => {
    quantity &&
      setValueInPrescription(
        product,
        quantity,
        takeIndex,
        PrescriptionValueToChange.ITEM_QUANTITY
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  useEffect(() => {
    if (!isPrescriptionCreated && !protocolId) {
      reset()
      return
    }

    selectedvariant?.title &&
      setValueInPrescription(
        product,
        selectedvariant?.title,
        takeIndex,
        PrescriptionValueToChange.ITEM_VARIANT_TITLE
      )

    item?.note &&
      product &&
      setValueInPrescription(
        product,
        item?.note,
        takeIndex,
        PrescriptionValueToChange.NOTE
      )

    quantity &&
      setValueInPrescription(
        product,
        quantity,
        takeIndex,
        PrescriptionValueToChange.ITEM_QUANTITY
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, selectedvariant, isInProtocol, protocolIds])

  useEffect(() => {
    if (
      protocolIds.length > 0 &&
      product &&
      !protocolIds.includes(product.id)
    ) {
      reset()
    }
  }, [protocolIds, product])

  useEffect(() => {
    if (
      mergedItems?.length > 0 &&
      product &&
      isPrescriptionCreated &&
      productNotInTakes
    ) {
      reset()
    }
  }, [product, isPrescriptionCreated, productNotInTakes])

  // Render
  return (
    <Container>
      <MainContent>
        <Left>
          <Img
            src={product?.coverImage}
            alt=''
            onClick={handleDetailsModalOpenClick}
          />
        </Left>

        <Right>
          <Header>
            <HeaderLeft>
              <NameWrapper>
                <Name>{product?.name}</Name>
                <BrandImg alt={product?.name} src={product?.brand?.iconUrl} />
              </NameWrapper>
              <ProductQuantity
                quantity={quantity}
                setQuantity={(quantity: number) =>
                  setValueInPrescription(
                    product,
                    quantity,
                    takeIndex,
                    PrescriptionValueToChange.ITEM_QUANTITY
                  )
                }
                sortedVariants={sortedVariants}
                product={product}
                takeIndex={takeIndex}
                selectedvariant={selectedvariant}
                setValueInPrescription={setValueInPrescription}
                setSelectedvariant={setSelectedvariant}
              />
            </HeaderLeft>
            <HeaderRight>
              <IconsWrapper>
                <IconWrapper onClick={handleDetailsModalOpenClick}>
                  <AiOutlineInfoCircle size={20} color='#8F95B2' />
                </IconWrapper>
                {item.isAffiliated && (
                  <LinkWrapper
                    href={item.variantExternalUrl}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    target='_blank'
                  >
                    <IconLinkS height={20} width={20} fill='#8F95B2' />
                  </LinkWrapper>
                )}
                <IconWrapper
                  onClick={() => removeProductFromDraftTake(product, takeIndex)}
                >
                  <HiOutlineTrash size={20} color='#F46363' />
                </IconWrapper>
              </IconsWrapper>
            </HeaderRight>
          </Header>
          <PriceAndUnitWrapper>
            <Price>
              <DiscountPrice
                discountPercentage={discountPercentage}
                originalPrice={originalPrice}
                discountedPrice={discountedPrice}
                isAffiliated={item.isAffiliated}
              />
            </Price>
            <Units>
              {quantity} unité{quantity > 1 ? 's' : ''} (
              {!item.isAffiliated && discountPercentage > 0
                ? `${(discountedPrice * quantity).toFixed(2)}€`
                : `${(selectedvariant?.price * quantity).toFixed(2)}€`}
              )
            </Units>
          </PriceAndUnitWrapper>
        </Right>
      </MainContent>

      <ProductPhases
        phases={item.phases}
        prescriptionId={prescriptionId}
        item={item}
        onTakesItemsChange={onTakesItemsChange}
        onDraftChangedChange={handleDraftChangedChange}
        product={product}
        onValueInPrescriptionChange={setValueInPrescription}
        takeIndex={takeIndex}
        itemNote={item.note}
        onIsDeleteInstructionModalOpenChange={
          onIsDeleteInstructionModalOpenChange
        }
        onInstructionDeletionInfoChange={onInstructionDeletionInfoChange}
        chosenProductItems={getChosenProductItems()}
        selectedUsedInstructionId={item.instructionId}
        itemIndex={itemIndex}
        setValueInPrescription={setValueInPrescription}
      />

      {/* MODALS */}
      {isDetailModalOpen && (
        <ProductPreviewCardDetailsDialog
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          handle={product.handle}
        />
      )}
    </Container>
  )
}

export default ProductCardMarketplace
