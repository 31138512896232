import { components, SingleValueProps } from 'react-select'
import { Option } from '../../../../../../utils/constants'
import { SemiBoldDiv } from './styles'

const PhaseDurationSingleValue = ({
  children,
  data,
  ...props
}: SingleValueProps<Option>) => {
  const parts = data.label.split(' ')

  return (
    <components.SingleValue {...props}>
      <div>
        {parts.map((part, index) => {
          // Check if the part is a number, and bold it if true
          return (
            <span key={index}>
              {
                index > 0 &&
                  ' ' /* Add a space before the part except for the first one */
              }
              {!isNaN(part) ? <SemiBoldDiv>{part}</SemiBoldDiv> : part}
            </span>
          )
        })}
      </div>
    </components.SingleValue>
  )
}

export default PhaseDurationSingleValue
