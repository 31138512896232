import styled from 'styled-components/macro'

export const Container = styled.div`
  border: 0.0625rem solid #e7e9f4;
  border-radius: 0.75rem;
`

export const MainContent = styled.div`
  display: flex;
  background: #f2f4f9;
  padding: 1.25rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
`

export const Left = styled.div`
  margin-right: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Right = styled.div`
  width: 100%;
`

export const Img = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 0.3125rem;
  object-fit: cover;
`

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  font-size: 0.9375rem;
  width: 1.625rem;
  height: 1.625rem;
  background: ${props => (props.background ? props.background : '')};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DescriptionWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e9eaf0;
  border-radius: 0.5rem;
  padding: 0.75rem;
`
export const DescriptionTextInput = styled.textarea`
  color: #080038;
  font-size: 0.875rem;
  width: 100%;
  min-height: 1.25rem;
  border: none;
  outline: none;
  resize: none;
  font-family: system;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const HeaderLeft = styled.div``

export const Name = styled.h3`
  margin-bottom: 0.5rem;
`
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Price = styled.div`
  font-size: 1rem;
  margin-bottom: 0.3125rem;
`

export const Units = styled.div`
  font-size: 0.75rem;
  color: #8f95b2;
`

export const Title = styled.h5`
  margin: 0.625rem 0 0.3125rem 0;
`

export const ArrowDown = styled.span`
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  cursor: pointer;
`

export const BrandImg = styled.img`
  object-fit: contain;
  width: 3.4375rem;
  margin-left: 1.25rem;
  margin-top: -1.125rem;
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PriceAndUnitWrapper = styled.div`
  padding-top: 1rem;
`
