import styled from 'styled-components/macro'

export const TabWrapper = styled.div`
  margin-bottom: 1.25rem;
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`

export const Table = styled.table`
  border-radius: 0.9375rem;
  color: #393360;
  width: 100%;
  text-align: center;
`

export const HorizontalSeparator = styled.div`
  border: 0.0313rem solid #e9eaf0;
`

export const NutritionContainerHeader = styled.div`
  display: flex;
  align-items: center;
`
export const NutritionContainerTitle = styled.h3`
  color: #393360;
  font-size: 0.875rem;
`

export const TableHeaderRow = styled.tr`
  margin-top: 1.25rem;
  border-bottom: 0.0313rem solid black;
  border-top: 0.0313rem solid black;
  display: flex;
  padding: 0.3125rem;
  font-size: 0.875rem;
`

export const TableHeaderCell = styled.th`
  width: 9.375rem;
  display: flex;
  font-size: 0.625rem;
`

export const TableRow = styled.tr`
  padding: 0.3125rem;
  border-bottom: 0.0313rem solid #e9eaf0;
  margin-bottom: 0.3125rem;
  display: flex;
`

export const TableCell = styled.th`
  font-size: 0.6875rem;
  text-align: left;
  font-weight: 300;
  width: 9.375rem;
`

export const Select = styled.select`
  background: #f6f7fb;
  border-radius: 0.9375rem;
  padding: 0.3125rem;
  border: none;
  font-size: 0.625rem;
  margin: 0 0.5rem;
`

export const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  font-size: 0.75rem;
`

export const Na = styled.p`
  color: black !important;
`

export const IconWrapper = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  color: black;
  margin-left: 0.1875rem;
`

export const PopupContent = styled.div`
  width: 7.5rem;
  margin-top: 0.625rem;
  padding: 0.9375rem 1.25rem 0.625rem 1.25rem;
  background-color: white;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  border-radius: 0.75rem;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-size: 0.625rem;
`

export const NoProduct = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  text-align: center;
`

export const FlexRowDiv = styled.div`
  display: flex;
  align-items: center;
`
