/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PhasePeriodType =
  | 'ANYTIME'
  | 'FASTING'
  | 'BEFORE_MEAL'
  | 'DURING_MEAL'
  | 'AFTER_MEAL'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhasePeriodType = {
  ANYTIME: 'ANYTIME' as PhasePeriodType,
  FASTING: 'FASTING' as PhasePeriodType,
  BEFORE_MEAL: 'BEFORE_MEAL' as PhasePeriodType,
  DURING_MEAL: 'DURING_MEAL' as PhasePeriodType,
  AFTER_MEAL: 'AFTER_MEAL' as PhasePeriodType,
  UNKNOWN: 'UNKNOWN' as PhasePeriodType,
}
