import {
  BrandInstructionTiming,
  CreatePrescriptionDTO,
  CreatePrescriptionItemDTO,
  CreatePrescriptionTakeDTO,
  IntakePeriodType,
  PhaseDurationType,
  PhasePeriodType,
  PrescriptionFileDTO,
  PrescriptionItemDTO,
  PrescriptionItemPhaseDTO,
  PrescriptionRecommendedDuration,
  PrescriptionTakeType,
  PrescriptionType,
  ProductDTO,
  PurchaseOption,
} from '../../../services/api/types'

import * as Yup from 'yup'

export interface ProductWithTakes {
  product: ProductDTO
  takeInfo: PrescriptionItemDTO
}

export interface ProductItemWithPhases {
  itemId: string
  phases: PrescriptionItemPhaseDTO[]
}

export interface InstructionDeletionInfo {
  id: string
  productId: string
  takeIndex: number
  itemIndex: number
  nextSelectedUsedInstructionId: string
}

const PrescriptionItemPhaseIntakeLineSchema = Yup.object().shape({
  period: Yup.string()
    .oneOf(Object.values(IntakePeriodType))
    .required('La période de prise est requise'),
})

const PrescriptionItemPhaseIntakeSchema = Yup.object().shape({
  quantity: Yup.number().min(0).nullable(),
  lines: Yup.array()
    .of(PrescriptionItemPhaseIntakeLineSchema)
    .min(1, 'Veuillez sélectionner une période de prise'),
})

const PrescriptionItemPhaseSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  period: Yup.string().oneOf(Object.values(PhasePeriodType)).required(),
  duration: Yup.string()
    .oneOf(Object.values(PhaseDurationType))
    .required('La durée de la phase est requise'),
  intakes: Yup.array().of(PrescriptionItemPhaseIntakeSchema).min(1),
})

const CreatePrescriptionItemSchema = Yup.object().shape({
  productHandle: Yup.string().required('Product handle is required'),
  quantity: Yup.number().min(1).required('Quantity is required'),
  note: Yup.string().nullable(),
  instructionQuantity: Yup.number().nullable(),
  instructionTiming: Yup.string()
    .oneOf(Object.values(BrandInstructionTiming))
    .nullable(),
  instructionDuration: Yup.string().nullable(),
  variantTitle: Yup.string().nullable(),
  phases: Yup.array().of(PrescriptionItemPhaseSchema).min(1),
  instructionId: Yup.string().nullable(),
})

const CreatePrescriptionTakeSchema = Yup.object().shape({
  label: Yup.string().nullable(),
  type: Yup.string()
    .oneOf(Object.values(PrescriptionTakeType))
    .required('Type is required'),
  items: Yup.array().of(CreatePrescriptionItemSchema),
})

const PrescriptionFileDTOSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  createdAt: Yup.string().nullable(),
  fileUrl: Yup.string().required('File URL is required'),
  fileName: Yup.string().required('File name is required'),
  prescriberFileId: Yup.string().required('Prescriber file ID is required'),
  prescriptionId: Yup.number()
    .positive('Prescription ID must be positive').nullable(),
})

export const CreatePrescriptionSchema = Yup.object().shape({
  patientId: Yup.number().nullable(),
  discountCode: Yup.string().nullable(),
  takes: Yup.array().of(CreatePrescriptionTakeSchema),
  recommendedDuration: Yup.string()
    .oneOf(Object.values(PrescriptionRecommendedDuration))
    .required('Recommended duration is required'),
  customMessage: Yup.string().nullable(),
  purchaseOption: Yup.string()
    .oneOf(Object.values(PurchaseOption))
    .required('Purchase option is required'),
  parentId: Yup.number().nullable(),
  prescriptionType: Yup.string()
    .oneOf(Object.values(PrescriptionType))
    .required('Prescription type is required'),
  title: Yup.string().nullable(),
  files: Yup.array().of(PrescriptionFileDTOSchema),
  lastStatus: Yup.string().nullable(),
})

export interface DraftCreatePrescriptionDTO
  extends Omit<CreatePrescriptionDTO, 'takes'> {
  takes: DraftPrescriptionTakeDTO[]
  files: PrescriptionFileDTO[]
  lastStatus: string
}

export interface DraftPrescriptionTakeDTO
  extends Omit<CreatePrescriptionTakeDTO, 'items'> {
  items: DraftPrescriptionItemDTO[]
}

export interface DraftPrescriptionItemDTO extends CreatePrescriptionItemDTO {
  isAffiliated: boolean
  variantExternalUrl?: string | null
}
