import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 9rem;
  align-items: center;
  border: 0.0625rem solid #e9eaf0;
  border-radius: 0.25rem;
  padding: 0.5rem;
`

export const AlternateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #080038;
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const SwitchCureContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

export const Input = styled.input`
  width: 6.5rem;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  font-size: 1rem;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
