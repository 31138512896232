/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { PrescriptionItemPhaseDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getPrescriptionProductPhases = (
  prescriptionId: number,
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionItemPhaseDTO[]>(
    {
      url: `/prescriptions/${prescriptionId}/products/${productId}/phases`,
      method: 'get',
    },
    options
  )
}

export const getGetPrescriptionProductPhasesQueryKey = (
  prescriptionId: number,
  productId: string
) => [`/prescriptions/${prescriptionId}/products/${productId}/phases`]

export const useGetPrescriptionProductPhases = <
  TData = AsyncReturnType<typeof getPrescriptionProductPhases>,
  TError = unknown
>(
  prescriptionId: number,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionProductPhases>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriptionProductPhasesQueryKey(prescriptionId, productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriptionProductPhases>
  > = () =>
    getPrescriptionProductPhases(prescriptionId, productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionProductPhases>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(prescriptionId && productId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}
