/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PhaseDurationType =
  | 'ONE_WEEK'
  | 'TWO_WEEKS'
  | 'THREE_WEEKS'
  | 'ONE_MONTH'
  | 'TWO_MONTHS'
  | 'THREE_MONTHS'
  | 'FOUR_MONTHS'
  | 'FIVE_MONTHS'
  | 'SIX_MONTHS'
  | 'CONTINUOUS'
  | 'AS_NEEDED'
  | 'UNTIL_SYMPTOM_RESOLUTION'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhaseDurationType = {
  ONE_WEEK: 'ONE_WEEK' as PhaseDurationType,
  TWO_WEEKS: 'TWO_WEEKS' as PhaseDurationType,
  THREE_WEEKS: 'THREE_WEEKS' as PhaseDurationType,
  ONE_MONTH: 'ONE_MONTH' as PhaseDurationType,
  TWO_MONTHS: 'TWO_MONTHS' as PhaseDurationType,
  THREE_MONTHS: 'THREE_MONTHS' as PhaseDurationType,
  FOUR_MONTHS: 'FOUR_MONTHS' as PhaseDurationType,
  FIVE_MONTHS: 'FIVE_MONTHS' as PhaseDurationType,
  SIX_MONTHS: 'SIX_MONTHS' as PhaseDurationType,
  CONTINUOUS: 'CONTINUOUS' as PhaseDurationType,
  AS_NEEDED: 'AS_NEEDED' as PhaseDurationType,
  UNTIL_SYMPTOM_RESOLUTION: 'UNTIL_SYMPTOM_RESOLUTION' as PhaseDurationType,
  UNKNOWN: 'UNKNOWN' as PhaseDurationType,
}
