import styled from 'styled-components/macro'

export const Tooltip = styled.div`
  display: inline-block;
  position: relative;
`

export const LabelContent = styled.span`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  z-index: 9999;
  width: auto;
  min-width: ${({ size }) =>
    ({
      default: '80px',
      l: '180px',
      xl: '214px',
    }[size || 'default'])};
  background-color: #1e264e;
  padding: 4px 10px 6px 10px;
  border-radius: 4px;
  top: ${({ placement }) =>
    placement === 'top' ? 'calc(100% + 10px)' : 'unset'};
  bottom: ${({ placement }) =>
    placement === 'bottom' ? 'calc(-100% - 80px)' : 'unset'};
  left: ${({ placement }) => (placement === 'bottom' ? '50%' : 'unset')};
  transform: translateX(-50%);
  color: white;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
`
