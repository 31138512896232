import { useState, useEffect } from 'react'
import {
  getTakePackagingFormatName,
  getTakeFormatName,
} from '../../../utils/helpers/functions'
import ProductPreviewCardDetailsDialog from './product-details-dialog'
import RecoDialogSelectTake from '../products-upsells-dialog/dialog-select-take'
import PrescriptionDialogSelectTake from '../../prescription/create-prescription-form/step-2/dialog-select-take'
import { forwardRef } from 'react'
import {
  Body,
  Container,
  Div,
  Image,
  Name,
  Small,
  BrandImage,
  QuantityDescription,
  NewTag,
  ContainerParent,
  ImageContainer,
  ImageWrapper,
} from './style'
import { PageType } from '../../../utils/constants'
import Favorite from './favorite'
import AddToCart from './add-to-cart'
import Footer from './footer'
import Quantity from './quantity'
import { ProductPreviewCardProps } from './props'
import AffiliationLink from './affiliation-link'

const ProductPreviewCard = forwardRef(
  (
    {
      product,
      onClick,
      onSignUp,
      hasDetailModalOpen,
      closeDetailModal,
      mode,
      from,
      selectedHandle,
      setSelectedHandle,
      takes,
      setTakeChoices,
      getVariantName,
      takeChoices,
      setSearch,
      addProduct,
      bookmarks = false,
      enableFavorite = true,
      setIsInnerDialogOpen,
      enableAddToCart,
      doNotAllowProductToBeAdded,
      enableMoreInfo,
      isPatientCatalog,
      allowProductUrl,
      hideDiscountPercentage,
      type,
      favoriteId,
      favoriteLists,
      refetchCurrentFavoriteList,
    }: ProductPreviewCardProps,
    ref: any
  ) => {
    // Attributes

    const [numberOfCapsule, setNumberOfCapsule] = useState(1)
    const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)
    const sortedVariants = product?.variants
      ?.sort((a, b) => a.price - b.price)
      ?.filter(item => item?.title?.startsWith('mp'))
    const [selectedvariant, setSelectedvariant] = useState(
      product?.variants?.find(i => i.id === sortedVariants[0].id)
    )

    // Functions
    const closeModal = () =>
      hasDetailModalOpen ? closeDetailModal() : setIsDetailsModalOpen(false)

    function isDateOlderThanOneMonth(dateString) {
      // Parse the given date
      const givenDate = new Date(dateString)

      // Get the current date
      const currentDate = new Date()

      // Calculate the date one month ago from the current date
      const oneMonthAgo = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      )

      // Check if the given date is older than one month ago
      return givenDate < oneMonthAgo
    }

    function handleClickDisplayMoreInfo(e: React.MouseEvent<HTMLSpanElement>) {
      e.preventDefault()
      if (enableMoreInfo) {
        return setIsDetailsModalOpen(false)
      }
      setIsDetailsModalOpen(true)
    }

    // Hooks
    useEffect(() => {
      if (!setIsInnerDialogOpen) return
      setIsInnerDialogOpen(!isDetailModalOpen)
    }, [isDetailModalOpen])

    // Render
    return (
      <ContainerParent>
        <Container>
          <ImageContainer>
            {!isDateOlderThanOneMonth(product?.createdAt) && (
              <NewTag>Nouveau</NewTag>
            )}
            {product?.coverImage ? (
              <Image
                onClick={handleClickDisplayMoreInfo}
                alt={product?.name}
                src={product?.coverImage}
                $mode={mode}
              />
            ) : (
              <ImageWrapper />
            )}
            {enableFavorite && (
              <>
                <Favorite
                  product={product}
                  bookmarks={bookmarks}
                  refetchCurrentFavoriteList={refetchCurrentFavoriteList}
                />
              </>
            )}
            {product?.brand?.iconUrl && (
              <BrandImage
                src={product?.brand?.iconUrl}
                alt={product?.brand?.name}
              />
            )}
          </ImageContainer>
          <Name onClick={handleClickDisplayMoreInfo} $mode={mode}>
            {product.name}
            <span ref={ref}></span>
            <QuantityDescription>
              {`${numberOfCapsule ?? 0} ${
                getTakePackagingFormatName(
                  numberOfCapsule,
                  product?.packagingFormat
                ) ?? ''
              } 
                      de ${product?.takeTotalQuantity ?? ''}  
                  ${`${getTakeFormatName(
                    Number(product?.takeTotalQuantity),
                    product?.takeFormat
                  )}
                    `} (${product?.contentTotal ?? ''} ${
                product?.contentUnit ?? ''
              })
                  `}
            </QuantityDescription>
          </Name>
          <Body>
            <Div
              onClick={
                type === PageType.FAVORITE ? '' : handleClickDisplayMoreInfo
              }
              $mode={mode}
            >
              {!enableAddToCart && (
                <>
                  <Quantity
                    allowingAddProduct={doNotAllowProductToBeAdded}
                    from={from}
                    onClick={onClick}
                    product={product}
                    numberOfCapsule={numberOfCapsule}
                    mode={mode}
                    setNumberOfCapsule={setNumberOfCapsule}
                    hideDiscountPercentage={hideDiscountPercentage}
                    type={type}
                  />
                </>
              )}

              {product?.brand?.isAffiliated && (
                <AffiliationLink
                  externalUrl={product?.variants[0]?.externalUrl}
                />
              )}
              <Small>{product?.formatType}</Small>
              {type === PageType.FAVORITE && (
                <Favorite
                  product={product}
                  bookmarks={bookmarks}
                  type={type}
                  favoriteId={favoriteId}
                  userFavoriteLists={favoriteLists}
                  refetchCurrentFavoriteList={refetchCurrentFavoriteList}
                />
              )}
            </Div>
          </Body>

          <Footer product={product} onSignUp={onSignUp} mode={mode} />

          {/* MODALS */}
          {from === PageType.FAVORITE && isDetailModalOpen && (
            <ProductPreviewCardDetailsDialog
              isOpen={isDetailModalOpen}
              onClose={() => setIsDetailsModalOpen(false)}
              handle={product.handle}
              doNotAllowProductToBeAdded
            />
          )}
          {(isDetailModalOpen || hasDetailModalOpen) &&
            from !== PageType.FAVORITE && (
              <ProductPreviewCardDetailsDialog
                isOpen={isDetailModalOpen || hasDetailModalOpen}
                onClose={closeModal}
                handle={product.handle}
                onAddInCure={onClick}
                onClickAddProduct={onClick}
                doNotAllowProductToBeAdded={doNotAllowProductToBeAdded}
                isPatientCatalog={isPatientCatalog}
                allowProductUrl={allowProductUrl}
                bookmarks={bookmarks}
                enableFavorite={enableFavorite}
              />
            )}
          {from === PageType.RECOMMENDATION && (
            <RecoDialogSelectTake
              selectedHandle={selectedHandle}
              handle={product.handle}
              setSelectedHandle={setSelectedHandle}
              takes={takes}
              setTakeChoices={setTakeChoices}
              getVariantName={getVariantName}
              takeChoices={takeChoices}
              setSearch={setSearch}
              addProduct={addProduct}
              quantity={numberOfCapsule}
              recommendedDuration={undefined}
            />
          )}
          {from === PageType.PRESCRIPTION && (
            <PrescriptionDialogSelectTake
              selectedHandle={selectedHandle}
              setSelectedHandle={setSelectedHandle}
              takes={takes}
              addProduct={addProduct}
              product={product}
              quantity={numberOfCapsule}
            />
          )}
          {!doNotAllowProductToBeAdded && enableAddToCart && (
            <AddToCart
              sortedVariants={sortedVariants}
              selectedvariant={selectedvariant}
              setSelectedvariant={setSelectedvariant}
              dosings={{ takeFormat: product.takeFormat }}
              product={product}
              mode={mode}
              hideDiscountPercentage={hideDiscountPercentage}
              from={from}
            />
          )}
        </Container>
      </ContainerParent>
    )
  }
)

export default ProductPreviewCard
