import {
  Container,
  Right,
  Img,
  BrandImg,
  Left,
  IconsWrapper,
  IconWrapper,
  DescritpionWrapper,
  DescritpionTextInput,
  Header,
  HeaderLeft,
  QuantityWrapper,
  QuantityWrapperFlex,
  Name,
  HeaderRight,
  QuantityHandleWrapper,
  QuantityHandlerMinus,
  QuantityHandleNumber,
  QuantityHandlerPlus,
  QuantityChoiceWrapper,
  QuantityChoice,
  AdjustmentItem,
  NameWrapper,
  SeeMore,
  MobileBrandImg,
  DesktopPricing,
  MobilePricing,
  ImageWrapper,
  MobileNameWrapper,
  TopContainer,
  BottomContainer,
  PhaseWrapper,
  PhaseLine,
  IntakeWrapper,
  AffiliationLinkWrapper,
  AffiliationText,
  NotesAndAffiliationWrapper,
} from './style'

import { HiOutlineTrash } from 'react-icons/hi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
  getTakeFormatName,
  getNumberBetweenUnderscores,
  isContentUnit,
} from '../../../../utils/helpers'
import ProductPreviewCardDetailsDialog from '../../../../components/product/product-preview-card/product-details-dialog'
import Pricing from './pricing'
import {
  mapPhaseDurationToLabel,
  mapPhasePeriodToLabel,
} from '../../product-list/productCardMarketplace/product-phases/utils'
import { VerticalSeparator } from '../../product-list/productCardMarketplace/product-phases/style'
import IntakeLine from './intake-line'
import useProductItemLogic from './logic'
import { ProductProps } from './props'
import IconLinkS from '../../../ui/icon/icons/IconLinkS'

const Product = ({
  item,
  abilityToManageQuantity,
  cartInfo,
  pricing,
  isPatientCatalog,
  protocolId,
  prescriptionId,
  isNotPrescribedItem = false,
  isAffiliatedItem = false,
}: ProductProps) => {
  // Attributes
  const {
    product,
    handleProductImageClick,
    isDetailModalOpen,
    setIsDetailsModalOpen,
    handleDeleteProduct,
    handleQuantityMinusClick,
    handleQuantityAddClick,
    dosings,
    phases,
    isLongContent,
    toggleContent,
    isOpened,
    selectedvariant,
    subtotalAmount,
    totalAmount,
    discountPercentage,
  } = useProductItemLogic({
    item,
    cartInfo,
    pricing,
    protocolId,
    prescriptionId,
    isNotPrescribedItem,
  })

  // Render
  return (
    <Container>
      <TopContainer>
        <Left>
          <ImageWrapper>
            <Img
              src={product?.coverImage}
              alt=''
              onClick={handleProductImageClick}
            />
            <MobileNameWrapper>
              <MobileBrandImg src={product?.brand?.iconUrl} />
              <Name>{item?.product?.name}</Name>
            </MobileNameWrapper>
          </ImageWrapper>
          <IconsWrapper>
            {abilityToManageQuantity && (
              <IconWrapper background='#f46363' onClick={handleDeleteProduct}>
                <HiOutlineTrash />
              </IconWrapper>
            )}
            <IconWrapper
              background='#3A845E'
              onClick={e => {
                e.preventDefault()
                setIsDetailsModalOpen(true)
              }}
            >
              <AiOutlineInfoCircle />
            </IconWrapper>
          </IconsWrapper>
        </Left>

        <Right>
          <Header>
            <HeaderLeft>
              <NameWrapper>
                <Name>{item?.product?.name}</Name>
                <BrandImg src={product?.brand?.iconUrl} />
              </NameWrapper>
              <QuantityWrapper>
                <QuantityWrapperFlex>
                  <QuantityChoiceWrapper>
                    <QuantityChoice selected={true}>
                      {getNumberBetweenUnderscores(selectedvariant?.title)}{' '}
                      {isContentUnit(dosings?.takeFormat)
                        ? selectedvariant?.contentUnit
                        : getTakeFormatName(
                            getNumberBetweenUnderscores(selectedvariant?.title),
                            dosings?.takeFormat
                          )}
                    </QuantityChoice>
                  </QuantityChoiceWrapper>

                  <QuantityHandleWrapper>
                    {abilityToManageQuantity && (
                      <QuantityHandlerMinus onClick={handleQuantityMinusClick}>
                        -
                      </QuantityHandlerMinus>
                    )}
                    <QuantityHandleNumber>
                      {item?.quantity}
                    </QuantityHandleNumber>
                    {abilityToManageQuantity && (
                      <QuantityHandlerPlus onClick={handleQuantityAddClick}>
                        +
                      </QuantityHandlerPlus>
                    )}
                  </QuantityHandleWrapper>
                </QuantityWrapperFlex>

                <MobilePricing>
                  <Pricing
                    price={Number(selectedvariant?.price?.toFixed(2))}
                    quantity={item?.quantity}
                    subtotalAmount={subtotalAmount}
                    totalAmount={totalAmount}
                    discountPercentage={discountPercentage}
                  />
                </MobilePricing>
              </QuantityWrapper>
            </HeaderLeft>
            <HeaderRight>
              <DesktopPricing>
                <Pricing
                  price={Number(selectedvariant?.price?.toFixed(2))}
                  quantity={item?.quantity}
                  subtotalAmount={subtotalAmount}
                  totalAmount={totalAmount}
                  discountPercentage={discountPercentage}
                />
              </DesktopPricing>
            </HeaderRight>
          </Header>

          <NotesAndAffiliationWrapper>
            {item?.note && (
              <DescritpionWrapper>
                <DescritpionTextInput>
                  {/* {item?.note} */}

                  {isLongContent && !isOpened ? (
                    <span>{item?.note.substring(0, 100)}... </span>
                  ) : (
                    <span>{item?.note} </span>
                  )}

                  {isLongContent && (
                    <SeeMore onClick={toggleContent}>
                      {isOpened ? 'Voir moins' : 'Voir plus'}
                    </SeeMore>
                  )}
                </DescritpionTextInput>
              </DescritpionWrapper>
            )}
            {isAffiliatedItem && (
              <AffiliationLinkWrapper
                href={item?.product?.variantExternalUrl}
                target='_blank'
              >
                <IconLinkS width={15} height={15} fill='#fff' />
                <AffiliationText>
                  Acheter sur le site Therascience
                </AffiliationText>
              </AffiliationLinkWrapper>
            )}
          </NotesAndAffiliationWrapper>
        </Right>

        {/* MODALS */}
        {isDetailModalOpen && (
          <ProductPreviewCardDetailsDialog
            isOpen={isDetailModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
            handle={item?.product.handle}
            isPatientCatalog={isPatientCatalog}
          />
        )}
      </TopContainer>
      <BottomContainer>
        {phases?.map((phase, index) => (
          <PhaseWrapper key={index}>
            {phase.name}
            <PhaseLine>
              <AdjustmentItem>
                {mapPhaseDurationToLabel(phase.duration)}
              </AdjustmentItem>
              <AdjustmentItem>
                {mapPhasePeriodToLabel(phase.period)}
              </AdjustmentItem>

              <VerticalSeparator />
              <IntakeWrapper>
                {phase.intakes.map((intake, intakeIndex) => (
                  <IntakeLine
                    key={intakeIndex}
                    intake={intake}
                    product={product}
                  />
                ))}
              </IntakeWrapper>
            </PhaseLine>
          </PhaseWrapper>
        ))}
      </BottomContainer>
    </Container>
  )
}

export default Product
