import { useState, useEffect, SetStateAction } from 'react'
import queryString from 'query-string'
import { toast } from 'react-hot-toast'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Button, ButtonGroup, Dialog } from '../../../components/ui'
import {
  Container,
  DialogContent,
  Title,
  TitleWrapper,
  DialogContainer,
  Aside,
} from './style'
import CreatePatientForm from '../../../components/patient/create-patient-form'
import ProductList from '../../../components/prescription/product-list'
import Layout from '../../../components/layout/action'
import {
  useCreatePrescription,
  useGetPrescriptionById,
  useGetPrescriptionFiles,
  useGetPrescriptionStatus,
  useUpdatePrescription,
  usePushPrescriptionStatus,
  getPrescriptionFiles,
} from '../../../services/api/prescriptions/prescriptions'
import {
  getPatient,
  useGetPatient,
} from '../../../services/api/patients/patients'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import {
  getErrorMessage,
  getRecommendationLink,
  processProtocolTake,
  translatePatientSex,
} from '../../../utils/helpers'
import { useSavePrescriptionFiles } from '../../../services/api/prescriptions/prescriptions'
import NewPrescriptionHeader from '../header'
import {
  useCreatePatient,
  useGetPatients,
} from '../../../services/api/patients/patients'
import { useGetProtocol } from '../../../services/api/protocols/protocols'
import { useFormik } from 'formik'
import {
  CreatePrescriptionItemDTO,
  CreatePrescriptionTakeDTO,
  PatientDTO,
  PrescriptionDTO,
  PrescriptionFileDTO,
  PrescriptionRecommendedDuration,
  PrescriptionStatusDTO,
  ProductDTO,
  ProtocolDTO,
  PurchaseOption,
  BrandInstructionTiming,
  PushPrescriptionStatus,
  PrescriptionType,
  Role,
  PrescriptionItemPhaseDTO,
  PrescriptionAmountsDTO,
} from '../../../services/api/types'
import {
  addProductToTake,
  removeProductFromTake,
} from '../../protocoles/NewProtocole/utils'
import ProtocolSlider from '../../../components/reco/protocol-slider'
import { BackButton } from '../../../components/ui'
import PrescriptionResume from './components/prescriptionResume'
import { PrescriptionValueToChange } from '../../../utils/constants'
import { setValueInPrescription } from '../utils'
import SendPrescriptionDialog from '../../../components/prescription/send-prescription-dialog'
import PrescriptionStatusDetailed from '../../../components/prescription/prescription-status-detailed'
import InformativePopup from '../../../components/prescription/informative-popup'
import { getProductByIdOrHandle } from '../../../services/api/product/product'
import PersonalizedMessage from '../../../components/reco/personalized-message'
import SuccessfullySent from '../../../components/reco/successfully-sent'
import { Pdf } from '../../../components/prescription/prescription-pdf/booklet'
import QRCode from 'qrcode'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { useCalculPrescriptionAmounts } from '../../../services/api/prescriptions/prescriptions'
import { useGetUserBySub } from '../../../services/api/users/users'
import ProductSlider2 from '../../../components/reco/product-slider-v2'
import { format } from 'date-fns'
import useDebounce from '../../../utils/hooks/useDebounce'
import { getPrescriptionProductPhases } from '../../../services/api/prescription-phases/prescription-phases'
import IntakesTableModal from './components/intakes-table-modal'
import DeleteInstructionModal from './components/delete-instruction-modal'
import {
  CreatePrescriptionSchema,
  DraftCreatePrescriptionDTO,
  DraftPrescriptionItemDTO,
  DraftPrescriptionTakeDTO,
  InstructionDeletionInfo,
  ProductItemWithPhases,
  ProductWithTakes,
} from './types'
import cloneDeep from 'lodash/cloneDeep'

export function getLastNonSentOrOrderedStatusId(statuses) {
  if (!statuses || statuses.length === 0) {
    return null
  }

  // Find the most recent status that is not 'SENT' or 'ORDERED'
  const lastNonSentOrOrderedStatus = statuses.find(
    status => status.status !== 'SENT' && status.status !== 'ORDERED'
  )
  return lastNonSentOrOrderedStatus ? lastNonSentOrOrderedStatus.id : null
}

const NewPrescriptionMarketPlacePage = ({ location, history }) => {
  //Attributes - variables
  const prescriptionId = Number(
    queryString.parse(location.search)?.prescriptionId
  )
  const action = queryString.parse(location.search)?.action as string
  const { patientId: urlPatientId } = queryString.parse(location.search)
  const { backUrl: urlBackUrl } = queryString.parse(location.search)
  const { protocolId: urlProtocolId } = queryString.parse(location.search)
  const propertiesToCompare = ['quantity', 'productHandle', 'variantTitle']
  const bypassBackCondition =
    !!urlPatientId && !!urlProtocolId && action === 'update'
  //Attributes - States
  const [step, setStep] = useState(2)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [draftChanged, setDraftChanged] = useState(false)
  const [prescriptionSelectedPatient, setPrescriptionSelectedPatient] =
    useState(null)
  const [isInformativePopupOpened, setIsInformativePopupOpened] =
    useState(false)
  const [isFormFilesOpened, setIsFormFilesOpened] = useState(false)
  useOnboardingProfileCheck()
  const [isSliderProductDialogOpen, setIsSliderProductDialogOpen] =
    useState(false)
  const [isSliderProtocolDialogOpen, setIsSliderProtocolDialogOpen] =
    useState(false)
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const [protocolId, setProtocolId] = useState<number>(
    Number(queryString.parse(location.search)?.protocolId)
  )
  const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] =
    useState(false)
  const [isBeforeLeaveModalOpen, setIsBeforeLeaveModalOpen] = useState(false)
  const [amounts, setAmounts] = useState<PrescriptionAmountsDTO | null>(null)
  const [productsWithTakes, setProductsWithTakes] =
    useState<ProductWithTakes[]>([])
  const [isIntakesTableModalOpen, setIsIntakesTableModalOpen] = useState(false)
  const [isDeleteInstructionModalOpen, setIsDeleteInstructionModalOpen] =
    useState(false)
  const [instructionDeletionInfo, setInstructionDeletionInfo] =
    useState<InstructionDeletionInfo>()
  const [lastStatusPhases, setLastStatusPhases] =
    useState<ProductItemWithPhases[]>([])
  // Queries
  // PRESCRIPTION INFORMATIONS
  const { data: prescriber } = useGetUserBySub({
    role: localStorage.getItem('role') as Role,
  })
  const { data: prescription, refetch: refetchPrescription } =
    useGetPrescriptionById(prescriber?.id, prescriptionId, {
      query: {
        enabled: !!prescriber?.id && !!prescriptionId,
      },
    })

  const { data: files } = useGetPrescriptionFiles(prescription?.id, {
    query: { enabled: !!prescription?.id },
  })

  const { data: lastStatus, refetch: refetchLastStatus } =
    useGetPrescriptionStatus(
      prescription?.id,
      getLastNonSentOrOrderedStatusId(prescription?.statuses),
      {
        query: {
          enabled:
            !!prescription?.id &&
            getLastNonSentOrOrderedStatusId(prescription?.statuses) != null,
          onSuccess: async data => {
            if (data.takes?.[0]?.items) {
              await fetchProductWithTakes(data)
            }
          },
        },
      }
    )

  useGetPatient(prescriber?.id, Number(urlPatientId), {
    query: {
      enabled: !!prescriber?.id,
      onSuccess: setSelectedPatient,
    },
  })

  // PROTOCOL INFORMATIONS
  const { data: protocol, refetch: refetchProtocol } = useGetProtocol(
    prescriber?.id,
    protocolId,
    {
      query: {
        enabled: !!prescriber?.id && !!protocolId,
      },
    }
  )
  const { mutateAsync: savePrescriptionFiles } = useSavePrescriptionFiles()
  const { mutateAsync: addPatient, isLoading: patientLoading } =
    useCreatePatient({
      mutation: {
        onSuccess: handlePatientCreated,
        onError: (error: any) => {
          const errorMessage = error?.response?.data
          toast.error(getErrorMessage(errorMessage))
        },
      },
    })
  const { mutateAsync: addPrescription } = useCreatePrescription({
    mutation: {
      onSuccess: handlePrescriptionCreated,
    },
  })
  const { mutateAsync: createAndSendPrescription } = useCreatePrescription({})
  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: handlePrescriptionSent,
      onError: () => {
        toast.error(
          "Une erreur est survenue lors de l'envoi de la prescription."
        )
      },
    },
  })
  const { mutateAsync: updatePrescription } = useUpdatePrescription({
    mutation: { onSuccess: handlePrescriptionModified },
  })
  const { mutateAsync: calculatePrice, isLoading: isAmountsLoading } =
    useCalculPrescriptionAmounts()
  const { refetch: refetchPatients } = useGetPatients(prescriber?.id, {
    size: 1000,
  })

  // Formik
  const {
    values: draft,
    setFieldValue,
    resetForm,
  } = useFormik<DraftCreatePrescriptionDTO>({
    initialValues: {
      title: '',
      customMessage: `Bonjour, voici ma recommandation de compléments alimentaires. ${prescriber?.firstName} ${prescriber?.lastName}`,
      purchaseOption: PurchaseOption.BOTH,
      discountCode: '',
      recommendedDuration: PrescriptionRecommendedDuration.ONE_MONTH,
      takes: [],
      files: [],
      lastStatus: '',
      prescriptionType: PrescriptionType.MARKETPLACE,
      patientId: null,
    },
    //enableReinitialize: true,
    onSubmit: () => {},
  })
  const draftItems = draft?.takes?.[0]?.items
  const lastStatusItems = [
    ...(lastStatus?.takes?.[0]?.items ?? []),
    ...(lastStatus?.takes?.[1]?.items ?? []),
  ]
  const areArraysEqualByPropertiesValue = areArraysEqualByProperties(
    draftItems,
    lastStatusItems,
    propertiesToCompare
  )
  const debouncedDraft = useDebounce(draft, 500)

  const {
    affiliatedTakes,
    nonAffiliatedTakes,
    affiliatedItemCount,
    nonAffiliatedItemCount,
  }: {
    affiliatedTakes: DraftPrescriptionTakeDTO[]
    nonAffiliatedTakes: DraftPrescriptionTakeDTO[]
    affiliatedItemCount: number
    nonAffiliatedItemCount: number
  } = draft.takes.reduce(
    (acc, take) => {
      const affiliatedItems = (take.items ?? []).filter(
        item => item.isAffiliated
      )
      const nonAffiliatedItems = (take.items ?? []).filter(
        item => !item.isAffiliated
      )

      if (affiliatedItems.length > 0) {
        acc.affiliatedTakes.push({ ...take, items: affiliatedItems })
      }
      if (nonAffiliatedItems.length > 0) {
        acc.nonAffiliatedTakes.push({ ...take, items: nonAffiliatedItems })
      }

      acc.affiliatedItemCount += affiliatedItems.length
      acc.nonAffiliatedItemCount += nonAffiliatedItems.length

      return acc
    },
    {
      affiliatedTakes: [],
      nonAffiliatedTakes: [],
      affiliatedItemCount: 0,
      nonAffiliatedItemCount: 0,
    }
  )

  // Functions

  async function fetchProductWithTakes(
    prescriptionStatus: PrescriptionStatusDTO
  ) {
    const results = await Promise.all(
      prescriptionStatus.takes[0].items.map(async item => {
        const productData = await getProductByIdOrHandle(item?.product?.id)
        return {
          product: productData,
          takeInfo: item, // or extract specific take information if needed
        }
      })
    )
    setProductsWithTakes(results)
  }

  async function handleResetForm(
    prescription?: PrescriptionDTO,
    files?: PrescriptionFileDTO[],
    lastStatus?: PrescriptionStatusDTO,
    protocol?: ProtocolDTO,
    phases?: {
      itemId: string
      phases: PrescriptionItemPhaseDTO[]
    }[]
  ) {
    let newtakes: DraftPrescriptionTakeDTO[] = []
    if (queryString.parse(location.search)?.protocolId && protocol) {
      newtakes = await Promise.all(
        protocol.takes.map(take => processProtocolTake(take, protocol.id))
      )
    } else if (prescription) {
      if (
        action === 'update'
        // && prescription.prescriptionType === 'LEGACY'
      ) {
        const lastStatusTakesItems = lastStatus?.takes?.filter(
          item => item?.items
        )

        const items = lastStatusTakesItems?.flatMap(({ items }) => {
          const itemsPerTake = items?.flatMap(item => {
            return {
              instructionDuration: item?.instructionDuration,
              instructionQuantity: item?.instructionQuantity,
              instructionTiming: item?.instructionTiming,
              note: item?.note,
              quantity: item?.quantity,
              variantTitle: item?.variantTitle,
              productHandle: item?.product?.handle,
              instructionId: item?.instructionId,
              isAffiliated: item?.product?.isAffiliated,
              variantExternalUrl: item?.product?.variantExternalUrl,
              phases:
                phases?.find(phase => phase.itemId === item.product.id)
                  ?.phases ?? [],
            } as CreatePrescriptionItemDTO
          })
          return itemsPerTake
        })
        newtakes = [
          {
            type: 'STAND_ALONE',
            label: '',
            items: items,
          } as DraftPrescriptionTakeDTO,
        ]
      } else {
        newtakes = lastStatus?.takes?.map(
          take =>
            ({
              type: take.type,
              label: take.label,
              items: take.items?.map(
                item =>
                  ({
                    productHandle: item?.product?.handle,
                    quantity: item?.quantity,
                    note: item?.note,
                    instructionDuration: item?.instructionDuration,
                    instructionQuantity: item?.instructionQuantity,
                    instructionTiming: item?.instructionTiming,
                    variantTitle: item?.variantTitle,
                    instructionId: item?.instructionId,
                    isAffiliated: item?.product?.isAffiliated,
                    variantExternalUrl: item?.product?.variantExternalUrl,
                    phases:
                      phases?.find(phase => phase.itemId === item.product.id)
                        ?.phases ?? [],
                  } as DraftPrescriptionItemDTO)
              ),
            } as DraftPrescriptionTakeDTO)
        )
      }
    }
    // if it's to modify or duplicate a prescription

    if (prescription) {
      resetForm({
        values: {
          customMessage: prescription?.customMessage ?? '',
          purchaseOption: prescription?.purchaseOption ?? PurchaseOption.BOTH,
          discountCode: prescription?.discount?.code ?? '',
          recommendedDuration:
            prescription?.recommendedDuration ??
            PrescriptionRecommendedDuration.ONE_MONTH,
          files: files ?? [],
          takes: newtakes ?? [],
          lastStatus: '',
          prescriptionType: PrescriptionType.MARKETPLACE,
          title: prescription?.title,
          patientId: prescriptionSelectedPatient?.id
            ? prescriptionSelectedPatient?.id
            : action === 'duplicate'
            ? null
            : prescription?.patient?.id,
        },
      })
    } else {
      resetForm({
        values: {
          purchaseOption: PurchaseOption.BOTH,
          recommendedDuration:
            prescription?.recommendedDuration ??
            PrescriptionRecommendedDuration.ONE_MONTH,
          takes: newtakes ?? [],
          files: files ?? [],
          lastStatus: '',
          prescriptionType: PrescriptionType.MARKETPLACE,
          customMessage: protocol?.description ?? '',
          patientId: urlPatientId ? Number(urlPatientId) : null,
        },
      })
    }
  }

  function handlePrescriptionModified(prescription: PrescriptionDTO) {
    setIsCreatePatientModalOpen(false)
    // handleResetForm()
    setFieldValue('prescriptionId', prescription.id)
    // history.push('/prescriptions/confirm?prescriptionId=' + prescription.id)
  }

  function handlePrescriptionCreated(prescription: PrescriptionDTO) {
    setIsCreatePatientModalOpen(false)
    // handleResetForm()
    toast.success(`La recommandation a été créé avec succès!`)
    setFieldValue('prescriptionId', prescription?.id)

    history.push(
      '/prescriptions/new?step=2?prescriptionId=' +
        prescription?.id +
        '&action=update'
    )
  }

  function handleIsDeleteInstructionModalChange(
    value: SetStateAction<boolean>
  ) {
    setIsDeleteInstructionModalOpen(value)
  }

  async function handlePrescriptionSent(prescription: PrescriptionDTO) {
    setIsCreatePatientModalOpen(false)
    handleResetForm()
    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    const data = {
      patient_lastname: selectedPatient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      prescriber_firstname: prescriber?.lastName,
      prescriber_lastname: prescriber?.lastName,
      patient_email: selectedPatient?.email || prescription?.patient?.email,
      patient_firstname:
        selectedPatient?.firstName || prescription?.patient?.email,
      patient_phonenumber:
        selectedPatient?.phone || prescription?.patient?.email,
      custom_message: draft?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      purchase_url: getRecommendationLink(prescription?.id),
      promo_code: prescriber?.activeDiscount?.code,
      discount: prescriber?.activeDiscount?.percentage,
    }

    await axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .catch(error => {
        toast.error(error.code)
      })
    toast.success(
      `La recommandation a été créé et envoyé par email avec succès!`
    )
    setFieldValue('prescriptionId', prescription?.id)
    // history.push('/prescriptions/confirm?prescriptionId=' + prescription.id)
  }

  async function handlePatientCreated(patient: PatientDTO) {
    setSelectedPatient(patient)
    setIsCreatePatientModalOpen(false)
    await refetchPatients()
    const { id, firstName } = patient
    setFieldValue('patientId', id)
    toast.success(`${firstName} a été ajouté à la cure avec succès !`)
  }

  function onNewPatientSubmitted(newPatientData: PatientDTO) {
    addPatient({
      prescriberId: prescriber?.id,
      data: {
        email: newPatientData.email,
        firstName: newPatientData.firstName,
        lastName: newPatientData.lastName,
        phone: `+${newPatientData.phone}`,
        age: Number.isNaN(Number(newPatientData.age))
          ? undefined
          : Number(newPatientData.age),
        sexe: newPatientData.sexe ? newPatientData.sexe : undefined,
        pathology: newPatientData.pathology,
      },
    })
  }

  function areArraysEqualByProperties(
    array1: any[],
    array2: any[],
    properties: string[]
  ): boolean {
    if (array1?.length !== array2?.length) {
      return false
    }

    for (let i = 0; i < array1?.length; i++) {
      for (const prop of properties) {
        let val1 = array1[i][prop]
        let val2 = array2[i][prop]

        // Special handling for 'productHandle' property
        if (prop === 'productHandle') {
          val2 = array2[i].product?.handle
        }

        if (val1 !== val2) {
          return false
        }
      }
    }

    return true
  }

  async function onPrescriptionSubmitted(
    doesSendRecoDirectly: boolean
  ): Promise<number> {
    try {
      await CreatePrescriptionSchema.validate(draft)
    } catch (err) {
      toast.error(err.errors)
      return
    }

    let prescriptionId: number

    try {
      if (action !== 'update') {
        draft.discountCode = prescriber?.activeDiscount?.code

        if (prescription) {
          draft.parentId = prescription?.id
        }

        let newPrescription
        if (doesSendRecoDirectly) {
          newPrescription = await createAndSendPrescription({
            prescriberId: prescriber?.id,
            data: draft,
          })
        } else {
          newPrescription = await addPrescription({
            prescriberId: prescriber?.id,
            data: draft,
          })

          // Assuming addPrescription resolves with the prescription data
          history.push(
            `${history.location.pathname}${history.location.search}&prescriptionId=${newPrescription?.id}&action=update`
          )
        }

        prescriptionId = newPrescription?.id
      } else {
        // Modify existing prescription
        if (!areArraysEqualByPropertiesValue || draftChanged) {
          await updatePrescription(
            {
              prescriberId: prescriber?.id,
              prescriptionId: prescription?.id,
              data: {
                purchaseOption: draft.purchaseOption,
                recommendedDuration: draft.recommendedDuration,
                customMessage: draft.customMessage,
                discountCode: draft.discountCode
                  ? draft.discountCode
                  : undefined,
                takes: draft.takes,
                prescriptionType: PrescriptionType.MARKETPLACE,
                title: draft.title,
                patientId: draft?.patientId,
              },
            },
            {
              onSuccess: () => {
                toast.success('Votre recommandation a bien été sauvegardée')
              },
            }
          )

          refetchLastStatus()
          refetchPrescription()
        }

        prescriptionId = prescription?.id
      }

      // This will now execute regardless of the path taken above, assuming no errors are thrown.
      await savePrescriptionFiles({
        prescriptionId: prescriptionId,
        data: {
          fileIds: draft?.files?.map(file => file?.prescriberFileId),
        },
      })
    } catch (error) {
      console.error('An error occurred:', error)
      // Handle the error appropriately
    }

    return prescriptionId
  }

  // Function to directly send the prescription to the patient upon creation of the reco
  async function onPrescriptionSent() {
    const prescriptionId = await onPrescriptionSubmitted(true)
    if (prescriptionId)
      await pushStatus({
        prescriberId: prescriber?.id,
        prescriptionId: prescriptionId,
        data: {
          status: PushPrescriptionStatus.SENT,
          takes: draft.takes?.map(
            take =>
              ({
                ...take,
                items: take.items?.map(
                  item =>
                    ({
                      ...item,
                      productHandle: item?.productHandle,
                      quantity: item?.quantity,
                    } as CreatePrescriptionItemDTO)
                ),
              } as CreatePrescriptionTakeDTO)
          ),
        },
      })
  }

  function addProductToPrescription(
    product: ProductDTO,
    takeIndex: number = 0,
    quantity: number = 1
  ) {
    const takes = addProductToTake(
      [...draft.takes],
      product,
      takeIndex,
      quantity
    )
    setFieldValue('takes', takes)
    // toast.success('Le produit a bien été ajouté')
  }

  function handleIsIntakesTableModalOpenChange(value: SetStateAction<boolean>) {
    setIsIntakesTableModalOpen(value)
  }

  function handleTakesItemsChange(
    takeIndex: number,
    itemIndex: number,
    item: DraftPrescriptionItemDTO
  ) {
    const takes = [...draft.takes]
    takes[takeIndex].items[itemIndex] = item
    setFieldValue('takes', takes)
  }

  function handleInstructionDeletionInfoChange(value: InstructionDeletionInfo) {
    setInstructionDeletionInfo(value)
  }

  function handleSelectedUsedInstructionDelete(newUsedInstructionId: string) {
    const newTakes = [...draft.takes]
    const instructionProductItem =
      draft.takes[instructionDeletionInfo.takeIndex].items[
        instructionDeletionInfo.itemIndex
      ]

    const newItem = cloneDeep(instructionProductItem)
    newItem.instructionId = newUsedInstructionId

    newTakes[instructionDeletionInfo.takeIndex].items[
      instructionDeletionInfo.itemIndex
    ] = newItem

    setFieldValue('takes', newTakes)
  }

  function removeProductFromDraftTake(product: ProductDTO, takeIndex: number) {
    const takes = removeProductFromTake([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  const generateQRCode = async () => {
    try {
      const generatedDataUrl: any = await QRCode.toDataURL(
        `https://app.simplycure.com/r/${prescriptionId}`,
        {
          width: 144,
          color: {
            dark: '#000',
            light: '#FFF',
          },
          errorCorrectionLevel: 'H',
        }
      )

      return generatedDataUrl
    } catch (err) {
      console.error(err)
    }
  }

  const saveBookletPdf = async (shouldPrint = false) => {
    // Added shouldPrint argument with default false
    if (!prescription) {
      toast.error('Veuillez enregistrer la recommandation.')
      return
    }

    let qrCodeUrl = ''

    qrCodeUrl = await generateQRCode()

    const subTotal = Number(amounts?.subTotal?.toFixed(2)) ?? 0
    const total = Number(amounts?.total?.toFixed(2)) ?? 0
    const discountPercentage = amounts?.discount?.percentage
    const creationDate = format(
      new Date(prescription?.createdAt),
      'dd/MM/yyyy'
    ).replace('/', '_')

    const blob = await pdf(
      <Pdf
        products={productsWithTakes}
        prescriberFirstName={prescriber?.firstName}
        prescriberLastName={prescriber?.lastName}
        title={draft?.title}
        customMessage={draft?.customMessage}
        discountPercentage={discountPercentage}
        discountTotal={total}
        total={subTotal}
        prescriptionId={prescriptionId}
        qrCodeUrl={qrCodeUrl}
        prescription={prescription}
        lastStatusPhases={lastStatusPhases}
      />
    ).toBlob()

    let fileName = `Recommandation_Simplycure_${prescriptionId}_${creationDate}`

    if (prescription?.patient?.firstName && prescription?.patient?.lastName) {
      fileName =
        fileName = `Recommandation_Simplycure_${prescription.patient.firstName}_${prescription.patient.lastName}_${prescriptionId}_${creationDate}`
    }

    if (!shouldPrint) saveAs(blob, fileName)

    // Check if shouldPrint is true before opening the print window.
    if (shouldPrint) {
      const objectURL = URL.createObjectURL(blob)
      const printWindow = window.open(objectURL)
      if (printWindow) {
        printWindow.onload = function () {
          printWindow.print()
          printWindow.onafterprint = function () {
            printWindow.close()
          }
        }
      } else {
        toast.error(
          "Impossible d'ouvrir la fenêtre d'impression. Veuillez vérifier vos paramètres de blocage de popup."
        )
      }
    }
  }

  //Effects
  useEffect(() => {
    async function fetchAndSetAmounts() {
      try {
        const result = await calculatePrice({
          data: {
            recommendedDuration: draft.recommendedDuration,
            prescriptionType: 'MARKETPLACE',
            discountId: prescriber?.activeDiscount?.id,
            products: draft.takes?.flatMap(take =>
              take?.items?.map(i => ({
                variantTitle: i?.variantTitle,
                quantity: i?.quantity,
                productHandle: i?.productHandle,
              }))
            ),
          },
        })
        setAmounts(result) // Assuming the result object has the fetched amounts in a data property
      } catch (error) {
        // Handle error accordingly
      }
    }

    fetchAndSetAmounts()
  }, [
    draft.recommendedDuration,
    debouncedDraft.takes,
    prescriber?.activeDiscount?.id,
    calculatePrice,
  ])

  useEffect(() => {
    const { step: urlStep } = queryString.parse(location.search)
    // If the step is different from the one we have in the URL
    if (Number(step) !== Number(urlStep)) {
      history.replace({
        search: `?step=${step}`,
      })
    }

    if (urlBackUrl)
      history.push({
        search: `&backUrl=${urlBackUrl}`,
      })

    // If the step provided is unknown
    if (![1, 2, 3].includes(Number(step))) {
      setStep(1)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search, step])

  // Reset form for update prescription or to use protocol
  useEffect(() => {
    // Early return if both prescription and protocol are not available
    if (!prescription && !protocol) {
      return
    }

    // Define an async function to fetch the prescription files
    async function fetchPrescriptionFilesAndItemPhases() {
      let fileData = undefined
      try {
        fileData = await getPrescriptionFiles(prescription?.id)
      } catch (error) {
        console.error('Failed to fetch prescription files:', error)
      }
      let phases: ProductItemWithPhases[] = undefined
      try {
        phases = await Promise.all(
          lastStatus.takes[0].items.map(async item => {
            const result = await getPrescriptionProductPhases(
              prescription?.id,
              item.product.id
            )
            return {
              itemId: item.product.id,
              phases: result,
            }
          })
        )
        setLastStatusPhases(phases)
      } catch (error) {
        console.error('Failed to fetch item phases:', error)
      }
      handleResetForm(prescription, fileData, lastStatus, protocol, phases)
    }
    // Call the async function
    fetchPrescriptionFilesAndItemPhases()
  }, [prescription, files, lastStatus, protocol])

  // This useEffect is needed when we come back from choosing the protocol
  useEffect(() => {
    setProtocolId(Number(queryString.parse(location.search)?.protocolId))
    // check if protocolId has a value
    if (!isNaN(protocolId)) {
      refetchProtocol()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSliderProtocolDialogOpen])

  // set patientId from url param
  useEffect(() => {
    if (urlPatientId) setFieldValue('patientId', Number(urlPatientId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPatientId])

  useEffect(() => {
    if (prescriptionSelectedPatient?.id)
      setFieldValue('patientId', Number(prescriptionSelectedPatient?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionSelectedPatient])
  // Render
  return (
    <Layout
      header={
        <SuccessfullySent
          patientId={prescription?.patient?.id}
          statuses={prescription?.statuses}
          prescriptionId={prescription?.id}
          saveBookletPdf={saveBookletPdf}
        />
      }
      aside={
        <Aside>
          <PrescriptionResume
            draft={debouncedDraft}
            affiliatedItemCount={affiliatedItemCount}
            isFormFilesOpened={isFormFilesOpened}
            onPrescriptionSent={onPrescriptionSent}
            onPrescriptionSubmitted={onPrescriptionSubmitted}
            selectedPatient={selectedPatient}
            setIsFormFilesOpened={setIsFormFilesOpened}
            setFieldValue={setFieldValue}
            setIsShareDialogOpen={setIsShareDialogOpen}
            setDraftChanged={setDraftChanged}
            productsWithTakes={productsWithTakes}
            prescription={prescription}
            prescriptionSelectedPatient={prescriptionSelectedPatient}
            setPrescriptionSelectedPatient={setPrescriptionSelectedPatient}
            saveBookletPdf={saveBookletPdf}
            amounts={amounts}
            isAmountsLoading={isAmountsLoading}
          />
        </Aside>
      }
      top={
        <NewPrescriptionHeader
          handleResetForm={handleResetForm}
          onCancel={() => {
            if (draft.takes && draft.takes.length > 0) {
              setIsBeforeLeaveModalOpen(true)
            } else {
              history.push('/prescriptions')
            }
          }}
          currentStep={step}
          texts={['Patient', 'Cure', 'Finalisation']}
          setIsSliderProtocolDialogOpen={setIsSliderProtocolDialogOpen}
          prescriptionType={PrescriptionType.MARKETPLACE}
          onPrescriptionSubmitted={onPrescriptionSubmitted}
          isPrescriptionModified={
            !areArraysEqualByPropertiesValue ||
            draftChanged ||
            action === 'duplicate'
          }
          setDraftChanged={setDraftChanged}
          draft={debouncedDraft}
          bypassBackCondition={bypassBackCondition}
        />
      }
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Nouvelle prescription </title>
      </Helmet>
      <Container>
        <PersonalizedMessage
          customMessage={draft.customMessage}
          setCustomMessage={(value: string) =>
            setFieldValue('customMessage', value)
          }
          setDraftChanged={setDraftChanged}
          draft={debouncedDraft}
          setFieldValue={setFieldValue}
        />
        {
          <ProductList
            statuses={prescription?.statuses}
            filteredTakes={{
              affiliated: {
                takes: affiliatedTakes,
                itemCount: affiliatedItemCount,
              },
              nonAffiliated: {
                takes: nonAffiliatedTakes,
                itemCount: nonAffiliatedItemCount,
              },
            }}
            isChooseProtocolsAllowed
            setIsSliderProtocolDialogOpen={setIsSliderProtocolDialogOpen}
            isSliderProtocolDialogOpen={isSliderProtocolDialogOpen}
            removeProductFromDraftTake={removeProductFromDraftTake}
            setIsSliderProductDialogOpen={setIsSliderProductDialogOpen}
            draft={debouncedDraft}
            setValueInPrescription={(
              product: ProductDTO,
              value: string | number | BrandInstructionTiming,
              takeIndex: number,
              prescriptionValueToChange: PrescriptionValueToChange
            ) =>
              setValueInPrescription(
                draft,
                setFieldValue,
                product,
                value,
                takeIndex,
                prescriptionValueToChange
              )
            }
            setDraftChanged={setDraftChanged}
            lastStatus={lastStatus?.takes}
            onTakesItemsChange={handleTakesItemsChange}
            onIntakesTableModalOpenChange={handleIsIntakesTableModalOpenChange}
            onIsDeleteInstructionModalOpenChange={
              handleIsDeleteInstructionModalChange
            }
            onInstructionDeletionInfoChange={
              handleInstructionDeletionInfoChange
            }
          />
        }
        {prescription?.id && (
          <div>
            <PrescriptionStatusDetailed
              prescriptionId={prescription?.id}
              statuses={prescription?.statuses}
              lastStatus={undefined}
              refetchPrescription={undefined}
            />
          </div>
        )}
        <ProductSlider2
          isDialogOpen={isSliderProductDialogOpen}
          setIsDialogOpen={setIsSliderProductDialogOpen}
          addProductToTake={addProductToPrescription}
          prescriptionType={PrescriptionType.MARKETPLACE}
          draft={debouncedDraft}
          page='recommandation'
        />
        <ProtocolSlider
          isDialogOpen={isSliderProtocolDialogOpen}
          setIsDialogOpen={setIsSliderProtocolDialogOpen}
        />
      </Container>
      <Dialog
        type='aside'
        isOpen={isCreatePatientModalOpen}
        onClose={() => setIsCreatePatientModalOpen(false)}
        styleContainer={undefined}
      >
        <DialogContent>
          <TitleWrapper>
            <BackButton onClick={() => setIsCreatePatientModalOpen(false)} />
            <Title>Créez un nouveau client pour votre recommandation</Title>
          </TitleWrapper>
          <CreatePatientForm
            onSubmit={onNewPatientSubmitted}
            isLoading={patientLoading}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        type='default'
        isOpen={isBeforeLeaveModalOpen}
        onClose={() => false}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous annulez toutes vos données encodées seront
              perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir quitter la création de votre
              recommandation ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsBeforeLeaveModalOpen(false)}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue ma recommandation
              </Button>
              <Button
                onClick={() => {
                  handleResetForm()
                  if (urlBackUrl) {
                    history.push(urlBackUrl)
                  } else {
                    history.push('/prescriptions')
                  }
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>
      <IntakesTableModal
        isModalOpen={isIntakesTableModalOpen}
        items={draft?.takes[0]?.items}
        onClose={() => handleIsIntakesTableModalOpenChange(false)}
      />
      <DeleteInstructionModal
        isModalOpen={isDeleteInstructionModalOpen}
        onIsDeleteInstructionModalOpenChange={
          handleIsDeleteInstructionModalChange
        }
        instructionDeletionInfo={instructionDeletionInfo}
        onSelectedUsedInstructionDelete={handleSelectedUsedInstructionDelete}
        draft={draft}
      />
      <SendPrescriptionDialog
        setIsOpen={setIsShareDialogOpen}
        isOpen={isShareDialogOpen}
        prescriptionId={prescriptionId}
        lastStatus={lastStatus}
        status={prescription?.statuses}
      />
      <InformativePopup
        isOpen={isInformativePopupOpened}
        setIsOpen={setIsInformativePopupOpened}
      />
    </Layout>
  )
}

export default NewPrescriptionMarketPlacePage
