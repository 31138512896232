import React, { SVGProps } from 'react'

const IconClock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98492 1.5 9 1.5V1.5ZM9 15C7.81332 15 6.65328 14.6481 5.66658 13.9888C4.67989 13.3295 3.91085 12.3925 3.45673 11.2961C3.0026 10.1997 2.88378 8.99334 3.11529 7.82946C3.3468 6.66557 3.91825 5.59647 4.75736 4.75736C5.59648 3.91824 6.66558 3.3468 7.82946 3.11529C8.99335 2.88378 10.1997 3.0026 11.2961 3.45672C12.3925 3.91085 13.3295 4.67988 13.9888 5.66658C14.6481 6.65327 15 7.81331 15 9C15 10.5913 14.3679 12.1174 13.2426 13.2426C12.1174 14.3679 10.5913 15 9 15V15ZM9.75 8.9475V5.25C9.75 5.05109 9.67099 4.86032 9.53033 4.71967C9.38968 4.57902 9.19892 4.5 9 4.5C8.80109 4.5 8.61033 4.57902 8.46967 4.71967C8.32902 4.86032 8.25 5.05109 8.25 5.25V9C8.25 9 8.25 9.0525 8.25 9.0825C8.23155 9.23497 8.26035 9.38943 8.3325 9.525L9.4575 11.475C9.55696 11.6481 9.72109 11.7745 9.91378 11.8266C10.1065 11.8786 10.312 11.852 10.485 11.7525C10.6581 11.653 10.7845 11.4889 10.8366 11.2962C10.8886 11.1035 10.862 10.8981 10.7625 10.725L9.75 8.9475Z'
        fill={props.fill ?? '#666666'}
      />
    </svg>
  )
}

export default IconClock
