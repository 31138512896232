import styled from 'styled-components/macro'

export const IntakeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const IntakeDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const IntakeQuantity = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6.25rem;
  border: 0.0625rem solid #e7e9f4;
  height: 2.025rem;
  background-color: #fff;
  padding: 0.685rem 0.75rem;
  gap: 0.5rem;
  color: #393360;
  min-width: 9.0625rem;
  justify-content: space-between;
`
export const InputAndTakesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.125rem;
`

export const DeleteIntakeButton = styled.button`
  position: absolute;
  border-width: 0;
  color: #8f95b2;
  background-color: #f2f4f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: 0.625rem;
`

export const IntakeQuantityModifierButton = styled.button`
  display: flex;
  align-items: center;
  border-width: 0;
  background-color: #fff;
  cursor: pointer;
`

export const IntakeQuantityInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
  border: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 100%;
  color: #393360;
`
