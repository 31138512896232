import { CiWarning } from 'react-icons/ci'

import { FlexRowDiv, Icon } from '../../style'
import { DosagePercentageRowProps } from './props'
import { BsCheck2Circle } from 'react-icons/bs'

export const DosagePercentageRow = ({
  percentage,
  value,
}: DosagePercentageRowProps) => {
  const isOverLimit = Number(percentage) > 100

  return (
    <FlexRowDiv
      style={{
        color: isOverLimit ? '#F68282' : '#3A835F',
      }}
    >
      <Icon>{isOverLimit ? <CiWarning /> : <BsCheck2Circle />}</Icon>
      <div>{`${value} (${percentage}%)`}</div>
    </FlexRowDiv>
  )
}
