import { useState } from 'react'
import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import { PrescriptionItemDTO } from '../../../../services/api/types'
import { convertShopifyVariantIdToBase64 } from '../../../../utils/helpers/functions'
import { CartInfo, Pricing } from './types'
import { useGetProtocolProductPhases } from '../../../../services/api/protocol-product-phases/protocol-product-phases'
import { useGetPrescriptionProductPhases } from '../../../../services/api/prescription-phases/prescription-phases'
import { useGetProductDosingByProductId } from '../../../../services/api/dosings/dosings'
import { useGetProductLaboratoryInstructionPhases } from '../../../../services/api/instruction-phases/instruction-phases'
import toast from 'react-hot-toast'

interface UseProductItemLogicParams {
  item: PrescriptionItemDTO
  cartInfo?: CartInfo
  pricing?: Pricing
  protocolId?: number
  prescriptionId?: number
  isNotPrescribedItem?: boolean
}

const useProductItemLogic = ({
  item,
  cartInfo,
  pricing,
  protocolId,
  prescriptionId,
  isNotPrescribedItem,
}: UseProductItemLogicParams) => {
  const { data: product } = useGetProductByIdOrHandle(item?.product?.handle)
  const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)

  function handleProductImageClick(e: MouseEvent) {
    e.preventDefault()
    setIsDetailsModalOpen(true)
  }

  const { updateVariantToCart, removeVariantsToCart, nodeId, attributes } =
    cartInfo ?? {}

  const { subtotalAmount, totalAmount, discountPercentage } = pricing ?? {}

  let selectedvariant = product?.variants?.find(
    i => i.id === convertShopifyVariantIdToBase64(item?.product?.variantId)
  )
  if (!selectedvariant) {
    selectedvariant = product?.variants?.find(
      i => i.id === item?.product?.variantId
    )
  }

  //Queries
  const { data: dosings } = useGetProductDosingByProductId(item?.product?.id)
  const productAttributes = attributes?.map(i => ({
    key: i?.key,
    value: i?.value,
  }))

  const { data: protocolPhases } = useGetProtocolProductPhases(
    protocolId,
    product?.id,
    {
      query: {
        enabled: !!protocolId && !!product?.id,
      },
    }
  )

  const { data: prescriptionPhases } = useGetPrescriptionProductPhases(
    prescriptionId,
    product?.id,
    {
      query: {
        enabled: !!prescriptionId && !!product?.id,
      },
    }
  )

  const { data: laboratoryInstructionPhases } =
    useGetProductLaboratoryInstructionPhases(product?.id, {
      query: {
        enabled: !!product?.id && isNotPrescribedItem,
      },
    })

  const phases =
    laboratoryInstructionPhases ?? prescriptionPhases ?? protocolPhases

  const isLongContent = item?.note?.length > 100
  const [isOpened, setIsOpened] = useState(!isLongContent)

  const toggleContent = () => {
    setIsOpened(!isOpened)
  }

  // Functions
  function handleDeleteProduct() {
    toast.promise(
      removeVariantsToCart && removeVariantsToCart([nodeId]),
      {
        loading: 'en cours...',
        success: 'Le produit a bien été supprimé',
        error: 'Oops! Une erreur est survenue.',
      },
      {
        duration: 3000,
      }
    )
  }

  function handleQuantityMinusClick() {
    if (item?.quantity === 1)
      toast.promise(
        removeVariantsToCart && removeVariantsToCart([nodeId]),
        {
          loading: 'en cours...',
          success: '',
          error: 'Oops! Une erreur est survenue.',
        },
        {
          duration: 3000,
        }
      )
    else
      toast.promise(
        updateVariantToCart &&
          updateVariantToCart(nodeId, item?.quantity - 1, productAttributes),
        {
          loading: 'en cours...',
          success: '',
          error: 'Oops! Une erreur est survenue.',
        },
        {
          duration: 3000,
        }
      )
  }

  function handleQuantityAddClick() {
    toast.promise(
      updateVariantToCart &&
        updateVariantToCart(nodeId, item?.quantity + 1, productAttributes),
      {
        loading: 'en cours...',
        success: '',
        error: 'Oops! Une erreur est survenue.',
      },
      {
        duration: 3000,
      }
    )
  }

  return {
    product,
    handleProductImageClick,
    setIsDetailsModalOpen,
    isDetailModalOpen,
    handleDeleteProduct,
    handleQuantityMinusClick,
    handleQuantityAddClick,
    dosings,
    phases,
    isLongContent,
    toggleContent,
    isOpened,
    selectedvariant,
    subtotalAmount,
    totalAmount,
    discountPercentage,
  }
}

export default useProductItemLogic
