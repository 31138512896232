import React from 'react'
import {
  Container,
  Description,
  IconContainer,
  Left,
  LeftText,
  MainInfo,
  RightText,
  TextWrapper,
} from './styles'
import IconInfoBig from '../../../components/ui/icon/icons/IconInfoBig'
import { AffiliationInfoBannerProps } from './props'
import { AffiliationDocumentation } from '../../../components/prescription/product-list/style'

const AffiliationInfoBanner = ({
  affiliatedItemCount,
  totalPrice,
}: AffiliationInfoBannerProps) => {
  return (
    <Container>
      <MainInfo>
        <Left>
          <IconContainer>
            <IconInfoBig />
          </IconContainer>
          <TextWrapper>
            <LeftText>{`${affiliatedItemCount} Produit${
              affiliatedItemCount > 1 ? 's' : ''
            } non disponible${
              affiliatedItemCount > 1 ? 's' : ''
            } à la vente sur Simplycure`}</LeftText>
            <RightText>{`Prix Indicatif: ${totalPrice?.toFixed(
              2
            )}€`}</RightText>
          </TextWrapper>
        </Left>
      </MainInfo>

      <Description>
        Les articles suivant de votre recommandation ne sont pas disponibles à
        la vente sur Simplycure. Vous pourrez les acheter directement sur le
        site du laboratoire via le lien fourni. Les frais de livraison ne sont
        pas inclus dans le prix indicatif affiché.
      </Description>
    </Container>
  )
}

export default AffiliationInfoBanner
