import { Dialog } from '..'
import { ConfirmationDialogProps } from './props'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  ConfirmButton,
  ConfirmButtonWrapper,
} from './style'

const ConfirmationDialog = ({
  type = 'defaultCenter',
  isOpen,
  onClose,
  onConfirmClick,
  width = 'fit-content',
  showCloseButton = false,
  title,
  buttonText,
  fontSize,
  buttonBackgroundColor,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      type={type}
      isOpen={isOpen}
      onClose={onClose}
      width={width}
      showCloseButton={showCloseButton}
    >
      <Card>
        <CardHeader>
          <CardTitle fontSize={fontSize}> {title} </CardTitle>
        </CardHeader>
        <CardContent>
          <ConfirmButtonWrapper>
            <ConfirmButton
              backgroundColor={buttonBackgroundColor}
              onClick={onConfirmClick}
            >
              {buttonText}
            </ConfirmButton>
          </ConfirmButtonWrapper>
        </CardContent>
      </Card>
    </Dialog>
  )
}

export default ConfirmationDialog
