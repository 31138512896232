import { useRef, useState } from 'react'
import {
  ActionDropdown,
  ActionItem,
  ItemText,
  ListItem,
  MenuButton,
  RadioButton,
} from '../style'
import IconMore from '../../../../../../ui/icon/icons/IconMore'
import { InstructionItemProps } from './props'
import IconTrashVariant from '../../../../../../ui/icon/icons/IconTrashVariant'
import { usePatchInstructionDefault } from '../../../../../../../services/api/instructions/instructions'
import { toast } from 'react-toastify'
import IconTargetMarker from '../../../../../../ui/icon/icons/IconTargetMarker'
import { ItemTextWrapper, RightActionsWrapper } from './style'
import { useQueryClient } from 'react-query'
import { useClickOutside } from '../../../../../../../utils/hooks'
import { InstructionType } from '../../../../../../../services/api/types'
import { InstructionDeletionInfo } from '../../../../../../../pages/prescriptions/new-prescription-marketplace/types'

const InstructionItem = ({
  instruction,
  onIsDeleteInstructionModalOpenChange,
  onInstructionDeletionInfoChange,
  productId,
  onInstructionTemplateSelect,
  selectedUsedInstructionId,
  defaultInstructionCount,
  takeIndex,
  itemIndex,
  defaultInstructionId,
}: InstructionItemProps) => {
  // Attributes
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()
  const [actionMenuPosition, setActionMenuPosition] = useState({
    top: 0,
    left: 0,
  })

  useClickOutside({
    ref: dropdownRef,
    onClick: () => setIsActionMenuOpen(false),
  })

  const { mutateAsync: setInstructionToDefault } = usePatchInstructionDefault({
    mutation: {
      onSuccess: () => {
        toast(`Instruction ${instruction.name} mis par défaut`)
        if (productId) {
          queryClient.invalidateQueries(['instructionLists', productId])
        }
      },
    },
  })

  // Functions
  function handleDeleteInstructionClick(e: MouseEvent) {
    if (!instruction?.id || !productId) return
    e.stopPropagation()

    const nextSelectedUsedInstructionId =
      selectedUsedInstructionId === instruction?.id
        ? defaultInstructionId
        : selectedUsedInstructionId

    const instructionDeletionInfo: InstructionDeletionInfo = {
      id: instruction?.id,
      productId,
      takeIndex,
      itemIndex,
      nextSelectedUsedInstructionId,
    }

    onInstructionDeletionInfoChange(instructionDeletionInfo)
    onIsDeleteInstructionModalOpenChange(true)
  }

  async function handleSetDefaultInstructionClick(e: MouseEvent) {
    e.stopPropagation()
    if (!instruction?.id) return
    await setInstructionToDefault({
      instructionId: instruction.id,
    })
  }

  function isDefaultIconShown() {
    if (
      instruction.isDefault &&
      defaultInstructionCount > 1 &&
      instruction.type === InstructionType.CUSTOM
    )
      return true
    if (
      instruction.isDefault &&
      defaultInstructionCount === 1 &&
      instruction.type === InstructionType.LABORATORY
    )
      return true
    return false
  }

  function handleMenuButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    setIsActionMenuOpen(true)
    const rect = e.currentTarget.getBoundingClientRect()
    setActionMenuPosition({
      top: rect.top - 10,
      left: rect.right + 20,
    })
  }

  return (
    <div style={{ position: 'relative', cursor: 'pointer' }}>
      <ListItem
        isSelected={selectedUsedInstructionId === instruction.id}
        onClick={() => onInstructionTemplateSelect(instruction.id)}
      >
        <ItemTextWrapper>
          {isDefaultIconShown() && <IconTargetMarker />}
          <ItemText>{instruction.name}</ItemText>
        </ItemTextWrapper>
        <RightActionsWrapper>
          <RadioButton
            type='radio'
            name='dosage'
            value={instruction.id}
            onChange={() => onInstructionTemplateSelect(instruction.id)}
            checked={selectedUsedInstructionId === instruction.id}
          />
          <MenuButton onClick={handleMenuButtonClick}>
            <IconMore height={16} width={16} />
          </MenuButton>
        </RightActionsWrapper>
        <ActionDropdown
          ref={dropdownRef}
          $isOpen={isActionMenuOpen}
          top={actionMenuPosition.top}
          left={actionMenuPosition.left}
        >
          <ActionItem onClick={handleSetDefaultInstructionClick}>
            <IconTargetMarker width={16} height={16} color='#6B6688' />
            Utiliser par défaut
          </ActionItem>
          {instruction.type !== InstructionType.LABORATORY && (
            <ActionItem $danger onClick={handleDeleteInstructionClick}>
              <IconTrashVariant width={16} height={16} color='#6B6688' />
              Supprimer
            </ActionItem>
          )}
        </ActionDropdown>
      </ListItem>
    </div>
  )
}

export default InstructionItem
