import { useState } from 'react'

import { Tooltip, LabelContent } from './style'
import { TooltipElProps } from './props'

const TooltipEl = ({
  children,
  label,
  size = 'default',
  placement = 'top',
  ...props
}: TooltipElProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Tooltip
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <LabelContent
        {...props}
        size={size}
        isVisible={isOpen}
        placement={placement}
      >
        {label}
      </LabelContent>
      {children}
    </Tooltip>
  )
}

export default TooltipEl
