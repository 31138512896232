/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useMutation, UseMutationOptions, MutationFunction } from 'react-query'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const deleteInstruction = (
  instructionId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/instructions/${instructionId}`, method: 'delete' },
    options
  )
}

export const useDeleteInstruction = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteInstruction>,
    TError,
    { instructionId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteInstruction>,
    { instructionId: string }
  > = props => {
    const { instructionId } = props || {}

    return deleteInstruction(instructionId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deleteInstruction>,
    TError,
    { instructionId: string },
    TContext
  >(mutationFn, mutationOptions)
}
