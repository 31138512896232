import styled from 'styled-components/macro'

import { getStatusColor } from '../../../utils/helpers'

export const NavLink = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ $variant }) => getStatusColor($variant)};
  text-decoration: none;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};
  cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
  transition: background 100ms ease-in-out;
  ${({ $isActive }) =>
    !$isActive &&
    `
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    `};
`
