import { Text } from '@react-pdf/renderer'
import { styles } from './style'
import { IntakeAnyPeriodType } from '../../../../../services/api/types'
import { mapIntakeAnyPeriodToLabel } from '../../../product-list/productCardMarketplace/product-phases/utils'
import { IntakeCellProps } from './props'

const IntakeCell = ({ intakes, period }: IntakeCellProps) => {
  const intakeWithFrequencyPeriod = intakes.find(intake =>
    intake.lines.some(line =>
      Object.values(IntakeAnyPeriodType).includes(
        line.period as IntakeAnyPeriodType
      )
    )
  )

  if (intakeWithFrequencyPeriod && !period) {
    return (
      <Text style={styles.regularCell}>
        {`${intakeWithFrequencyPeriod.quantity} unité${
          intakeWithFrequencyPeriod.quantity > 1 ? 's' : ''
        } ${mapIntakeAnyPeriodToLabel(
          intakeWithFrequencyPeriod.lines[0].period as IntakeAnyPeriodType
        )}`}
      </Text>
    )
  }

  const intake = intakes.find(intake =>
    intake.lines.some(line => line.period === period)
  )
  if (!intake) return <Text style={styles.hyphen}>-</Text>

  return <Text style={styles.intakeQuantity}>{intake.quantity}</Text>
}

export default IntakeCell
