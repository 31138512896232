import styled from 'styled-components/macro'

export const Suggestion = styled.div`
  border-radius: 0.375rem;
  background-color: white;
  width: 100%;
  padding: 0.5rem 1.25rem;
  font-weight: 300;
  font-size: 13px;

  display: flex;
  align-items: center;

  p {
    opacity: 0.6;
    span {
      cursor: pointer;
      text-decoration: underline;
      margin-left: 10px;
    }
  }
`

export const SuggestionIcon = styled.span`
  font-size: 20px;
  margin-right: 20px;
  opacity: 0.6;
`
