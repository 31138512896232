import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  intakeQuantity: {
    fontSize: 9,
    fontWeight: 600,
    lineHeight: 1,
  },
  hyphen: {
    color: '#CECCD7',
    fontSize: 9,
    fontWeight: 600,
    lineHeight: 1,
  },
  regularCell: {
    fontSize: 9,
    color: '#393360',
    fontWeight: 400,
    lineHeight: 1,
  },
})
