import styled from 'styled-components/macro'
export const Button = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #3a845e;
  border-radius: 6px;
  border: none;
  color: ${props => (props.textColor ? props.textColor : '')};
  a {
    text-decoration: none;
  }
`

export const ButtonText = styled.p`
  padding: 0px 6px 0px 0px;
  display: flex;
  align-items: center;
`
export const LinkText = styled.p`
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 8px 10px 8px 10px;
  font-family: Poppins;
  background-color: ${props => (props.isAblelToClick ? '' : '#F2F2F2')};
  color: ${props => (props.isAblelToClick ? '' : '#000')};
  border: ${props => (props.isAblelToClick ? '' : '1px solid #D0D0D0')};
`

export const ButtonArrow = styled.div`
  padding: 7px 2px 7px 8px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-left: ${props => (props.isAblelToClick ? '1px solid #89b59e' : '')};
`

export const ArrowIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
  transition: all 0.2s;
  color: ${props => (props.textColor ? props.textColor : '')} !important; ;
`

export const PopupContent = styled.div`
  margin-top: 8px;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
`
