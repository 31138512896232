import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
export const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: stretch;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Title = styled.h3`
  color: #080038;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const IntakesTableButton = styled.button`
  border: 0.0625rem solid #65b293;
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`