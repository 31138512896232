import {
  CreatePrescriptionTakeDTO,
  CreateProtocolTakeDTO,
  PrescriptionTakeType,
  ProductDTO,
  BrandInstructionTiming,
  PhaseDurationType,
  PhasePeriodType,
  IntakePeriodType,
} from '../../../services/api/types'
import { getDefaultMarketplaceVariant } from '../../../utils/helpers'
import { DraftPrescriptionTakeDTO } from '../../prescriptions/new-prescription-marketplace/types'
import { DraftProtocolTakeDTO } from '../new-marketplace-protocole/types'

// Add in cure take
export function addTake<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[]): T[] {
  const inCureTakes = takes.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  const standAloneTake = takes.find(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  inCureTakes.push({
    items: [],
    label: `Prise #${inCureTakes?.length + 1}`,
    type: PrescriptionTakeType.IN_CURE,
  } as T)

  if (standAloneTake) {
    inCureTakes.push(standAloneTake)
  }
  return inCureTakes
}

// Remove take
export function removeTake<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], takeIndex): T[] {
  takes = takes.filter((_, i) => i !== takeIndex)

  const inCureTakes = takes.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  const standAloneTake = takes.find(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  // Update each take label

  inCureTakes.forEach((take, index) => {
    take.label = take.label?.includes('Prise #')
      ? `Prise #${index + 1}`
      : take.label
  })

  if (standAloneTake) {
    inCureTakes.push(standAloneTake)
  }
  return inCureTakes
}

export const DEFAULT_TREATMENT_NAME = 'Votre cure'
export const DEFAULT_FIRST_PHASE_NAME = 'Phase 1'

// Add product to take, create new take if needed
export function addProductToTake<
  T extends DraftPrescriptionTakeDTO | DraftProtocolTakeDTO
>(
  takes: T[],
  product: ProductDTO,
  takeIndex: number,
  quantity: number = 1
): T[] {
  const inCureTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  let standAloneTake = takes?.find(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  // Handle stand alone product
  if (product.standAlone) {
    if (!standAloneTake) {
      standAloneTake = {
        items: [],
        label: null,
        type: PrescriptionTakeType.STAND_ALONE,
      } as T
    }

    // Increase quantity if item already exists
    const item = standAloneTake.items?.find(
      item => item.productHandle === product?.handle
    )
    if (item) {
      item.quantity = item.quantity + quantity
    } else {
      // Add new item
      standAloneTake.items.push({
        productHandle: product.handle,
        isAffiliated: product.brand.isAffiliated,
        variantExternalUrl: product.variants.find(
          variant =>
            variant.title ===
            getDefaultMarketplaceVariant(product?.variants)?.title
        )?.externalUrl,
        quantity,
        variantTitle: getDefaultMarketplaceVariant(product?.variants)?.title,
        instructionId: null,
        phases: [
          {
            name: DEFAULT_TREATMENT_NAME,
            duration: PhaseDurationType.ONE_WEEK,
            period: PhasePeriodType.BEFORE_MEAL,
            intakes: [
              {
                lines: [
                  {
                    period: IntakePeriodType.MORNING,
                  },
                ],
                quantity: 1,
              },
            ],
          },
        ],
      })
    }
  } else {
    // Handle in cure product
    if (takeIndex >= inCureTakes?.length) {
      takeIndex = inCureTakes?.length
      inCureTakes.push({
        items: [],
        label: `Prise #${takeIndex + 1}`,
        type: PrescriptionTakeType.IN_CURE,
      } as T)
    }

    // Increase quantity if item already exists
    const item = inCureTakes[takeIndex].items?.find(
      item => item.productHandle === product?.handle
    )
    if (item) {
      item.quantity = item.quantity + quantity
    } else {
      // Add new item
      inCureTakes[takeIndex].items.push({
        isAffiliated: product.brand.isAffiliated,
        variantExternalUrl: product.variants.find(
          variant =>
            variant.title ===
            getDefaultMarketplaceVariant(product?.variants)?.title
        )?.externalUrl,
        productHandle: product.handle,
        quantity,
        variantTitle: getDefaultMarketplaceVariant(product?.variants)?.title,
        phases: [
          {
            name: DEFAULT_TREATMENT_NAME,
            duration: PhaseDurationType.ONE_WEEK,
            period: PhasePeriodType.BEFORE_MEAL,
            intakes: [
              {
                lines: [
                  {
                    period: IntakePeriodType.MORNING,
                  },
                ],
                quantity: 1,
              },
            ],
          },
        ],
      })
    }
  }

  // Update the form
  if (standAloneTake) {
    inCureTakes.push(standAloneTake)
  }
  return inCureTakes
}

// Remove product from take, remove take if needed
export function removeProductFromTake<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], product: ProductDTO, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const items = take.items?.filter(
    item => item.productHandle !== product?.handle
  )

  // Remove item
  if (items?.length) {
    take.items = items
    return takes
  }

  // Remove take
  return removeTake(takes, takeIndex)
}

export function removeProductFromTakeWithIndex<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], takeIndex: number, itemIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  take.items.splice(itemIndex, 1)
  return
}

// Increase item quantity
export function increaseItemQuantity<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], product: ProductDTO, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  item.quantity++

  return takes
}

// Decrease item quantity, remove item if needed
export function decreaseItemQuantity<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], product: ProductDTO, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  item.quantity--

  // Remove item
  if (item.quantity <= 0) {
    return removeProductFromTake(takes, product, takeIndex)
  }

  return takes
}

// Set take label
export function setTakeLabel<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], label: string, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  take.label = label

  return takes
}

// Set item note
export function setItemNote<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], product: ProductDTO, note: string, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  item.note = note

  return takes
}

// Set item instructionQuantity
export function setItemInstructionQuantity<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(
  takes: T[],
  product: ProductDTO,
  instructionQuantity: number,
  takeIndex: number
): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  if (instructionQuantity) item.instructionQuantity = instructionQuantity

  return takes
}

// Set item instructionTiming
export function setItemInstructionTiming<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(
  takes: T[],
  product: ProductDTO,
  instructionTiming: BrandInstructionTiming,
  takeIndex: number
): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  if (instructionTiming) item.instructionTiming = instructionTiming

  return takes
}

// Set item instructionDuration
export function setItemInstructionDuration<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(
  takes: T[],
  product: ProductDTO,
  instructionDuration: string,
  takeIndex: number
): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take.items?.find(item => item.productHandle === product?.handle)
  if (instructionDuration) item.instructionDuration = instructionDuration

  return takes
}

// Set item quantity
export function setItemQuantity<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(takes: T[], product: ProductDTO, quantity: number, takeIndex: number): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take?.items?.find(item => item.productHandle === product?.handle)
  if (item !== undefined) {
    if (quantity) item.quantity = quantity
  }

  return takes
}

// Set item variant title
export function setItemVariantTitle<
  T extends CreatePrescriptionTakeDTO | CreateProtocolTakeDTO
>(
  takes: T[],
  product: ProductDTO,
  variantTitle: string,
  takeIndex: number
): T[] {
  if (takeIndex < 0 || takeIndex >= takes?.length) return takes

  const take = takes[takeIndex]
  const item = take?.items?.find(item => item.productHandle === product?.handle)
  if (item && variantTitle) item.variantTitle = variantTitle

  return takes
}
