/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type IntakeSpecificPeriodType =
  | 'MORNING'
  | 'NOON'
  | 'AFTERNOON'
  | 'EVENING'
  | 'BEDTIME'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntakeSpecificPeriodType = {
  MORNING: 'MORNING' as IntakeSpecificPeriodType,
  NOON: 'NOON' as IntakeSpecificPeriodType,
  AFTERNOON: 'AFTERNOON' as IntakeSpecificPeriodType,
  EVENING: 'EVENING' as IntakeSpecificPeriodType,
  BEDTIME: 'BEDTIME' as IntakeSpecificPeriodType,
  UNKNOWN: 'UNKNOWN' as IntakeSpecificPeriodType,
}
