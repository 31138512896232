import { SVGProps } from 'react'

const IconStroke = ({ stroke, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      {...props}
    >
      <path
        d='M8.33317 2.5L3.74984 7.08333L1.6665 5'
        stroke={stroke ?? 'white'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconStroke
