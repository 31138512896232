import { createPortal } from 'react-dom'

import {
  Container,
  Overlay,
  DefaultDialog,
  AsideDialog,
  OuterDialog,
  FullDialog,
  OuterLargeDialog,
  OuterAsideDialog,
  OuterFullDialog,
  LargeDialog,
  MinimalDialog,
  OuterMinimalDialog,
  OuterMinimalCenterDialog,
  MinimalCenterDialog,
  DefaultCenterDialog,
  OuterCenterDialog,
  NavDialog,
  OuterNavDialog,
  CloseButtonContainer,
  CloseButton,
} from './style'
import { DialogElProps } from './props'
import { IconCross } from '../icon/icons'

export const dialogComponents = {
  minimal: MinimalDialog,
  minimalCenter: MinimalCenterDialog,
  aside: AsideDialog,
  default: DefaultDialog,
  defaultCenter: DefaultCenterDialog,
  large: LargeDialog,
  full: FullDialog,
  nav: NavDialog,
}

const outerDialogComponents = {
  minimal: OuterMinimalDialog,
  minimalCenter: OuterMinimalCenterDialog,
  aside: OuterAsideDialog,
  default: OuterDialog,
  defaultCenter: OuterCenterDialog,
  large: OuterLargeDialog,
  full: OuterFullDialog,
  nav: OuterNavDialog,
}

const getDialog = (
  type: keyof typeof dialogComponents,
  mode: 'inner' | 'outer'
) => (mode === 'inner' ? dialogComponents[type] : outerDialogComponents[type])

const DialogEl: React.FC<DialogElProps> = ({
  type = 'default',
  children,
  isOpen,
  onClose,
  styleContainer,
  width,
  padding,
  closeOnOverlayClick = true,
  noPadding = false,
  showCloseButton = false,
}) => {
  const Dialog = getDialog(type, 'inner')
  const OuterDialog = getDialog(type, 'outer')

  const onOverlayClicked = (e: React.MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      e.target.dataset.el === 'closable' &&
      onClose &&
      closeOnOverlayClick
    ) {
      onClose()
    }
  }

  return createPortal(
    <Container isVisible={isOpen} style={styleContainer}>
      <Overlay onClick={onOverlayClicked} data-el='closable'>
        <OuterDialog $type={type} data-el='closable'>
          <Dialog
            isVisible={isOpen}
            noPadding={noPadding}
            padding={padding}
            style={{ width: width }}
          >
            {children}
            {showCloseButton && (
              <CloseButtonContainer>
                <CloseButton onClick={onClose}>
                  <IconCross
                    width='1.0831rem'
                    height='1.0831rem'
                    stroke='#2E6A4B'
                  />
                </CloseButton>
              </CloseButtonContainer>
            )}
          </Dialog>
        </OuterDialog>
      </Overlay>
    </Container>,
    document.getElementById('dialog-root') as HTMLElement
  )
}

export default DialogEl
