import styled from 'styled-components/macro'
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fc;
  border-radius: 0.75rem;
  border: 0.0625rem solid #e7e9f4;
`
export const Left = styled.div`
  display: flex;
  align-items: center;
`
export const RightText = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const LeftText = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
`
