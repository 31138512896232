import { Container, IconContainer, Left, LeftText, RightText } from './styles'
import IconInfoBig from '../../../../../../components/ui/icon/icons/IconInfoBig'
import { AffiliatedItemsPricesProps } from './props'
import { Tooltip } from '../../../../../../components/ui'
const AffiliatedItemsPrices = ({
  affiliatedItemCount,
  totalPrice,
}: AffiliatedItemsPricesProps) => {
  return (
    <Container>
      <Left>
        <Tooltip
          label='Ces produits peuvent être recommandés au patient, mais devront être achetés directement sur le site du laboratoire.'
          size={'xl'}
          placement={'bottom'}
        >
          <IconContainer>
            <IconInfoBig />
          </IconContainer>
        </Tooltip>
        <LeftText>{`${affiliatedItemCount} article${
          affiliatedItemCount > 1 ? 's' : ''
        } à commander via le site du laboratoire`}</LeftText>
      </Left>
      <RightText>{`Prix Indicatif: ${totalPrice?.toFixed(2)}€`}</RightText>
    </Container>
  )
}
export default AffiliatedItemsPrices
