import { PhaseIntakeQuantitiesCellProps } from './props'
import { DailyIntakeContainer, PhaseLabel, PhaseLabelContainer } from './styles'

export const PhaseIntakeQuantitiesCell = ({
  quantities,
}: PhaseIntakeQuantitiesCellProps) => {
  return (
    <div>
      {quantities?.map((quantity, index) => (
        <DailyIntakeContainer key={index}>
          {quantities.length > 1 ? (
            <PhaseLabelContainer>
              <PhaseLabel>{`Phase ${index + 1} `}</PhaseLabel>
              <div>:</div>
            </PhaseLabelContainer>
          ) : (
            ''
          )}
          <div>{`${quantity.value.toFixed(2)} ${quantity.unit}`}</div>
        </DailyIntakeContainer>
      ))}
    </div>
  )
}
