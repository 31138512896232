const Pencil = props => (
  <svg
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_11562_7771)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.92858 0.294348L0.767151 5.51506L0.0528649 8.80078C0.0174243 8.96291 0.0233138 9.13136 0.0699919 9.29062C0.11667 9.44989 0.202639 9.59486 0.319994 9.71222C0.437349 9.82958 0.582324 9.91554 0.741589 9.96222C0.900855 10.0089 1.0693 10.0148 1.23144 9.97935L4.51715 9.26506L9.68572 4.03578C9.86984 3.84967 9.97282 3.59826 9.97215 3.33647C9.97148 3.07468 9.86722 2.82379 9.68215 2.63863L7.33429 0.290062C7.24188 0.197736 7.13214 0.124582 7.01137 0.0748044C6.89059 0.0250266 6.76117 -0.000393648 6.63055 4.60807e-06C6.49992 0.000402864 6.37066 0.0266117 6.25019 0.077125C6.12972 0.127638 6.02043 0.20146 5.92858 0.294348ZM2.00715 8.90363L1.12572 8.02292L1.57572 5.95649L4.07572 8.45649L2.00715 8.90363Z'
        fill={props.fill ?? '#8F95B2'}
      />
    </g>
    <defs>
      <clipPath id='clip0_11562_7771'>
        <rect width='10' height='10' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Pencil
