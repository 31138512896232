import { useState } from 'react'
import { IconWrapper } from '../../share-access-dialog/style'
import { Button, Tooltip } from '../../ui'
import { FaRegCopy, FaCheck } from 'react-icons/fa'
import { Container, Header, LinkWrapper } from './style'
import { RecoLinkProps } from './props'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import SaveDialog from './save-dilaog'

const RecoLink = ({
  onPrescriptionSubmitted,
  draft,
  setDraftChanged,
}: RecoLinkProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { location } = useHistory()
  const { prescriptionId } = queryString.parse(location.search)

  // Functions
  const handleCopyToClipBoard = async () => {
    if (!draft?.takes?.length) {
      toast.error('Veuillez ajouter un produit')
      return
    }

    if (!prescriptionId) {
      setIsDialogOpen(true)
      return
    }

    let localPrescriptionId = prescriptionId

    if (!localPrescriptionId) {
      localPrescriptionId = await onPrescriptionSubmitted(false)
    }

    if (localPrescriptionId) {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/r/${localPrescriptionId}`
        )
        setIsLinkCopied(true)
        setTimeout(() => setIsLinkCopied(false), 3000)
      } catch (error) {
        console.error('Error copying text: ', error)
        toast.error('Failed to copy to clipboard')
      }
    } else {
      toast.error('Error: Prescription ID not set')
    }
  }

  const prescriptionRecoLink = `${window.location.origin}/r/${
    prescriptionId ? prescriptionId : '...'
  }`

  return (
    <Container>
      <Header>Lien de la recommandation</Header>
      <LinkWrapper>
        <p>{prescriptionRecoLink}</p>
        <Tooltip
          size='l'
          label={
            prescriptionId
              ? isLinkCopied
                ? `Copié !`
                : 'Copier dans le presse papier'
              : 'Veuillez sauvegarder la recommandation avant de procéder à la copie du lien.'
          }
        >
          <Button
            appearance='minimal'
            onClick={handleCopyToClipBoard}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{
              color: prescriptionId ? '' : '#c5c5c5',
            }}
          >
            <IconWrapper>
              {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
            </IconWrapper>
            Copier
          </Button>
        </Tooltip>
      </LinkWrapper>

      <SaveDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onPrescriptionSubmitted={onPrescriptionSubmitted}
        setDraftChanged={setDraftChanged}
      />
    </Container>
  )
}

export default RecoLink
