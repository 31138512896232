import { useState } from 'react'
import {
  Content,
  FilterButton,
  NoFoundWrapper,
  ProductsContainer,
  WrapperSearchbar,
} from './style'
import { Spinner, NoFound } from '../ui'
import { PackagingType } from '../../services/api/types'
import { ProductsProps } from './props'
import ProductPreviewCard from '../product/product-preview-card/ProductPreviewCard'
import { ViewMode } from '../../utils/constants'
import ProductItem from '../product/favorites/product-item'
import RequestProduct from './request-product'
import { useLogicProducts } from '../products-v2/logic'
import ResultBar from '../products-v2/result-bar'
import { SpinnerBottomContainer } from '../products-v2/style'
import ProductSearchBar from '../products-v2/product-search'
import { FilterMobileDialog } from './mobile-filters-dialog'
import Filters from '../products-v2/filters'
import { LuFilter } from 'react-icons/lu'

const Products = ({
  SIZE = 20,
  packagingType = PackagingType.ORIGINAL,
  addProductWithTake,
  onProductClicked,
  viewModeProduct,
  takes,
  from,
  selectedHandle,
  setSelectedHandle,
  recommendedDuration,
  setTakeChoices,
  getVariantName,
  takeChoices,
  isFavorite,
  enableFavorite,
  setIsInnerDialogOpen,
  enableAddToCart,
  doNotAllowProductToBeAdded,
  enableMoreInfo,
  isPatientCatalog,
  allowProductUrl,
  hideDiscountPercentage,
  favoriteLists,
  favoriteListId,
  excludeAffiliated,
}: ProductsProps) => {
  // Attributess
  const isMobile = window.innerWidth < 768
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Logic
  const {
    isFiltersUsedBoolean,
    isSuccess,
    productsToDisplay,
    hasNextPage,
    ref,
    productDetailsModal,
    setProductDetailsModal,
    allFavoriteListsHandles,
    isFetchingNextPage,
    clearAllFilters,
    values,
    setFieldValue,
    search,
    changeSearch,
    debouncedSearch,
    totalAmount,
    allBrands,
    allProductTypes,
    isLoading,
    allProductTags,
    allProductLabels,
    allIngredients,
    allTakeFormat,
    allAllergens,
    onSortBy,
  } = useLogicProducts({ packagingType, SIZE, favoriteListId, favoriteLists, excludeAffiliated })

  return (
    <>
      <Content>
        <WrapperSearchbar>
          <ProductSearchBar
            search={search}
            debouncedSearch={debouncedSearch}
            setSearch={changeSearch}
            packagingType={packagingType}
            onProductClicked={onProductClicked}
            values={values}
            setFieldValue={setFieldValue}
            isMobile={isMobile}
          />
          <FilterButton onClick={() => setIsDialogOpen(true)}>
            <LuFilter size={13} style={{ color: '#65B293' }} />
            Filtres
          </FilterButton>
          {isDialogOpen && (
            <>
              <FilterMobileDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title='Filtres'
              >
                <Filters
                  values={values}
                  allBrands={allBrands}
                  allProductTypes={allProductTypes}
                  allProductTags={allProductTags}
                  allProductLabels={allProductLabels}
                  allIngredients={allIngredients}
                  allTakeFormat={allTakeFormat}
                  allAllergens={allAllergens}
                  setFieldValue={setFieldValue}
                />
              </FilterMobileDialog>
            </>
          )}
        </WrapperSearchbar>
        <ResultBar
          totalAmount={totalAmount}
          values={values}
          setFieldValue={setFieldValue}
          clearFilter={clearAllFilters}
          onSortBy={onSortBy}
        />
        {isLoading && !isFiltersUsedBoolean && (
          <Spinner fullScreen={undefined} />
        )}

        {((isSuccess && !isFiltersUsedBoolean) || isFiltersUsedBoolean) && (
          <ProductsContainer $mode={ViewMode.GRID}>
            {productsToDisplay ? (
              productsToDisplay.map((product, index) => {
                const total = (index / SIZE) * 100
                let hasRef = false

                if (
                  total % 50 === 0 &&
                  ((hasNextPage && !isFiltersUsedBoolean) ||
                    isFiltersUsedBoolean)
                ) {
                  hasRef = true
                }

                return isFavorite ? (
                  <ProductItem
                    key={product.handle}
                    ref={hasRef ? ref : undefined}
                    product={product}
                    mode={viewModeProduct}
                    setIsInnerDialogOpen={setIsInnerDialogOpen}
                  />
                ) : (
                  <ProductPreviewCard
                    key={product.handle}
                    mode={viewModeProduct}
                    hasDetailModalOpen={productDetailsModal === product.handle}
                    product={product}
                    closeDetailModal={() => setProductDetailsModal('')}
                    ref={hasRef ? ref : undefined}
                    onClick={quantity => onProductClicked(product, quantity)}
                    takes={takes}
                    addProduct={addProductWithTake}
                    from={from}
                    selectedHandle={selectedHandle}
                    setSelectedHandle={setSelectedHandle}
                    setTakeChoices={setTakeChoices}
                    getVariantName={getVariantName}
                    takeChoices={takeChoices}
                    bookmarks={allFavoriteListsHandles?.includes(
                      product.handle
                    )}
                    enableFavorite={enableFavorite}
                    setIsInnerDialogOpen={setIsInnerDialogOpen}
                    enableAddToCart={enableAddToCart}
                    doNotAllowProductToBeAdded={doNotAllowProductToBeAdded}
                    enableMoreInfo={enableMoreInfo}
                    isPatientCatalog={isPatientCatalog}
                    allowProductUrl={allowProductUrl}
                    hideDiscountPercentage={hideDiscountPercentage}
                  />
                )
              })
            ) : (
              <Spinner fullScreen={undefined} />
            )}
          </ProductsContainer>
        )}
        <SpinnerBottomContainer>
          {isFetchingNextPage && <Spinner fullScreen={undefined} />}
        </SpinnerBottomContainer>
        {isSuccess && !productsToDisplay?.length && (
          <NoFoundWrapper>
            <NoFound
              title='Aucun Produit'
              descritpion='Nous n’avons trouvé aucun résultat à votre recherche'
              clearAllFilters={clearAllFilters}
            />
            <RequestProduct />
          </NoFoundWrapper>
        )}
      </Content>
    </>
  )
}

export default Products
