import * as Yup from 'yup'
import {
  BrandInstructionTiming,
  IntakePeriodType,
  PhaseDurationType,
  PhasePeriodType,
  PrescriptionRecommendedDuration,
  PrescriptionTakeType,
  PrescriptionType,
  PurchaseOption,
} from '../../../services/api/types'

// Define the ProtocolIntakeLineDTO schema
const ProtocolIntakeLineDTOSchema = Yup.object().shape({
  period: Yup.string()
    .oneOf(Object.values(IntakePeriodType))
    .required('Period is required'),
})

// Define the ProtocolIntakeDTO schema
const ProtocolIntakeDTOSchema = Yup.object().shape({
  quantity: Yup.number()
    .min(0)
    .nullable(),
  lines: Yup.array()
    .of(ProtocolIntakeLineDTOSchema)
    .min(1, 'Veuillez sélectionner une période de prise'),
})

// Define the ProtocolPhaseDTO schema
const ProtocolPhaseDTOSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  period: Yup.string()
    .oneOf(Object.values(PhasePeriodType))
    .required('Period is required'),
  duration: Yup.string()
    .oneOf(Object.values(PhaseDurationType))
    .required('Duration is required'),
  intakes: Yup.array().of(ProtocolIntakeDTOSchema).min(1),
})

// Define the CreateProtocolItemDTO schema
const CreateProtocolItemDTOSchema = Yup.object().shape({
  productHandle: Yup.string().required('Product handle is required'),
  quantity: Yup.number()
    .positive("Veuillez sélectionner une quantité de produit supérieure à 0")
    .required('Quantity is required'),
  note: Yup.string().nullable(),
  instructionQuantity: Yup.number().nullable(),
  instructionTiming: Yup.string()
    .oneOf(Object.values(BrandInstructionTiming))
    .nullable(),
  instructionDuration: Yup.string().nullable(),
  variantTitle: Yup.string().nullable(),
  phases: Yup.array().of(ProtocolPhaseDTOSchema).min(1),
  instructionId: Yup.string().nullable(),
})

// Define the CreateProtocolTakeDTO schema
const CreateProtocolTakeDTOSchema = Yup.object().shape({
  label: Yup.string().nullable(),
  type: Yup.string()
    .oneOf(Object.values(PrescriptionTakeType))
    .required('Type is required'),
  items: Yup.array().of(CreateProtocolItemDTOSchema),
})

// Define the CreateProtocolDTO schema
export const CreateProtocolDTOSchema = Yup.object().shape({
  name: Yup.string().required('Veuillez ajouter un nom de protocole'),
  recommendedDuration: Yup.string()
    .oneOf(Object.values(PrescriptionRecommendedDuration))
    .required('Recommended duration is required'),
  description: Yup.string().nullable(),
  share: Yup.boolean().nullable(),
  customMessage: Yup.string().nullable(),
  takes: Yup.array()
    .of(CreateProtocolTakeDTOSchema)
    .required('Takes are required'),
  purchaseOption: Yup.string()
    .oneOf(Object.values(PurchaseOption))
    .required('Purchase option is required'),
  protocolType: Yup.string().oneOf(Object.values(PrescriptionType)).nullable(),
})
