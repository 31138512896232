import styled from 'styled-components/macro'

export const DailyIntakeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const PhaseLabel = styled.div`
  min-width: 2.5rem;
`
export const PhaseLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
