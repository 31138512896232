import {
  Container,
  NoProductsWrapper,
  NoProductsImage,
  NoProductsText,
  Or,
  OrWrapper,
  OrLine,
  MainContent,
  Description,
  DescriptionWrapper,
  AffiliationDocumentation,
} from './style'
import NoProducts from '../../../assets/images/no-products.png'
import { Button, Spinner } from '../../ui'
import { ProductListProps } from './props'
import ProtocolSlider from '../../reco/protocol-slider'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import ProductsContainer from './productCardMarketplace/products-container'
import VrnAlert from './vnr-alert'

const ProductList = ({
  setIsSliderProductDialogOpen,
  draft,
  removeProductFromDraftTake,
  setValueInPrescription,
  setIsSliderProtocolDialogOpen,
  isSliderProtocolDialogOpen,
  isChooseProtocolsAllowed,
  setDraftChanged,
  statuses,
  lastStatus,
  onTakesItemsChange,
  onIntakesTableModalOpenChange,
  onIsDeleteInstructionModalOpenChange,
  onInstructionDeletionInfoChange,
  filteredTakes,
}: ProductListProps) => {
  // Attributes
  const { location } = useHistory()
  const { prescriptionId } = queryString.parse(location.search)
  const { takes } = draft

  return (
    <>
      <Container>
        {prescriptionId && !draft.takes.length && (
          <Spinner fullScreen={false} />
        )}
        {takes?.[0]?.items?.length > 0 || takes?.[1]?.items?.length > 0 ? (
          <MainContent>
            <VrnAlert takes={takes} />
            {filteredTakes.nonAffiliated.itemCount > 0 && (
              <ProductsContainer
                takes={filteredTakes.nonAffiliated.takes}
                originalTakes={takes}
                onIntakesTableModalOpenChange={onIntakesTableModalOpenChange}
                setIsSliderProductDialogOpen={setIsSliderProductDialogOpen}
                removeProductFromDraftTake={removeProductFromDraftTake}
                setValueInPrescription={setValueInPrescription}
                setDraftChanged={setDraftChanged}
                statuses={statuses}
                lastStatus={lastStatus}
                onTakesItemsChange={onTakesItemsChange}
                onIsDeleteInstructionModalOpenChange={
                  onIsDeleteInstructionModalOpenChange
                }
                onInstructionDeletionInfoChange={
                  onInstructionDeletionInfoChange
                }
                title={
                  filteredTakes.affiliated.itemCount > 0
                    ? 'Produits disponibles'
                    : 'Vos produits'
                }
                showHeaderActions={true}
              />
            )}
            {filteredTakes.affiliated.itemCount > 0 && (
              <ProductsContainer
                takes={filteredTakes.affiliated.takes}
                originalTakes={takes}
                onIntakesTableModalOpenChange={onIntakesTableModalOpenChange}
                setIsSliderProductDialogOpen={setIsSliderProductDialogOpen}
                removeProductFromDraftTake={removeProductFromDraftTake}
                setValueInPrescription={setValueInPrescription}
                setDraftChanged={setDraftChanged}
                statuses={statuses}
                lastStatus={lastStatus}
                onTakesItemsChange={onTakesItemsChange}
                onIsDeleteInstructionModalOpenChange={
                  onIsDeleteInstructionModalOpenChange
                }
                onInstructionDeletionInfoChange={
                  onInstructionDeletionInfoChange
                }
                title={`${filteredTakes.affiliated.itemCount} Produit${
                  filteredTakes.affiliated.itemCount > 1 ? 's' : ''
                } non disponible${
                  filteredTakes.affiliated.itemCount > 1 ? 's' : ''
                } à la vente sur Simplycure`}
                description={
                  <DescriptionWrapper>
                    <Description>
                      Les articles suivant de votre recommandation sont en
                      affiliation. Votre patient pourra les acheter directement
                      sur le site du laboratoire via le lien fourni.
                    </Description>
                    <div>
                      <AffiliationDocumentation
                        href={
                          process.env.REACT_APP_AFFILIATION_DOCUMENTATION_URL
                        }
                        target='_blank'
                      >
                        Plus d'infos
                      </AffiliationDocumentation>
                    </div>
                  </DescriptionWrapper>
                }
                showHeaderActions={
                  filteredTakes.nonAffiliated.itemCount > 0 ? false : true
                }
              />
            )}
          </MainContent>
        ) : (
          !prescriptionId && (
            <NoProductsWrapper>
              <NoProductsImage src={NoProducts} />
              <NoProductsText>
                Ajoutez des produits pour compléter votre recommandation
              </NoProductsText>
              <Button
                appearance='primary'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
                onClick={() => setIsSliderProductDialogOpen(true)}
              >
                Ajouter un produit
              </Button>
              {isChooseProtocolsAllowed && (
                <>
                  <OrWrapper>
                    <OrLine />
                    <Or>ou</Or>
                    <OrLine />
                  </OrWrapper>
                  <Button
                    appearance='minimal'
                    iconLeft={undefined}
                    isDisabled={undefined}
                    isLoading={undefined}
                    isActive={undefined}
                    onClick={() => setIsSliderProtocolDialogOpen(true)}
                  >
                    Choisir un protocole
                  </Button>
                </>
              )}
            </NoProductsWrapper>
          )
        )}

        <ProtocolSlider
          isDialogOpen={isSliderProtocolDialogOpen}
          setIsDialogOpen={setIsSliderProtocolDialogOpen}
        />
      </Container>
    </>
  )
}

export default ProductList
