import { useState } from 'react'
import {
  Statuses,
  Title,
  MarkAsSent,
  Header,
  HeaderIcon,
  Wrapper,
} from './style'
import { Tooltip, Icon } from '../../ui'
import { Color } from '../../../utils/constants'
import { PrescriptionStatusesProps } from './props'
import {
  PrescriptionStatus,
  PushPrescriptionStatus,
} from '../../../services/api/types'
import { usePushPrescriptionStatus } from '../../../services/api/prescriptions/prescriptions'
import { useGetMe } from '../../../utils/hooks/getMe'

import { format } from 'date-fns'
import StatusItem from './status-item'
import { Collapse } from 'react-collapse'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { processTake } from '../../../utils/helpers'

export function renderStatusTime(date: Date): string {
  return `Le ${format(date, 'dd/MM/yyyy')} à ${format(date, 'HH')}h${format(
    date,
    'mm'
  )}`
}

const PrescriptionStatuses = ({
  prescriptionId,
  statuses,
  lastStatus,
  refetchPrescription,
}: PrescriptionStatusesProps) => {
  //  Attributes
  const [isOpened, setIsOpened] = useState(false)
  const { data: prescriber } = useGetMe()

  const { mutateAsync: pushStatus, isLoading: pushPrescriptionStatusLoading } =
    usePushPrescriptionStatus({
      mutation: {
        onSuccess: () => {
          refetchPrescription()
        },
      },
    })

  // Functions
  async function onPushSentStatus() {
    if (lastStatus?.status !== PrescriptionStatus.CREATED) return

    const newTakes = await Promise.all(
      lastStatus.takes.map(take => processTake(take, prescriptionId))
    )
    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescriptionId,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  // Render
  return (
    <Wrapper>
      <Header onClick={() => setIsOpened(!isOpened)}>
        <HeaderIcon>
          {isOpened ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </HeaderIcon>
        <Title>Historique de la recommandation</Title>
      </Header>

      <Collapse isOpened={isOpened}>
        <Statuses>
          {statuses?.map((status, index) => (
            <StatusItem index={index} status={status} />
          ))}
          <MarkAsSent
            onClick={onPushSentStatus}
            isDisabled={lastStatus?.status !== PrescriptionStatus.CREATED}
            isLoading={pushPrescriptionStatusLoading}
          >
            Marquer comme "envoyé"
            <Tooltip
              size='l'
              label='Si vous partagez le lien de la recommandation avec votre client par un canal de votre choix (Facebook messenger, Whatsapp, autre) vous pouvez manuellement indiquer à Simplycure que cette recommandation est "Envoyée"'
            >
              <Icon name='info' color={Color.DEEP_SEA} />
            </Tooltip>
            {pushPrescriptionStatusLoading && (
              <Icon name='loading' color={Color.DEEP_SEA} />
            )}
          </MarkAsSent>
        </Statuses>
      </Collapse>
    </Wrapper>
  )
}

export default PrescriptionStatuses
