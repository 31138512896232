import {
  PrescriptionItemPhaseIntakeDTO,
  ProductDTO,
} from '../../../../../services/api/types'
import {
  getProductUnit,
  renderIntakePeriodDeterminant,
} from '../../../product-list/productCardMarketplace/product-phases/intake-line/utils'
import { mapIntakePeriodToLabel } from '../../../product-list/productCardMarketplace/product-phases/utils'

export function renderIntakeInstruction(
  intake?: PrescriptionItemPhaseIntakeDTO,
  product?: ProductDTO
) {
  const intakePeriodString = intake?.lines
    ?.map(line => mapIntakePeriodToLabel(line.period).toLowerCase())
    .join(', ')

  return `${intake?.quantity} ${getProductUnit(
    intake.quantity,
    product
  )} ${renderIntakePeriodDeterminant(intakePeriodString)} ${intakePeriodString}`
}
