import { MdOutlineNotificationImportant } from 'react-icons/md'
import { getSuggestion } from '../../../../../utils/helpers'
import { SuggestionCardProps } from './props'
import { Suggestion, SuggestionIcon } from './style'
import { PhaseDurationType } from '../../../../../services/api/types'
import { getItemUnitFromSuggestedQuantity } from './utils'

const SuggestionCard = ({
  setQuantity,
  takeTotalQuantity,
  item,
}: SuggestionCardProps) => {
  const suggestedItemQuantity = getSuggestion(item, takeTotalQuantity)

  const hasAsNeedeedPhaseDuration = item?.phases?.some(
    phase => phase.duration === PhaseDurationType.AS_NEEDED
  )

  if (item?.quantity === suggestedItemQuantity || hasAsNeedeedPhaseDuration)
    return <></>

  return (
    <Suggestion>
      <SuggestionIcon>
        <MdOutlineNotificationImportant />
      </SuggestionIcon>
      <p>
        {`D'après vos instructions de dosage, votre client aura besoin d'au moins ${suggestedItemQuantity} ${getItemUnitFromSuggestedQuantity(
          suggestedItemQuantity
        )}.`}
        <span onClick={() => setQuantity(suggestedItemQuantity)}>
          {`Recommander ${suggestedItemQuantity} ${getItemUnitFromSuggestedQuantity(
            suggestedItemQuantity
          )} ?`}
        </span>
      </p>
    </Suggestion>
  )
}

export default SuggestionCard
