import styled from 'styled-components/macro'

export const ItemTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const RightActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
