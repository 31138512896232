import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import axios from 'axios'
import { Spinner } from '../../../components/ui'
import { Container, Content } from './style'
import Layout from '../../../components/layout/default'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import { useGetMe } from '../../../utils/hooks/getMe'

import queryString from 'query-string'
import {
  useGetPrescriptionById,
  useDeletePrescription,
  useArchivePrescriptionById,
  getPrescriptionStatus,
  usePushPrescriptionStatus,
} from '../../../services/api/prescriptions/prescriptions'
import { getPatient } from '../../../services/api/patients/patients'
import PrescriptionBody from '../prescription/body'
import { Link } from 'react-router-dom'
import { MenuContainer, DropdownWrapper } from './style'
import { Dropdown, NavLink } from '../../../components/ui'
import { Button } from '../../../components/ui'
import {
  getRecommendationLink,
  processTake,
  translatePatientSex,
} from '../../../utils/helpers'

import {
  PrescriptionStatus,
  PrescriptionDTO,
  PushPrescriptionStatus,
} from '../../../services/api/types'

const NewPrescriptionConfirmPage = ({ location }) => {
  // Attributes
  const history = useHistory()
  useOnboardingProfileCheck()
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  // Query param
  const [prescriptionId] = useState<number>(
    Number(queryString.parse(location.search)?.prescriptionId)
  )

  function refetPrescription() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/prescriptions/${prescription?.id}`,
    ])
  }

  const {
    data: prescription,
    isLoading,
    refetch,
  } = useGetPrescriptionById(prescriber?.id, prescriptionId)

  const { mutateAsync: deletePrescription } = useDeletePrescription({
    mutation: {
      onSuccess: () => {
        history.push('/prescriptions')
      },
      onError: () => {
        toast.error(
          `Il y a eu une erreur dans la supression de la recommandation, merci de réessayer.`
        )
      },
    },
  })

  const { mutateAsync: archivePrescription } = useArchivePrescriptionById({
    mutation: {
      onSuccess: () => {
        toast.success('La recommandation à été archivée avec succès!')
        history.replace(`/prescriptions?nav=${PrescriptionStatus.ARCHIVED}`)
      },
      onError: () => {
        toast.error("La recommandation n'a pas pu être archivée, réessayez.")
      },
    },
  })
  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        toast.success(`L'email a été envoyé avec succès !`)
        refetPrescription()
      },
      onError: () => {
        toast.error(
          'Une erreur est survenue lors de la mise à jours du status de la recommandation.'
        )
      },
    },
  })

  const lastStatus = prescription?.statuses?.[0]

  async function onPushSentStatus(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const prescriptionStatusDTO = await getPrescriptionStatus(
      prescription?.id,
      lastStatus.id
    )

    const newTakes = await Promise.all(
      prescriptionStatusDTO.takes.map(take =>
        processTake(take, prescription?.id)
      )
    )

    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescription?.id,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  async function sendMail(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    const data = {
      patient_email: prescription?.patient?.email,
      patient_firstname: prescription?.patient?.firstName,
      patient_lastname: prescription?.patient?.lastName,
      patient_phonenumber: prescription?.patient?.phone,
      patient_sex: translatePatientSex(patient.sexe),
      custom_message: prescription?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      prescriber_firstname: prescriber?.lastName,
      prescriber_lastName: prescriber?.lastName,
      purchase_url: getRecommendationLink(prescription?.id),
      promo_code: prescription?.discount?.code,
      discount: prescription?.discount?.percentage,
    }

    axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .then(() => {
        onPushSentStatus(prescription)
      })
      .catch(error => {
        toast.error(error.code)
      })
  }

  const RenderSendMailButtons = () => {
    if (
      lastStatus?.status === PrescriptionStatus.CREATED ||
      lastStatus?.status === PrescriptionStatus.MODIFIED
    ) {
      return (
        <NavLink variant={undefined} onClick={() => sendMail(prescription)}>
          Envoyer
        </NavLink>
      )
    }

    if (lastStatus?.status === PrescriptionStatus.SENT) {
      return (
        <NavLink variant={undefined} onClick={() => sendMail(prescription)}>
          Renvoyer
        </NavLink>
      )
    }
  }

  // Render
  return (
    <Layout active={null}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Confirmation nouvelle prescription </title>
      </Helmet>
      <Container>
        <Content>
          {isLoading ? (
            <Spinner fullScreen={undefined} />
          ) : (
            <>
              <DropdownWrapper>
                <Dropdown
                  content={
                    <MenuContainer>
                      {RenderSendMailButtons()}
                      {lastStatus?.status ===
                        PrescriptionStatus.TO_RELAUNCH && (
                        <NavLink
                          as={Link}
                          to={{
                            pathname:
                              prescription.type === 'MARKETPLACE'
                                ? '/prescriptions/new'
                                : '/compliment-prescriptions/new',
                            search: `?step=2&prescriptionId=${prescription?.id}&action=relaunch`,
                          }}
                          variant={undefined}
                        >
                          Renouveler
                        </NavLink>
                      )}
                      {
                        <NavLink
                          as={Link}
                          to={{
                            pathname:
                              prescription.type === 'MARKETPLACE'
                                ? '/prescriptions/new'
                                : '/compliment-prescriptions/new',
                            search: `?step=1&prescriptionId=${prescription?.id}&action=duplicate`,
                          }}
                          variant={undefined}
                        >
                          Dupliquer
                        </NavLink>
                      }
                      {(lastStatus?.status === PrescriptionStatus.CREATED ||
                        lastStatus?.status === PrescriptionStatus.MODIFIED) && (
                        <NavLink
                          as={Link}
                          to={{
                            pathname:
                              prescription.type === 'MARKETPLACE'
                                ? '/prescriptions/new'
                                : '/compliment-prescriptions/new',
                            search: `?step=2&prescriptionId=${prescription?.id}&action=update&patientId=${prescription?.patient?.id}`,
                          }}
                          variant={undefined}
                        >
                          Modifier
                        </NavLink>
                      )}
                      {lastStatus?.status ===
                        PrescriptionStatus.TO_RELAUNCH && (
                        <NavLink
                          onClick={() => {
                            archivePrescription({
                              prescriptionId: prescription?.id,
                            })
                          }}
                          variant={undefined}
                        >
                          Archiver
                        </NavLink>
                      )}
                      {(lastStatus?.status === PrescriptionStatus.CREATED ||
                        lastStatus?.status === PrescriptionStatus.MODIFIED) && (
                        <NavLink
                          onClick={() =>
                            deletePrescription({
                              prescriberId: prescriber?.id,
                              prescriptionId: prescription?.id,
                            })
                          }
                          variant={undefined}
                        >
                          Supprimer
                        </NavLink>
                      )}
                    </MenuContainer>
                  }
                >
                  <Button
                    iconLeft='menu'
                    appearance='primary'
                    isDisabled={undefined}
                    isLoading={undefined}
                    isActive={undefined}
                  >
                    Actions
                  </Button>
                </Dropdown>
              </DropdownWrapper>

              {(!lastStatus ||
                lastStatus?.status === PrescriptionStatus.CREATED) && (
                <PrescriptionBody
                  title='Votre recommandation est prête'
                  subtitle={`Vérifiez votre recommandation et envoyez la à ${prescription?.patient?.firstName} une fois que tout vous semble en ordre.`}
                  prescription={prescription}
                  refetchPrescription={refetch}
                />
              )}
              {(lastStatus?.status === PrescriptionStatus.SENT ||
                lastStatus?.status === PrescriptionStatus.MODIFIED) && (
                <PrescriptionBody
                  title='Votre recommandation a été envoyée'
                  subtitle={`Votre recommandation a déjà été envoyée mais vous pouvez toujours la modifier et la renvoyer à ${prescription?.patient?.firstName}.`}
                  prescription={prescription}
                  refetchPrescription={refetch}
                />
              )}
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default NewPrescriptionConfirmPage
