import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
`

export const TriggerButton = styled.button`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  background: #fff;
  border: 0.0625rem solid #e7e9f4;
  border-radius: 6.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
  color: #393360;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  transition: all 100ms ease-in-out;
`

export const Dropdown = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  top: 130%;
  left: 0;
  width: auto;
  min-width: 12.5rem;
  background: white;
  border: 1px solid #e7e9f4;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(40, 41, 61, 0.04),
    0rem 1.625rem 2.125rem 0rem rgba(96, 97, 112, 0.06);
  padding: 0.375rem 0;
  z-index: 10;
`

export const Section = styled.div<{ borderTop?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  z-index: 10;
  border-top: ${props => (props.borderTop ? '.0625rem solid #E9EAF0' : '0')};
`

export const SectionHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem 0.875rem;
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-height: 7.75rem;
  overflow-y: auto;
`

export const SectionTitle = styled.h2`
  color: #393360;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const PeriodOptionWrapper = styled.div`
  padding: 0 0.375rem;
`

export const PeriodOption = styled.div<{ isSelected?: boolean }>`
  padding: 0.4375rem 0.5rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #4a5568;
  background: ${props => (props.isSelected ? '#f7fafc' : 'transparent')};

  &:hover {
    border-radius: 0.25rem;
    background: #f7fafc;
  }

  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const Checkbox = styled.div<{ isChecked: boolean }>`
  width: 1rem;
  height: 1rem;
  border: 1px solid ${props => (props.isChecked ? '#38a169' : '#cbd5e0')};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isChecked ? '#38a169' : 'white')};
  color: white;
`
