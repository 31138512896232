import {
  PrescriptionItemDTO,
  PrescriptionStatusDTO,
  PrescriptionTakeDTO,
} from '../../services/api/types'
import { getProductVariantId } from '../../utils/data'
import { getProductByIdOrHandle } from '../../services/api/product/product'
import { PackagingType } from '../../services/api/types'
import { lineItemsForCart } from '../prescriptions/utils'
import { selectPackagingTypeFromPrescriptionType } from '../../utils/helpers'

// Get subscription price (only 1-month enabled)
export const mapSubscriptionLines = async (
  cart: any,
  packagingType: PackagingType = 'BOTH'
) => {
  let product
  return await Promise.all(
    cart?.lines?.edges?.map(async i => {
      product = await getProductByIdOrHandle(
        i.node.merchandise.product.handle,
        {
          packagingType,
        }
      )

      const variantId = getProductVariantId(product, 'ONE_MONTH-subscription')

      const sellingPlanId =
        i.node.merchandise.sellingPlanAllocations.edges.find(
          alloc => alloc.node.sellingPlan.name === 'Delivery every 30 Days'
        )?.node.sellingPlan.id
      const attributes = i.node.attributes.map(i => ({
        key: i.key,
        value: i.value,
      }))

      return {
        id: i.node.id,
        merchandiseId: variantId,
        sellingPlanId,
        attributes,
      }
    })
  )
}

// Get one time price
export const mapOneTimePurchaseLines = async (
  cart: any,
  duration: string,
  packagingType: PackagingType = 'BOTH'
) => {
  let product
  return await Promise.all(
    cart?.lines?.edges?.map(async i => {
      product = await getProductByIdOrHandle(
        i.node.merchandise.product.handle,
        {
          packagingType,
        }
      )
      const variantId = getProductVariantId(product, duration)

      const attributes = i.node.attributes.map(i => ({
        key: i.key,
        value: i.value,
      }))

      return {
        id: i.node.id,
        merchandiseId: variantId,
        sellingPlanId: null,
        attributes,
      }
    })
  )
}

// it allows to see the items added from the recommendation page
const insertAllTakes = (takes: PrescriptionTakeDTO[]) => {
  const take: PrescriptionTakeDTO = {
    items: [],
    label: '',
    type: undefined,
  }

  if (!takes.find((take: PrescriptionTakeDTO) => take.type === 'STAND_ALONE')) {
    take.type = 'STAND_ALONE'
    takes.push(take)
  }

  if (!takes.find((take: PrescriptionTakeDTO) => take.type === 'IN_CURE')) {
    take.type = 'IN_CURE'
    takes.push(take)
  }
}

export const mapShopifyCartToPrescriptionTakeDTOs = async (
  cart: any,
  takes: PrescriptionTakeDTO[],
  packagingType: PackagingType = 'BOTH'
): Promise<PrescriptionTakeDTO[]> => {
  if (!cart?.lines?.edges?.length || !takes?.length) return
  const edges = cart.lines.edges
  insertAllTakes(takes)

  for (let i = 0; i < takes.length; i++) {
    const take = takes[i]

    // Get items from take
    const items = edges?.filter(
      edge =>
        edge.node.attributes?.find(
          attr => attr.key === 'prise' || attr.key === '_prise'
        )?.value === `${i}`
    )

    let product
    // Override items from shopify
    take.items = await Promise.all(
      items?.map(async item => {
        // Get product
        product = await getProductByIdOrHandle(
          item.node.attributes?.find(
            attr => attr.key === 'handle' || attr.key === '_handle'
          )?.value,
          {
            packagingType,
          }
        )

        return {
          product: {
            id: product?.id,
            formatQuantity: Number(product?.formatQuantity),
            formatType: product?.formatType,
            handle: product?.handle,
            name: product?.name,
            pillPicture: product?.pillPicture,
            variantId: item.node.merchandise.id,
            variantPrice: product?.variants?.find(
              variant => variant.title === item.node.merchandise.title
            )?.price,
          },
          instructionDuration: product?.brandInstructionDuration,
          instructionTiming: product?.brandInstructionTiming,
          instructionQuantity: product?.brandInstructionQuantity,
          quantity: item.node.quantity,
          note: item.node.attributes.find(attr => attr.key === 'notes')?.value,
        } as PrescriptionItemDTO
      })
    )
  }

  return takes
}

export const getCartAttributes = presId => [
  {
    key: 'prescription_id',
    value: `${presId}`,
  },
]

export const getLineItemsForCart = async (takes, prescription) => {
  return lineItemsForCart(
    takes,
    prescription?.recommendedDuration,
    selectPackagingTypeFromPrescriptionType(prescription?.type)
  )
}

export const getMissingItems = (lastStatus: PrescriptionStatusDTO, cart) => {
  if (!lastStatus?.takes || !cart?.lines) return []

  // Get all product handles from lastStatus
  const lastStatusHandles = lastStatus.takes.flatMap(take =>
    take.items.map(item => item.product?.handle)
  )

  // Find cart items whose handles are not in lastStatus
  const extraItems = cart.lines.edges.filter(
    line => !lastStatusHandles.includes(line.node.merchandise.product?.handle)
  )

  return extraItems
}

export const createAttribute = (key: string, value: string) =>
  value?.trim()
    ? [
        {
          key,
          value: value.trim(),
        },
      ]
    : []
