/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type IntakePeriodType =
  | 'ONCE_PER_DAY'
  | 'TWICE_PER_DAY'
  | 'THREE_TIMES_PER_DAY'
  | 'FOUR_TIMES_PER_DAY'
  | 'FIVE_TIMES_PER_DAY'
  | 'MORNING'
  | 'NOON'
  | 'AFTERNOON'
  | 'EVENING'
  | 'BEDTIME'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntakePeriodType = {
  ONCE_PER_DAY: 'ONCE_PER_DAY' as IntakePeriodType,
  TWICE_PER_DAY: 'TWICE_PER_DAY' as IntakePeriodType,
  THREE_TIMES_PER_DAY: 'THREE_TIMES_PER_DAY' as IntakePeriodType,
  FOUR_TIMES_PER_DAY: 'FOUR_TIMES_PER_DAY' as IntakePeriodType,
  FIVE_TIMES_PER_DAY: 'FIVE_TIMES_PER_DAY' as IntakePeriodType,
  MORNING: 'MORNING' as IntakePeriodType,
  NOON: 'NOON' as IntakePeriodType,
  AFTERNOON: 'AFTERNOON' as IntakePeriodType,
  EVENING: 'EVENING' as IntakePeriodType,
  BEDTIME: 'BEDTIME' as IntakePeriodType,
  UNKNOWN: 'UNKNOWN' as IntakePeriodType,
}
