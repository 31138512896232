import {
  PrescriptionRecommendedDuration,
  PackagingFormatType,
  TakeFormatType,
  BrandInstructionTiming,
  PrescriptionType,
  PackagingType,
  PatientSexe,
  PrescriptionStatusDTO,
  PrescriptionItemDTO,
  DiscountDTO,
  PrescriptionTakeDTO,
  CreatePrescriptionItemDTO,
  ProtocolPhaseDTO,
  PrescriptionItemPhaseDTO,
  IntakeAnyPeriodType,
  PhaseDurationType,
} from '../../services/api/types'
import { ErrorCodes, Status, Color } from '../constants'
import { ProductHandle } from '../constants'
import toast from 'react-hot-toast'
import { boxProductVariants } from '../data'
import moment from 'moment'
import 'moment/locale/fr'
import { getPrescriptionProductPhases } from '../../services/api/prescription-phases/prescription-phases'
import { getProtocolProductPhases } from '../../services/api/protocol-product-phases/protocol-product-phases'
import { DraftPrescriptionItemDTO } from '../../pages/prescriptions/new-prescription-marketplace/types'

const productUrl = process.env.REACT_APP_AWS_BUCKET + 'assets/images/products/'

export const getProductPicture = handle => {
  return productUrl + handle + '.png'
}

export const isProd = process.env.REACT_APP_ENV === 'production'

const unitDisplay = unit =>
  ({
    month: 'mois',
    year: 'an(s)',
  }[unit])

// Deprecated, use displayRecommendedDuration instead.
export const getRecommendationDurationDisplay = (key = '-') => {
  const [duration, unit] = key ? key.split('-') : []

  if (!duration) return null

  return `${duration} ${unitDisplay(unit)}`
}

export const displayRecommendedDuration = duration => {
  return {
    [PrescriptionRecommendedDuration.ONE_MONTH]: '1 mois',
    [PrescriptionRecommendedDuration.TWO_MONTH]: '2 mois',
    [PrescriptionRecommendedDuration.THREE_MONTH]: '3 mois',
  }[duration]
}

export const getBaseAppLink = () =>
  ({
    local: 'http://localhost:3000/',
    staging: 'https://app-staging.compliment.me/',
    production: 'https://app.simplycure.com/',
  }[process.env.REACT_APP_ENV || 'production'])

export const getRecommendationLink = (prescriptionId: number) => {
  return `${window.location.origin}/r/${prescriptionId}`
}

export const removePlural = string => {
  if (string == null) {
    return ''
  }

  const strings = string.trim().split(' ')
  return strings.reduce((acc, curr) => {
    const isFirstWord = !!acc.length
    if (curr[curr.length - 1] === 's') {
      return isFirstWord
        ? `${acc} ${curr.substring(0, curr.length - 1)}`
        : `${acc}${curr.substring(0, curr.length - 1)}`
    } else {
      return isFirstWord ? `${acc} ${curr}` : `${acc}${curr}`
    }
  }, '')
}

export const getErrorMessage = errorCode => {
  switch (errorCode) {
    case ErrorCodes.PRODUCT_ALREADY_IN_TAKE:
      return 'Ce produit ne peut pas être ajouté deux fois pour une même prise, augmentez la quantité du produit à la place.'
    case ErrorCodes.PRODUCT_NOT_FOUND:
      return 'Le produit est introuvable'
    case ErrorCodes.TAKE_NOT_FOUND:
      return 'La prise est introuvable'
    case ErrorCodes.UNABLE_TO_CREATE_PATIENT_EMAIL_ALREADY_USED:
      return "L'email du patient est déja utilisé"
    default:
      return "Une erreur s'est produite, réessayez plus tard"
  }
}

export const sortAscendingByProductName = (productA, productB) => {
  if (productA.name < productB.name) {
    return -1
  }
  if (productA.name > productB.name) {
    return 1
  }
  return 0
}

/**
 * Will add an item to a take
 *
 * @param {Object} take Receiving take
 * @param {String} handle Product handle for the item to add
 * @return {undefined} Void
 */
export const pushItemInTake = (take, handle) => {
  const existingProductItemIndex = take.items.findIndex(
    item => item.handle === handle
  )

  if (existingProductItemIndex > -1)
    throw Error(ErrorCodes.PRODUCT_ALREADY_IN_TAKE)

  const productToAdd = { notes: '', handle: handle, quantity: 1 }
  take.items.push(productToAdd)
}

/**
 * Will add a new take to recommendation draft
 *
 * @param {Object} draft Recommendation (prescription) draft object
 * @param {TakeType} takeType IN_CURE || STAND_ALONE constant
 * @param {String} handle Product handle for the item to add
 * @return {undefined} Void
 */
export const pushTakeInDraft = (draft, takeType, handle) => {
  // Get last index
  const lastIndex =
    draft.takes.sort((take1, take2) => take2.index - take1.index)[0]?.index ?? 0

  const takeToAdd = {
    index: lastIndex + 1,
    label: '',
    type: takeType,
    items: [],
  }
  pushItemInTake(takeToAdd, handle)
  draft.takes.push(takeToAdd)
}

/**
 * Will get the array index of the take by the take index (this one from DB
 * who's responsible of the order)
 *
 * @param {Array} takes List of takes
 * @param {Number} index Index from a take (order index)
 *
 * @return {Number} Take index
 */
export const getTakeIndexByIndex = (takes, index) => {
  const concernedTakeIndex = takes.findIndex(take => take.index === index)

  if (concernedTakeIndex === -1) throw Error(ErrorCodes.TAKE_NOT_FOUND)

  return concernedTakeIndex
}

/**
 * Will return the take based on order index
 *
 * @param {Array} takes List of takes
 * @param {*} index Index from a take (order index)
 *
 * @return {Object} Corresponding take
 */
export const getTakeByIndex = (takes, index) => {
  const takeIndex = getTakeIndexByIndex(takes, index)

  return takes[takeIndex]
}

export const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac'] // add your OS values
  return os.find(v => window.navigator.userAgent.indexOf(v) >= 0)
}

export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf(' electron/') !== -1
}

export const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

export const getStatusColor = status =>
  Object.values(Status).includes(status)
    ? Color[status.toUpperCase()]
    : Color.MIRAGE

export const getPrescriptionGain = (
  discountPercentage,
  prescriptionTotalPrice
) => ((prescriptionTotalPrice / 100) * (25 - discountPercentage)).toFixed(2)

export const normalizeString = str =>
  str
    ?.trim()
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const removeBoxProductFromLineItems = (lineItems: any[]) => {
  return lineItems?.filter(
    edge =>
      !edge?.node?.merchandise?.product?.handle?.includes('boite-a-sachets')
  )
}

export const selectDefaultDuration = cart => {
  const duration = removeBoxProductFromLineItems(cart?.lines?.edges)?.filter(
    edge =>
      edge?.node?.merchandise?.product?.handle !== ProductHandle.MELATONINE &&
      edge?.node?.merchandise?.product?.handle !== ProductHandle.VEGAN_WHEY
  )?.[0]?.node?.merchandise?.title

  if (duration?.startsWith('1month')) {
    return PrescriptionRecommendedDuration.ONE_MONTH
  } else if (duration?.startsWith('2month')) {
    return PrescriptionRecommendedDuration.TWO_MONTH
  } else if (duration?.startsWith('3month')) {
    return PrescriptionRecommendedDuration.THREE_MONTH
  } else {
    return PrescriptionRecommendedDuration.ONE_MONTH
  }
}

export const isSubscription = cart => {
  const duration = removeBoxProductFromLineItems(cart?.lines?.edges)?.filter(
    edge =>
      edge?.node?.merchandise?.product?.handle !== ProductHandle.MELATONINE &&
      edge?.node?.merchandise?.product?.handle !== ProductHandle.VEGAN_WHEY
  )?.[0]?.node?.merchandise?.title

  return duration?.includes('subscription')
}

export const addBoxProduct = (addToCart, cart, duration, monthtlyPrice) => {
  const cartId = cart?.id
  let merchandiseId = boxProductVariants.ONE_MONTH
  let sellingPlanId = ''
  if (monthtlyPrice >= 70) {
    // Free
    merchandiseId = boxProductVariants.FREE
  } else {
    switch (duration) {
      case PrescriptionRecommendedDuration.ONE_MONTH:
        merchandiseId = boxProductVariants.ONE_MONTH
        if (isSubscription(cart)) {
          merchandiseId = boxProductVariants.ONE_MONTH_SUBSCRIPTION
          sellingPlanId =
            boxProductVariants.ONE_MONTH_SUBSCRIPTION_SELLING_PLAN_ID
        }
        break
      case PrescriptionRecommendedDuration.TWO_MONTH:
        merchandiseId = boxProductVariants.TWO_MONTH
        break
      case PrescriptionRecommendedDuration.THREE_MONTH:
        merchandiseId = boxProductVariants.THREE_MONTH
        break
    }
  }

  return toast.promise(
    addToCart({
      variables: {
        lines: [
          {
            merchandiseId,
            sellingPlanId: sellingPlanId ? sellingPlanId : undefined,
          },
        ],
        cartId,
      },
    }),
    {
      loading: 'en cours...',
      success: '',
      error: 'Oops! Une erreur est survenue.',
    },
    {
      duration: 3000,
    }
  )
}

export const getPriceBoxProduct = (monthtlyPrice, duration) => {
  const priceWithoutBox = monthtlyPrice - 5

  if (monthtlyPrice >= 70 && priceWithoutBox > 70) {
    return 0.0
  } else {
    switch (duration) {
      case PrescriptionRecommendedDuration.ONE_MONTH:
        return 5.0
      case PrescriptionRecommendedDuration.TWO_MONTH:
        return 5.0
      case PrescriptionRecommendedDuration.THREE_MONTH:
        return 5.0
      default:
        return 5.0
    }
  }
}

export const getMonthlyPrice = cart => {
  return (
    cart?.estimatedCost.subtotalAmount.amount /
    Number(displayRecommendedDuration(selectDefaultDuration(cart))[0])
  ).toFixed(1)
}

export const checkBoxProductRules = (
  cart,
  monthlyPrice,
  cartLinesRemove,
  addToCart,
  isTakesMoreThanThreeProducts,
  duration
) => {
  const boxProduct = cart?.lines?.edges?.find(i =>
    i?.node?.merchandise?.product?.handle.includes('boite-a-sachets')
  )
  const paidBoxProduct = cart?.lines?.edges?.find(
    i => i?.node?.merchandise?.product?.handle === 'boite-a-sachets-compliment'
  )
  const freeBoxProduct = cart?.lines?.edges?.find(
    i =>
      i?.node?.merchandise?.product?.handle ===
      'boite-a-sachets-compliment-offerte'
  )
  const isBoxProductSubscription =
    boxProduct?.node?.merchandise?.title.includes('subscription')

  const isCartSubscription = isSubscription(cart)

  if (!boxProduct) {
  } else if (!isTakesMoreThanThreeProducts) {
    boxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: boxProduct?.node?.id,
        },
      })
  } else if (isCartSubscription !== isBoxProductSubscription) {
    boxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: boxProduct?.node?.id,
        },
      })
  } else if (monthlyPrice >= 70) {
    const priceWithoutBox = monthlyPrice - 5

    paidBoxProduct &&
      priceWithoutBox > 70 &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: paidBoxProduct?.node?.id,
        },
      })
  } else if (monthlyPrice < 70) {
    freeBoxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: freeBoxProduct?.node?.id,
        },
      })
  }

  const boxProductTitle = boxProduct?.node?.merchandise?.title

  if (!boxProduct) {
  } else if (
    duration === PrescriptionRecommendedDuration.ONE_MONTH &&
    !boxProductTitle.includes('1')
  ) {
    boxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: boxProduct?.node?.id,
        },
      }).then(() => {
        !boxProduct &&
          addToCart({
            variables: {
              lines: [
                {
                  merchandiseId: boxProductVariants.ONE_MONTH,
                },
              ],
              cartId: cart?.id,
            },
          })
      })
  } else if (
    duration === PrescriptionRecommendedDuration.TWO_MONTH &&
    !boxProductTitle.includes('2')
  ) {
    boxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: boxProduct?.node?.id,
        },
      }).then(() => {
        !boxProduct &&
          addToCart({
            variables: {
              lines: [
                {
                  merchandiseId: boxProductVariants.TWO_MONTH,
                },
              ],
              cartId: cart?.id,
            },
          })
      })
  } else if (
    duration === PrescriptionRecommendedDuration.THREE_MONTH &&
    !boxProductTitle.includes('3')
  ) {
    boxProduct &&
      cartLinesRemove({
        variables: {
          cartId: cart?.id,
          lineIds: boxProduct?.node?.id,
        },
      }).then(() => {
        !boxProduct &&
          addToCart({
            variables: {
              lines: [
                {
                  merchandiseId: boxProductVariants.THREE_MONTH,
                },
              ],
              cartId: cart?.id,
            },
          })
      })
  }
}

export const removeVariantsToCart = (cartLinesRemove, lineIds, cartId) => {
  return toast.promise(
    cartLinesRemove({
      variables: {
        cartId,
        lineIds,
      },
    }),
    {
      loading: 'en cours...',
      success: '',
      error: 'Oops! Une erreur est survenue.',
    },
    {
      duration: 3000,
    }
  )
}

export const capitalizeFirstLetter = string => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
}

export function getTakePackagingFormatName(
  numberOfCapsules: number,
  packagingFormat: PackagingFormatType | any
): string {
  switch (packagingFormat) {
    case PackagingFormatType.POT:
      return `pot${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.BLISTER:
      return `blister${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.BOX:
      return `boîte${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.SPRAY:
      return `spray${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.FLACON:
      return `flacon${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.BOTTLE:
      return `bouteille${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.BAG:
      return `sachet${numberOfCapsules > 1 ? 's' : ''}`
    case PackagingFormatType.TUBE:
      return `tube${numberOfCapsules > 1 ? 's' : ''}`
    case 'UNIDOSE':
      return `unidose${numberOfCapsules > 1 ? 's' : ''}`
    default:
      return ''
  }
}

export function getTakeFormatName(
  numberOfCapsules: number,
  takeFormat: TakeFormatType
): string {
  switch (takeFormat) {
    case TakeFormatType.BAG:
      return `sachet${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.CAPSULE:
      return `gélule${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.DROPS:
      return `goutte${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.POD:
      return `dosette${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.SOFT_CAPSULE:
      return `capsule${numberOfCapsules > 1 ? 's' : ''} molle${
        numberOfCapsules > 1 ? 's' : ''
      }`
    case TakeFormatType.SPRAY:
      return `pulvérisation${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.TABLESPOON:
      return `cuillère à soupe${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.TABLETS:
      return `comprimé${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.TEASPOON:
      return `cuillère${numberOfCapsules > 1 ? 's' : ''} à café`
    case TakeFormatType.GLASS:
      return `verre${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.AMPOULE:
      return `ampoule${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.CAP:
      return `capuchon${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.NUT:
      return `noisette${numberOfCapsules > 1 ? 's' : ''}`
    case TakeFormatType.PATCH:
      return `patch${numberOfCapsules > 1 ? 's' : ''}`

    default:
      return ''
  }
}

export const getTimingDescription = (timing: BrandInstructionTiming) => {
  switch (timing) {
    case BrandInstructionTiming.ONCE_PER_DAY:
      return 'Une fois par jour'
    case BrandInstructionTiming.TWICE_PER_DAY:
      return 'Deux fois par jour'
    case BrandInstructionTiming.THREE_TIMES_PER_DAY:
      return 'Trois fois par jour'
    case BrandInstructionTiming.FOUR_TIMES_PER_DAY:
      return 'Quatre fois par jour'
    case BrandInstructionTiming.EVERY_MORNING:
      return 'Chaque matin'
    case BrandInstructionTiming.EVERY_NOON:
      return 'Chaque midi'
    case BrandInstructionTiming.EVERY_EVENING:
      return 'Chaque soir'
  }
}

export const getDuration = (duration: string) => {
  switch (duration) {
    case 'until symptoms are resolved':
      return "jusqu'à la fin des symptomes"
    case 'non stop':
      return 'en continu'
    case 'as needed':
      return 'autant que nécessaire'
    case '7 days':
      return 'pendant 1 semaine'
    case '14 days':
      return 'pendant 2 semaines'
    case '21 days':
      return 'pendant 3 semaines'
    case '30 days':
      return 'pendant 1 mois'
    case '60 days':
      return 'pendant 2 mois'
    case '90 days':
      return 'pendant 3 mois'
    case '120 days':
      return 'pendant 4 mois'
    case '150 days':
      return 'pendant 5 mois'
    case '180 days':
      return 'pendant 6 mois'
  }
}

export const getDefaultMarketplaceVariant = variants => {
  return variants
    ?.sort((a, b) => a?.price - b?.price)
    ?.filter(item => item?.title?.startsWith('mp'))?.[0]
}

export const selectPackagingTypeFromPrescriptionType = (
  PackagingType: PrescriptionType
): PackagingType => {
  if (PackagingType === 'COMPLIMENT') return 'PERSONNALISED'
  else if (PackagingType === 'LEGACY') return 'PERSONNALISED'
  else if (PackagingType === 'MARKETPLACE') return 'ORIGINAL'
  else return 'BOTH'
}

export const getNumberBetweenUnderscores = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return 0
  }

  const regex = /_(\d+)_/
  const match = str.match(regex)

  return match ? parseInt(match[1]) : 0
}

export const getSuggestion = (
  item: CreatePrescriptionItemDTO,
  takeTotalQuantity: number = 0
) => {
  const totalQuantityInstruction = item?.phases?.reduce((phaseTotal, phase) => {
    const phaseTotalIntakeQuantity = getPhaseTotalIntakeQuantity(phase)
    return (
      phaseTotal +
      phaseTotalIntakeQuantity * getPhaseDurationMultiplier(phase.duration)
    )
  }, 0)

  return Math.ceil(totalQuantityInstruction / takeTotalQuantity)
}

function getPhaseTotalIntakeQuantity(phase: PrescriptionItemPhaseDTO) {
  return phase.intakes.reduce((intakeSum, intake) => {
    let intakeQuantity = 0
    const isAnyPeriodIntake = Object.values(IntakeAnyPeriodType).includes(
      intake.lines[0]?.period as IntakeAnyPeriodType
    )

    if (intake.lines.length === 1 && isAnyPeriodIntake) {
      // case when an any period (1x per day, 2x per day, etc.) has been chosen
      intakeQuantity =
        intake.quantity *
        getIntakeAnyPeriodMultiplier(
          intake.lines[0]?.period as IntakeAnyPeriodType
        )
    } else {
      // case when a specific intake timing (morning, noon) or more has been chosen
      intakeQuantity = intake.quantity * intake.lines.length
    }
    return intakeSum + intakeQuantity
  }, 0)
}

function getIntakeAnyPeriodMultiplier(period: IntakeAnyPeriodType) {
  switch (period) {
    case IntakeAnyPeriodType.ONCE_PER_DAY:
      return 1
    case IntakeAnyPeriodType.TWICE_PER_DAY:
      return 2
    case IntakeAnyPeriodType.THREE_TIMES_PER_DAY:
      return 3
    case IntakeAnyPeriodType.FOUR_TIMES_PER_DAY:
      return 4
    case IntakeAnyPeriodType.FIVE_TIMES_PER_DAY:
      return 5
    default:
      return 1
  }
}

function getPhaseDurationMultiplier(duration: PhaseDurationType) {
  switch (duration) {
    case PhaseDurationType.UNTIL_SYMPTOM_RESOLUTION:
      return 30
    case PhaseDurationType.CONTINUOUS:
      return 30
    case PhaseDurationType.AS_NEEDED:
      return 30
    case PhaseDurationType.ONE_WEEK:
      return 7
    case PhaseDurationType.TWO_WEEKS:
      return 14
    case PhaseDurationType.THREE_WEEKS:
      return 21
    case PhaseDurationType.ONE_MONTH:
      return 30
    case PhaseDurationType.TWO_MONTHS:
      return 60
    case PhaseDurationType.THREE_MONTHS:
      return 90
    case PhaseDurationType.FOUR_MONTHS:
      return 120
    case PhaseDurationType.FIVE_MONTHS:
      return 150
    case PhaseDurationType.SIX_MONTHS:
      return 180
    default:
      return 30
  }
}

export const getTotalIntakeQuantityFromPhase = (
  phase: PrescriptionItemPhaseDTO
) => {
  const phaseTotalIntakeQuantity = getPhaseTotalIntakeQuantity(phase)

  return phaseTotalIntakeQuantity
}

export const isContentUnit = takeFormat => {
  return (
    takeFormat === 'POD' ||
    takeFormat === 'TEASPOON' ||
    takeFormat === 'SPRAY' ||
    takeFormat === 'GLASS' ||
    takeFormat === 'DROPS' ||
    takeFormat === 'CAP'
  )
}

export const getLowestPrice = variants => {
  if (variants?.length === 0) return null

  return variants?.reduce((lowest, variant) => {
    return variant?.price < lowest?.price ? variant : lowest
  })?.price
}

export function sortAndFilterVariants(product) {
  if (!product?.variants) return []

  return product.variants
    .filter(item => item?.title?.startsWith('mp'))
    .sort((a, b) => a.price - b.price)
}

export function translatePatientSex(patientSex: PatientSexe) {
  let title

  if (patientSex === 'MAN') {
    title = 'Mr'
  } else if (patientSex === 'WOMAN') {
    title = 'Mme'
  } else {
    title = 'Mr/Mme'
  }

  return title
}

export function convertShopifyVariantIdToBase64(variantId) {
  // Use a Buffer to convert the variantId to a base64 string
  return Buffer.from(variantId).toString('base64')
}

export function calculateDiscountPercentage(total: number, subtotal: number) {
  var discountAmount = subtotal - total
  var discountPercentage = (discountAmount / subtotal) * 100

  return discountPercentage ? Number(discountPercentage.toFixed(2)) : 0
}

export function normalizePrescriptionData(
  prescriptionData: PrescriptionStatusDTO,
  prescriptionDataItem: PrescriptionItemDTO[]
) {
  return {
    ...prescriptionData,
    takes: [
      {
        items: prescriptionDataItem,
        label: '',
        type: 'IN_CURE',
      },
    ],
  }
}

function combineItems(obj) {
  return [...(obj?.takes?.[0]?.items || []), ...(obj?.takes?.[1]?.items || [])]
}

export function mapPrescriptionDataWithComparison(
  prescriptionData: PrescriptionStatusDTO,
  otherPrescriptionData: PrescriptionStatusDTO,
  property: string
) {
  const prescriptionCombineItemsData = combineItems(prescriptionData)
  const otherPrescriptionombineItemsData = combineItems(otherPrescriptionData)

  // Check if prescriptionData and otherPrescriptionData are not empty
  if (!prescriptionData || !otherPrescriptionData) {
    return prescriptionCombineItemsData
  }

  return prescriptionCombineItemsData?.map(item => {
    if (
      otherPrescriptionombineItemsData.find(
        i => i.product.id === item.product.id
      )
    )
      return {
        ...item,
        [property]: false,
      }
    else
      return {
        ...item,
        [property]: true,
      }
  })
}

export function convertTimestampOrDateStringToFrenchDateString(input) {
  let date
  if (typeof input === 'string') {
    date = moment(input, 'YYYY-MM-DD')
    return date.format('dddd D MMMM YYYY')
  } else if (typeof input === 'number') {
    date = moment.unix(input)
    return date.format('dddd D MMMM YYYY [à] HH:mm:ss')
  } else {
    return '-'
  }
}

type SortOrder = 'MostRecentToOldest' | 'OldestToMostRecent'

export function sortDates(
  dateObjects: any[],
  sortOrder: SortOrder,
  datePropertyName: string
): any[] {
  if (sortOrder === 'MostRecentToOldest') {
    // Sort from most recent to oldest
    dateObjects?.sort(
      (a, b) =>
        new Date(b[datePropertyName]).getTime() -
        new Date(a[datePropertyName]).getTime()
    )
  } else if (sortOrder === 'OldestToMostRecent') {
    // Sort from oldest to most recent
    dateObjects?.sort(
      (a, b) =>
        new Date(a[datePropertyName]).getTime() -
        new Date(b[datePropertyName]).getTime()
    )
  } else {
    throw new Error(
      'Invalid sortOrder. Use "MostRecentToOldest" or "OldestToMostRecent".'
    )
  }

  return dateObjects
}

export function transformSanityImageURL(imageId) {
  const baseURL = 'https://cdn.sanity.io/images/veswluao/production/'
  const width = 2000
  const fit = 'max'
  const autoFormat = 'format'
  const dpr = 2

  const cleanedImageId = imageId?.replace('image-', '').replace('-png', '')
  const url = `${baseURL}${cleanedImageId}.png?w=${width}&fit=${fit}&auto=${autoFormat}&dpr=${dpr}`
  return url
}

export const getSuggestionSentence = (
  quantity: number = 0,
  timing: BrandInstructionTiming,
  duration: string,
  takeFormat: TakeFormatType
) => {
  let timingStr = ''
  let durationDays = ''

  // Determine timing string
  switch (timing) {
    case BrandInstructionTiming.ONCE_PER_DAY:
      timingStr = 'une fois par jour'
      break
    case BrandInstructionTiming.TWICE_PER_DAY:
      timingStr = 'deux fois par jour'
      break
    case BrandInstructionTiming.THREE_TIMES_PER_DAY:
      timingStr = 'trois fois par jour'
      break
    case BrandInstructionTiming.FOUR_TIMES_PER_DAY:
      timingStr = 'quatre fois par jour'
      break
    case BrandInstructionTiming.EVERY_MORNING:
      timingStr = 'chaque matin'
      break
    case BrandInstructionTiming.EVERY_NOON:
      timingStr = 'chaque midi'
      break
    case BrandInstructionTiming.EVERY_EVENING:
      timingStr = 'chaque soir'
      break
  }

  // Determine duration in days
  switch (duration) {
    case 'until symptoms are resolved':
      durationDays = "jusqu'à la fin des symptomes"
      break
    case 'non stop':
      durationDays = 'en continu'
      break
    case 'as needed':
      durationDays = 'autant que nécessaire'
      break
    case '7 days':
      durationDays = 'pendant 7 jours'
      break
    case '14 days':
      durationDays = 'pendant 14 jours'
      break
    case '21 days':
      durationDays = 'pendant 21 jours'
      break
    case '30 days':
      durationDays = 'pendant 30 jours'
      break
    case '60 days':
      durationDays = 'pendant 60 jours'
      break
    case '90 days':
      durationDays = 'pendant 90 jours'
      break
    case '120 days':
      durationDays = 'pendant 120 jours'
      break
    case '150 days':
      durationDays = 'pendant 150 jours'
      break
    case '180 days':
      durationDays = 'pendant 180 jours'
      break
  }

  return `${quantity} ${getTakeFormatName(
    quantity,
    takeFormat
  )} ${timingStr} ${durationDays} `
}

export const getMaxPercentageItem = (
  discountArray,
  commissionPercentage
): DiscountDTO => {
  if (!Array.isArray(discountArray) || discountArray.length === 0) {
    return null
  }

  const foundItem = discountArray.find(
    i => i.percentage === commissionPercentage
  )

  if (foundItem !== undefined) {
    return foundItem
  }

  return discountArray.find(i => i.percentage === 10) || null
}

export function returnRecommendedDuration(prescriptionDuration) {
  switch (prescriptionDuration) {
    case '1-month':
      return PrescriptionRecommendedDuration.ONE_MONTH
    case '2-month':
      return PrescriptionRecommendedDuration.TWO_MONTH
    case '3-month':
      return PrescriptionRecommendedDuration.THREE_MONTH

    default:
      return PrescriptionRecommendedDuration.ONE_MONTH
  }
}

export function pluralize(
  singular: string,
  plural: string,
  amount: number | undefined
) {
  const amountDisplay = amount === undefined ? '' : `${amount} `
  return `${amountDisplay} ${amount <= 1 ? singular : plural}`
}

async function processItem(item: PrescriptionItemDTO, prescriptionId: number) {
  let phases: PrescriptionItemPhaseDTO[] = []
  try {
    phases = await getPrescriptionProductPhases(prescriptionId, item.product.id)
  } catch {
    toast.error("Une erreur s'est produite lors de la récupération des phases")
  }

  return {
    ...item,
    productHandle: item.product?.handle,
    quantity: item.quantity,
    phases,
  }
}

export async function processTake(
  take: PrescriptionTakeDTO,
  prescriptionId: number
) {
  const items = await Promise.all(
    take.items.map(item => processItem(item, prescriptionId))
  )
  return { ...take, items }
}

async function processProtocolItem(
  item: PrescriptionItemDTO,
  protocolId: number
): Promise<DraftPrescriptionItemDTO> {
  let protocolPhases: ProtocolPhaseDTO[] = []
  try {
    protocolPhases = await getProtocolProductPhases(protocolId, item.product.id)
  } catch {
    toast.error("Une erreur s'est produite lors de la récupération des phases")
  }

  return {
    ...item,
    productHandle: item.product?.handle,
    quantity: item.quantity,
    isAffiliated: item.product?.isAffiliated,
    variantExternalUrl: item.product?.variantExternalUrl,
    phases: protocolPhases.map(phase => ({
      duration: phase.duration,
      period: phase.period,
      name: phase.name,
      intakes: phase.intakes.map(intake => ({
        lines: intake.lines.map(line => ({
          period: line.period,
        })),
        quantity: intake.quantity,
      })),
    })),
  }
}

export async function processProtocolTake(
  take: PrescriptionTakeDTO,
  prescriptionId: number
) {
  const items = await Promise.all(
    take.items.map(item => processProtocolItem(item, prescriptionId))
  )
  return { ...take, items }
}
