import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  intakesTableContainer: {
    padding: 36,
    flexDirection: 'column',
    gap: 18,
  },
  title: {
    fontSize: 24,
    color: '#1a0066',
  },
  tablesContainer: {
    flexDirection: 'column',
    gap: 9,
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#65B293',
    borderRadius: 3,
    backgroundColor: '#F8F9FC',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    backgroundColor: '#f8f8ff',
    borderColor: '#E7E9F4',
  },
  tableCell: {
    paddingHorizontal: 6,
    paddingVertical: 12,
    flex: 1,
    fontSize: 9,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dashedBottomBorder: {
    borderStyle: 'dashed',
    borderBottomWidth: 0.75,
    borderBottomColor: '#E7E9F4',
  },
  centeredCell: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerText: {
    color: '#666',
    fontSize: 9,
    fontWeight: 400,
    lineHeight: 1,
  },
  phaseName: {
    color: '#65B293',
    fontSize: 9,
    fontWeight: 600,
    lineHeight: 1,
  },
  productContainer: {
    flexDirection: 'column',
    gap: 9,
  },
  productPicture: {
    width: 18,
    height: 18,
  },
  productNameContainer: {
    flexDirection: 'column',
    gap: 1.5,
  },
  productName: {
    color: '#393360',
    fontSize: 9,
    fontWeight: 600,
    lineHeight: 1,
  },
  productUnit: {
    color: '#8F95B2',
    fontSize: 7.5,
    fontWeight: 400,
    lineHeight: 1,
  },
  regularCell: {
    flex: 1,
    fontSize: 9,
    color: '#393360',
    fontWeight: 400,
    lineHeight: 1,
    alignItems: 'center',
  },
  boldCell: {
    fontWeight: 600,
  },
})
