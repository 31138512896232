import { useState, useEffect } from 'react'
import {
  TabWrapper,
  NutritionContainerHeader,
  NutritionContainerTitle,
  TableWrapper,
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Select,
  IconWrapper,
  PopupContent,
  NoProduct,
} from './style'
import { PatientSexe } from '../../../../../services/api/types'
import { Country } from '../../../../../services/api/types/country'
import { PatientTarget } from '../../../../../services/api/types/patientTarget'
import {
  capitalizeFirstLetter,
  getTotalIntakeQuantityFromPhase,
} from '../../../../../utils/helpers'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { getVNRValueByProductIdAndCountryAndSexeAndPatientTarget } from '../../../../../services/api/vnrvalues/vnrvalues'
import { getProductNutritionalIntakesByProductId } from '../../../../../services/api/nutritional-intakes/nutritional-intakes'
import { getProductByIdOrHandle } from '../../../../../services/api/product/product'
import { TabProps } from './props'
import { calculateNutritionalValues } from '../utils'
import {
  getNames,
  translatePatientSexe,
} from '../../../../product/product-dialog/main/formulation'
import Popup from 'reactjs-popup'
import { TakeProductNrv } from '../types'
import { MaxDosageCell } from './max-dosage-cell'
import { PhaseIntakeQuantitiesCell } from './phase-intake-quantities-cell'
import { NrvCell } from './nrv-cell'

const Tab = ({ takes, onProductNrvChange, productNrv }: TabProps) => {
  // Attributes
  const [country, setCountry] = useState<Country>('FRANCE')
  const [sexe, setSexe] = useState<PatientSexe>('WOMAN')
  const [patientTarget, setPatientTarget] = useState<PatientTarget>(
    'EIGHTEEN_TO_SIXTY_YEARS'
  )

  //Functions
  const handleCountryChange = e => {
    setCountry(e)
  }

  const handleSexeChange = e => {
    setSexe(e)
  }

  const handlePatientTargetChange = e => {
    setPatientTarget(e)
  }

  const fetchProductVnr = async () => {
    const productNrv: TakeProductNrv[] = []

    for (let take of takes) {
      const promises = take.items.map(async item => {
        const product = await getProductByIdOrHandle(item.productHandle, {})

        const productsNutritional =
          await getProductNutritionalIntakesByProductId(product?.id)

        const nrvValues =
          await getVNRValueByProductIdAndCountryAndSexeAndPatientTarget(
            product?.id,
            {
              country: country,
              sexe: sexe,
              target: patientTarget,
            }
          )

        const takeProduct = take.items.find(
          i => i.productHandle === product.handle
        )

        const phaseTotalIntakeQuantities = takeProduct.phases.map(phase =>
          getTotalIntakeQuantityFromPhase(phase)
        )

        const mappedNrvValues = nrvValues?.map(item => {
          const productNutritional = productsNutritional?.find(i => {
            return i?.nutritionalIntake?.id === item?.nutritionalIntake?.id
          })
          return {
            ...item,
            productNutritional,
          }
        })

        return {
          phaseTotalIntakeQuantities,
          product: {
            name: product.name,
          },
          nrvValues: mappedNrvValues,
        }
      })

      const takeProductNrv = await Promise.all(promises)
      productNrv.push(...takeProductNrv)
    }

    return productNrv
  }

  // Hooks
  useEffect(() => {
    fetchProductVnr().then(productNrv => {
      onProductNrvChange(productNrv)
    })
  }, [takes, country, sexe, patientTarget])

  const productData = calculateNutritionalValues(productNrv)

  // Render
  return (
    <TabWrapper>
      <NutritionContainerHeader>
        <NutritionContainerTitle>Apport nutritionnel</NutritionContainerTitle>

        <Select
          value={country}
          onChange={e => handleCountryChange(e.target.value)}
        >
          {(Object.keys(Country) as Array<keyof typeof Country>).map(value => {
            return (
              <>
                <option value={value}>{capitalizeFirstLetter(value)}</option>
              </>
            )
          })}
        </Select>

        <Select value={sexe} onChange={e => handleSexeChange(e.target.value)}>
          {(Object.keys(PatientSexe) as Array<keyof typeof PatientSexe>).map(
            value => {
              return (
                <>
                  <option value={value}>{translatePatientSexe(value)}</option>
                </>
              )
            }
          )}
        </Select>

        <Select
          value={patientTarget}
          onChange={e => handlePatientTargetChange(e.target.value)}
        >
          {(
            Object.keys(PatientTarget) as Array<keyof typeof PatientTarget>
          ).map(v => {
            const { label, value } = getNames(v)
            return (
              <>
                <option value={value}>{label} </option>
              </>
            )
          })}
        </Select>
      </NutritionContainerHeader>

      <TableWrapper>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell>Ingrédient</TableHeaderCell>

            <TableHeaderCell>
              Dosage total quotidien{' '}
              <Popup
                trigger={
                  <IconWrapper>
                    <AiOutlineInfoCircle />
                  </IconWrapper>
                }
                position='bottom center'
                on='hover'
                closeOnDocumentClick
                mouseLeaveDelay={100}
                mouseEnterDelay={0}
                contentStyle={{ padding: '0px', border: 'none' }}
                arrow={false}
              >
                <PopupContent>
                  Dosage total quotidien d’un ingrédient pour votre
                  recommandation.
                </PopupContent>
              </Popup>
            </TableHeaderCell>

            <TableHeaderCell>
              VNR{' '}
              <Popup
                trigger={
                  <IconWrapper>
                    <AiOutlineInfoCircle />
                  </IconWrapper>
                }
                position='bottom center'
                on='hover'
                closeOnDocumentClick
                mouseLeaveDelay={100}
                mouseEnterDelay={0}
                contentStyle={{ padding: '0px', border: 'none' }}
                arrow={false}
              >
                <PopupContent>
                  Apport quotidien recommandé (Valeurs nutritionnelles de
                  référence)
                </PopupContent>
              </Popup>
            </TableHeaderCell>

            <TableHeaderCell>Dosage maximum autorisé</TableHeaderCell>

            <TableHeaderCell>Produit contenant l’ingrédient</TableHeaderCell>
          </TableHeaderRow>

          {!productData.length && (
            <NoProduct>Pas d’apports pour ces produits</NoProduct>
          )}

          {productData.map(value => {
            return (
              <TableRow>
                <TableCell>{value?.ingredientName}</TableCell>

                <TableCell>
                  <PhaseIntakeQuantitiesCell
                    quantities={value?.phaseIntakeTotalQuantities}
                  />
                </TableCell>

                <TableCell>
                  <NrvCell nrv={value?.nrv} />
                </TableCell>

                <TableCell>
                  <MaxDosageCell dosageMax={value?.dosageMax} />
                </TableCell>

                <TableCell>{value?.productsName?.join(', ')}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </TableWrapper>
    </TabWrapper>
  )
}

export default Tab
