/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { SimplifiedInstructionDTO, CreateInstructionDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getInstructionLists = (
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SimplifiedInstructionDTO[]>(
    { url: `/products/${productId}/instructions`, method: 'get' },
    options
  )
}

export const getGetInstructionListsQueryKey = (productId: string) => [
  `/products/${productId}/instructions`,
]

export const useGetInstructionLists = <
  TData = AsyncReturnType<typeof getInstructionLists>,
  TError = unknown
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getInstructionLists>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetInstructionListsQueryKey(productId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getInstructionLists>> =
    () => getInstructionLists(productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getInstructionLists>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createInstruction = (
  productId: string,
  createInstructionDTO: CreateInstructionDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SimplifiedInstructionDTO>(
    {
      url: `/products/${productId}/instructions`,
      method: 'post',
      data: createInstructionDTO,
    },
    options
  )
}

export const useCreateInstruction = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createInstruction>,
    TError,
    { productId: string; data: CreateInstructionDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createInstruction>,
    { productId: string; data: CreateInstructionDTO }
  > = props => {
    const { productId, data } = props || {}

    return createInstruction(productId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createInstruction>,
    TError,
    { productId: string; data: CreateInstructionDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const patchInstructionDefault = (
  instructionId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/instructions/${instructionId}/default`, method: 'patch' },
    options
  )
}

export const usePatchInstructionDefault = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof patchInstructionDefault>,
    TError,
    { instructionId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof patchInstructionDefault>,
    { instructionId: string }
  > = props => {
    const { instructionId } = props || {}

    return patchInstructionDefault(instructionId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof patchInstructionDefault>,
    TError,
    { instructionId: string },
    TContext
  >(mutationFn, mutationOptions)
}
