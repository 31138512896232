import styled from 'styled-components/macro'

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const QuantityHandleWrapper = styled.div`
  margin-right: 1rem;
  font-size: 0.625rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem 0.625rem;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 6.25rem;
`

export const QuantityHandleNumber = styled.div`
  margin: 0 8px;
`

export const QuantityHandlerMinus = styled.div`
  cursor: pointer;
  padding: 0 0.625rem;
  font-size: 0.875rem;
`
export const QuantityHandlerPlus = styled.div`
  cursor: pointer;
  padding: 0 0.5rem;
  font-size: 0.875rem;
`

export const QuantityChoiceWrapper = styled.div`
  display: flex;
`

export const QuantityChoice = styled.div`
  cursor: pointer;
  margin-right: 0.25rem;
  padding: 0.5rem 0.75rem;
  background-color: ${props => (props.selected ? '#65B293' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 1px solid #e7e9f4;
  border-radius: 6.25rem;
  font-size: 0.625rem;
`
