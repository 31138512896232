import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/reco'
import { useQuery } from 'react-query'
import { CartService } from '../../services/cart.service'
import { CART_QUERY } from '../../utils/constants'
import Cookies from 'js-cookie'
import AccessModal from '../patient-products/access-modal'
import Discount from '../patient-products/discount-message'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../components/ui'
import PageTitle from '../../components/section/page-title'
import {
  mapSubscriptionLines,
  mapOneTimePurchaseLines,
  mapShopifyCartToPrescriptionTakeDTOs,
} from './utils'
import {
  BodySection,
  Container,
  Content,
  Left,
  PageTitleWrapper,
  Right,
  OrderSummary,
  FooterBar,
  FooterLine,
  FooterLinePrice,
} from './style'
import { getCart } from '../../store/cart'
import {
  useAddToCart,
  useUpdateCart,
  useCartLinesRemove,
  useFetchCart,
  useCartAttributesUpdate,
} from '../../utils/hooks'
import MarketplacePrescriptionProductsList from '../../components/prescription/marketplace-prescription-products-list'
import ProductsUpsellsDialog from '../../components/product/products-upsells-dialog'
import PaymentSettings from '../../components/payment/payment-settings'

import { selectDefaultDuration, isSubscription } from '../../utils/helpers'
import Arguments from '../../components/public-reco/arguments'
import Faq from '../../components/public-reco/faq'
import Testimonials from '../../components/public-reco/testimonials'
import Footer from '../../components/public-reco/footer'
import { PrescriptionTakeDTO } from '../../services/api/types'
import { PageType } from '../../utils/constants'
import { useHistory } from 'react-router'
import { getProductLaboratoryInstructionPhases } from '../../services/api/instruction-phases/instruction-phases'

const RecommendationPage = () => {
  // Attributes
  const history = useHistory()
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false)
  const { fetchCart, loading: fetchCartLoading } = useFetchCart()
  const c = useQuery(CART_QUERY, () => {
    return CartService.getCart()
  })

  const cart = useSelector((state: any) => getCart(state))

  const [isOpenDialogMoreProducts, setIsOpenDialogMoreProducts] =
    useState(false)
  const { cartAttributesUpdate, loading: cartAttributesUpdateLoading } =
    useCartAttributesUpdate()
  const { addToCart, loading: addToCartLoading } = useAddToCart()
  const { updateCart, loading: updateCartLoading } = useUpdateCart()
  const { cartLinesRemove } = useCartLinesRemove()
  const [takes, setTakes] = useState<PrescriptionTakeDTO[]>([])
  const [isTakesLoading, setIsTakesLoading] = useState(false)

  const cartItemCount = cart?.lines?.edges?.length

  // Functions
  const addVariantToCart = async (
    productId,
    merchandiseId,
    takeNumber,
    handle,
    takeName,
    quantity = 1,
    instructionDuration = '',
    instructionQuantity = '',
    instructionTiming = '',
    takeFormat?: null
  ) => {
    const phases = await getProductLaboratoryInstructionPhases(productId)
    const structuredPhases = phases.map(phase => ({
      _name: phase.name,
      _duration: phase.duration,
      _period: phase.period,
      _intakes: phase.intakes.map(intake => ({
        _quantity: intake.quantity,
        _periods: intake.lines.map(line => ({
          _period: line.period,
        })),
      })),
    }))

    let attributes = [
      {
        key: '_handle',
        value: handle,
      },
      {
        key: '_prise',
        value: takeNumber ?? '/',
      },
      {
        key: '_nomPrise',
        value: takeName ?? '/',
      },
      {
        key: '_phases',
        value: JSON.stringify(structuredPhases),
      },
    ]
    if (takeFormat)
      attributes = [...attributes, { key: '_takeFormat', value: takeFormat }]
    if (instructionDuration)
      attributes = [
        ...attributes,
        { key: '_instructionDuration', value: `${instructionDuration}` },
      ]
    if (instructionQuantity)
      attributes = [
        ...attributes,
        { key: '_instructionQuantity', value: `${instructionQuantity}` },
      ]
    if (instructionTiming)
      attributes = [
        ...attributes,
        { key: '_instructionTiming', value: `${instructionTiming}` },
      ]
    return addToCart({
      variables: {
        lines: [
          {
            merchandiseId: merchandiseId,
            quantity,
            attributes,
          },
        ],
        cartId: cart.id,
      },
    }).then(() => {
      setIsOpenDialogMoreProducts(true)
    })
  }

  const updateVariantToCart = (
    cartId,
    cartLineId,
    qty,
    attributes,
    sellingPlanId = null
  ) => {
    if (sellingPlanId)
      updateCart({
        variables: {
          cartId: cartId,
          lines: {
            id: cartLineId,
            quantity: qty,
            attributes,
            sellingPlanId,
          },
        },
      })
    else
      updateCart({
        variables: {
          cartId: cartId,
          lines: {
            id: cartLineId,
            quantity: qty,
            attributes,
          },
        },
      })
  }

  const removeVariantsToCart = (cartId, lineIds) => {
    return cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: lineIds,
      },
    })
  }

  // Update product prices to subscription prices
  const updateSubscription = async () => {
    if (!cart?.id) return

    // Get lines for shopify
    const lines = await mapSubscriptionLines(cart, 'ORIGINAL')

    updateCart({
      variables: {
        cartId: cart.id,
        lines,
      },
    })
  }

  // Update product prices to one time prices
  const updateOneTimePurchase = async duration => {
    if (!cart?.id) return

    // Get lines for shopify
    const lines = await mapOneTimePurchaseLines(cart, duration, 'ORIGINAL')

    return updateCart({
      variables: {
        cartId: cart.id,
        lines,
      },
    })
  }

  const goToCheckout = () => {
    const savedPromoCode = Cookies.get('promoCode')

    if (!savedPromoCode) {
      history.push(`/cart?goToCheckout=${cart?.checkoutUrl}`)
      setIsAccessModalOpen(true)
    } else window.location.href = cart?.checkoutUrl
  }

  useEffect(() => {
    c?.data?.id &&
      fetchCart({
        variables: { cartId: c.data.id },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [c.data?.id, c.data?.items, c.isLoading])

  useEffect(() => {
    // Assuming you're updating the cart somewhere in here
    cart?.id &&
      updateCart({
        variables: {
          cartId: cart.id,
          lines: [],
        },
      }).then(() => {
        // After the cart has been updated, force a re-render
      })
  }, [cart?.id])

  useEffect(() => {
    const fetchData = async () => {
      setIsTakesLoading(true)

      try {
        const mappedData = await mapShopifyCartToPrescriptionTakeDTOs(
          cart,
          [],
          'ORIGINAL'
        )

        setTakes(mappedData)
      } catch (error) {
      } finally {
        setIsTakesLoading(false)
      }
    }
    fetchData()
  }, [cart, fetchCartLoading])

  // Render
  return (
    <Layout
      from='cart'
      setIsOpenDialogMoreProducts={setIsOpenDialogMoreProducts}
      nav={[]}
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Checkout prescription</title>
      </Helmet>
      <Container>
        <Content>
          <div>
            <PageTitle
              title={
                <PageTitleWrapper>
                  <p>Votre panier</p>
                  <Discount />
                </PageTitleWrapper>
              }
            />
            <BodySection>
              <Left>
                <OrderSummary>
                  <MarketplacePrescriptionProductsList
                    isPatientCatalog
                    setIsOpenDialogMoreProducts={setIsOpenDialogMoreProducts}
                    takes={takes}
                    cart={cart}
                    updateVariantToCart={async (
                      nodeId,
                      quantity,
                      attributes,
                      sellingPlanId
                    ) =>
                      updateVariantToCart(
                        cart.id,
                        nodeId,
                        quantity,
                        attributes,
                        sellingPlanId
                      )
                    }
                    removeVariantsToCart={async nodeIds =>
                      removeVariantsToCart(cart.id, nodeIds)
                    }
                    isLoading={!takes.length && isTakesLoading}
                    abilityToManageQuantity
                    enableLaboratoryInstructions
                  />
                </OrderSummary>
              </Left>

              <Right>
                {cart && (
                  <PaymentSettings
                    isMarketplacePrescription={true}
                    displayMAndDown='none'
                    updateCartLoading={updateCartLoading}
                    cartAttributesUpdateLoading={cartAttributesUpdateLoading}
                    cartAttributesUpdate={cartAttributesUpdate}
                    prescription={undefined}
                    discountCode={Cookies.get('promoCode') ?? ''}
                    selectDefaultDuration={selectDefaultDuration}
                    cart={cart}
                    updateSubscription={updateSubscription}
                    updateOneTimePurchase={updateOneTimePurchase}
                    isSubscription={isSubscription(cart)}
                    checkoutUrl={cart.checkoutUrl}
                    isTakesMoreThanThreeProducts={false}
                    isMarketPlace={true}
                    goToCheckout={goToCheckout}
                    setSliderProductDialogOpen={setIsOpenDialogMoreProducts}
                  />
                )}
              </Right>
            </BodySection>

            {isOpenDialogMoreProducts && (
              <ProductsUpsellsDialog
                takes={takes}
                isDialogOpen={isOpenDialogMoreProducts}
                setIsDialogOpen={setIsOpenDialogMoreProducts}
                addProduct={addVariantToCart}
                recommendedDuration={selectDefaultDuration(cart)}
                addToCartLoading={addToCartLoading}
                prescriptionType={'MARKETPLACE'}
                enableFavorite={false}
                from={PageType.CART}
              />
            )}
          </div>

          <Arguments />
          <Testimonials />
          <Faq />

          {cart?.checkoutUrl && (
            <FooterBar>
              <FooterLine></FooterLine>
              <FooterLinePrice>
                {cart?.estimatedCost.totalAmount.amount !==
                cart?.estimatedCost.subtotalAmount.amount ? (
                  <>
                    <del>{cart?.estimatedCost.subtotalAmount.amount} €</del>
                    <span
                      style={{
                        marginLeft: '8px',
                      }}
                    >
                      {cart?.estimatedCost?.totalAmount?.amount} €
                    </span>
                  </>
                ) : (
                  <>{cart?.estimatedCost?.totalAmount?.amount} €</>
                )}
              </FooterLinePrice>
              <Button
                onClick={goToCheckout}
                style={{ height: '35px' }}
                appearance='primary'
                iconLeft={undefined}
                isDisabled={!cartItemCount}
                isLoading={undefined}
                isActive={undefined}
              >
                Continuer vers le paiement
              </Button>
            </FooterBar>
          )}
        </Content>
        <Footer />
      </Container>

      <AccessModal
        isOpen={isAccessModalOpen}
        setIsOpen={setIsAccessModalOpen}
        description="Pour passer une commande, merci d'entrer le code fourni par votre prescripteur."
      />
    </Layout>
  )
}

export default RecommendationPage
