import { FormEvent, useEffect } from 'react'
import {
  AddIntakeButton,
  AddIntakeWrapper,
  AddPhaseButton,
  AddPhaseWrapper,
  HorizontalSeparator,
  IntakesWrapper,
  PhaseActions,
  PhaseContainer,
  PhaseDetailsWrapper,
  PhaseHeader,
  PhasesContainer,
  PhaseWrapper,
  customStyles,
  VerticalSeparator,
  ColoredTextDiv,
} from './style'
import Select from 'react-select'
import {
  getPhaseDurationOptions,
  getPhasePeriodOptions,
  mapPhaseDurationToOption,
  mapPhasePeriodToOption,
} from './utils'
import {
  Option,
  PrescriptionValueToChange,
} from '../../../../../utils/constants'
import { IconPlusVariant } from '../../../../ui/icon/icons'
import { DescriptionTextInput, DescriptionWrapper } from '../style'
import { ProductPhasesProps } from './props'
import Switch from 'react-switch'
import useProductPhaseLogic from './logic'
import InstructionDropdown from './instruction-dropdown'
import NameInput from './name-input'
import { SwitchCureContainer } from './name-input/style'
import IntakeLine from './intake-line'
import SuggestionCard from '../suggestion-card'
import PhaseDurationSingleValue from './phase-duration-single-value'

const ProductPhases = ({
  phases,
  item,
  onTakesItemsChange,
  onDraftChangedChange,
  product,
  onValueInPrescriptionChange,
  takeIndex,
  itemNote,
  onIsDeleteInstructionModalOpenChange,
  onInstructionDeletionInfoChange,
  chosenProductItems,
  selectedUsedInstructionId,
  itemIndex,
  setValueInPrescription,
}: ProductPhasesProps) => {
  // Attributes
  const {
    calculateWidth,
    handleNoteDescriptionChange,
    handlePhaseDurationSelect,
    handlePhasePeriodSelect,
    handleSwitchChange,
    isMultiPhasesChecked,
    phaseWidths,
    setPhaseWidths,
    handlePhaseNameChange,
    handleAddIntakeToPhaseClick,
    handleAddPhaseToItemClick,
    handleInstructionSelect,
    handleIntakeDelete,
    handleIntakePeriodSelect,
    handleIntakeQuantityChange,
    handleSelectedUsedInstructionIdChange,
    handleIsMultiPhasesCheckedChange,
  } = useProductPhaseLogic({
    item,
    onTakesItemsChange,
    onDraftChangedChange,
    onValueInPrescriptionChange,
    phases,
    product,
    takeIndex,
    itemIndex,
  })

  useEffect(() => {
    const newPhaseWidths = {}

    // Loop through all phases and calculate width for both 'period' and 'duration' selects
    phases.forEach((phase, phaseIndex) => {
      const initialPeriodValue =
        mapPhasePeriodToOption(phase.period)?.label || ''
      const initialDurationValue =
        mapPhaseDurationToOption(phase.duration)?.label || ''

      // Set the width for each phase independently
      newPhaseWidths[`duration_${phaseIndex}`] =
        calculateWidth(initialDurationValue)
      newPhaseWidths[`period_${phaseIndex}`] =
        calculateWidth(initialPeriodValue)
    })

    // Update the state with the new widths for each phase
    setPhaseWidths(newPhaseWidths)
  }, [phases]) // Recalculate when the phases change

  // Render
  return (
    <PhasesContainer>
      <SuggestionCard
        setQuantity={(quantity: number) =>
          setValueInPrescription(
            product,
            quantity,
            takeIndex,
            PrescriptionValueToChange.ITEM_QUANTITY
          )
        }
        item={item}
        takeTotalQuantity={product?.takeTotalQuantity}
      />
      {phases.map((phase, phaseIndex) => (
        <PhaseContainer key={phaseIndex}>
          <PhaseHeader>
            <NameInput
              value={phase.name}
              handle={(value: string) =>
                handlePhaseNameChange(phaseIndex, value)
              }
            />
            {phaseIndex === 0 && (
              <PhaseActions>
                <SwitchCureContainer>
                  <Switch
                    checked={isMultiPhasesChecked}
                    onChange={handleSwitchChange}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor='#4FDC5D'
                    offColor='#E6E5EB'
                    height={16}
                    width={26}
                    handleDiameter={12}
                  />
                  <ColoredTextDiv>Dosages avancés</ColoredTextDiv>
                </SwitchCureContainer>
                <InstructionDropdown
                  phases={phases}
                  productId={product?.id}
                  onIsDeleteInstructionModalOpenChange={
                    onIsDeleteInstructionModalOpenChange
                  }
                  onInstructionDeletionInfoChange={
                    onInstructionDeletionInfoChange
                  }
                  onInstructionSelect={handleInstructionSelect}
                  chosenProductItems={chosenProductItems}
                  selectedUsedInstructionId={selectedUsedInstructionId}
                  onSelectedUsedInstructionIdChange={
                    handleSelectedUsedInstructionIdChange
                  }
                  takeIndex={takeIndex}
                  itemIndex={itemIndex}
                  onIsMultiPhasesCheckedChange={
                    handleIsMultiPhasesCheckedChange
                  }
                />
              </PhaseActions>
            )}
          </PhaseHeader>
          <PhaseWrapper>
            <PhaseDetailsWrapper>
              {/* Phase Duration Select */}
              <Select
                value={mapPhaseDurationToOption(phase.duration)}
                options={getPhaseDurationOptions()}
                onChange={(option: Option) =>
                  handlePhaseDurationSelect(phaseIndex, option)
                }
                components={{
                  IndicatorSeparator: () => null,
                  SingleValue: PhaseDurationSingleValue,
                }}
                styles={{
                  ...customStyles,
                  control: base => ({
                    ...customStyles.control(base),
                    width: phaseWidths[`duration_${phaseIndex}`],
                  }),
                }}
                isSearchable={false}
              />
              {/* Phase Period Select */}
              <Select
                value={mapPhasePeriodToOption(phase.period)}
                options={getPhasePeriodOptions()}
                onChange={(option: Option) =>
                  handlePhasePeriodSelect(phaseIndex, option)
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  ...customStyles,
                  control: base => ({
                    ...customStyles.control(base),
                    width: phaseWidths[`period_${phaseIndex}`],
                  }),
                }}
                isSearchable={false}
              />
            </PhaseDetailsWrapper>
            <VerticalSeparator />
            <IntakesWrapper>
              {phase.intakes.map((intake, intakeIndex) => (
                <IntakeLine
                  key={intakeIndex}
                  intake={intake}
                  intakeIndex={intakeIndex}
                  phaseIndex={phaseIndex}
                  phases={phases}
                  intakes={phase.intakes}
                  product={product}
                  onIntakeQuantityChange={handleIntakeQuantityChange}
                  onIntakePeriodSelect={handleIntakePeriodSelect}
                  onIntakeDelete={handleIntakeDelete}
                />
              ))}

              {isMultiPhasesChecked && (
                <AddIntakeWrapper>
                  <AddIntakeButton
                    onClick={() => handleAddIntakeToPhaseClick(phaseIndex)}
                  >
                    <IconPlusVariant width={16} height={16} color='#8F95B2' />
                    <div>Ajouter une prise</div>
                  </AddIntakeButton>
                </AddIntakeWrapper>
              )}
            </IntakesWrapper>
          </PhaseWrapper>
        </PhaseContainer>
      ))}
      {isMultiPhasesChecked && (
        <AddPhaseWrapper>
          <AddPhaseButton onClick={handleAddPhaseToItemClick}>
            <IconPlusVariant />
            <div>Ajouter une phase</div>
          </AddPhaseButton>
          <HorizontalSeparator />
        </AddPhaseWrapper>
      )}
      <DescriptionWrapper>
        <DescriptionTextInput
          onChange={(e: FormEvent<HTMLInputElement>) =>
            handleNoteDescriptionChange(e.currentTarget.value)
          }
          value={itemNote}
          placeholder='Tapez une note'
        />
      </DescriptionWrapper>
    </PhasesContainer>
  )
}

export default ProductPhases
