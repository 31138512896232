import { useState } from 'react'
import {
  Container,
  Header,
  Title,
  Nav,
  NavItem,
  FavoritesListByPackagingTypeWrapper,
  Flex,
} from './style'
import { toast } from 'react-toastify'
import { Dialog } from '../../ui'
import { selectPackagingTypeFromPrescriptionType } from '../../../utils/helpers'
import { ViewMode, viewModeFilter } from '../../../utils/constants'
import { BackButton } from '../../ui'
import Products from '../../products/products'
import { ProductSLiderProps } from './props'
import FavoritesListByPackagingType from '../../product/favorites/favorites-list-by-packaging-type'
import RequestProduct from '../../products/request-product'

const ProductSlider = ({
  isDialogOpen,
  setIsDialogOpen,
  addProductToTake,
  prescriptionType,
  draft,
  userType = 'prescriber',
}: ProductSLiderProps) => {
  // Attributes
  const [nav, setNav] = useState<'catalog' | 'favorite'>('catalog')
  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState<boolean>(true)
  const [isPatient, setIsPatient] = useState<boolean>(true)

  // Functions
  const onProductClicked = (product, quantity = 1) => {
    addProduct(product, 0, quantity)
    toast.success('Le produit a bien été ajouté')
  }

  const addProduct = (product, takeIndex = undefined, quantity = 1) => {
    addProductToTake(product, takeIndex, quantity)
  }

  const onFavoriteProductClicked = (product, quantity = 1) => {
    addProduct(product, 0, quantity)
    toast.success('Le produit a bien été ajouté')
  }

  // Render
  return (
    <Dialog
      type='aside'
      isOpen={isDialogOpen}
      styleContainer={null}
      closeOnOverlayClick={isInnerDialogOpen}
      width='100%'
      padding='1.875rem'
    >
      <Container>
        <Header>
          <BackButton
            buttonText='Mon panier'
            onClick={() => setIsDialogOpen(false)}
          />
          <Title> Ajoutez des produits</Title>
        </Header>
        {userType === 'prescriber' && (
          <>
            <Flex>
              <Nav>
                <NavItem
                  active={nav === 'catalog'}
                  onClick={() => setNav('catalog')}
                >
                  Catalogue
                </NavItem>
                <NavItem
                  active={nav === 'favorite'}
                  onClick={() => setNav('favorite')}
                >
                  Favoris
                </NavItem>
              </Nav>
              <RequestProduct setIsInnerDialogOpen={setIsInnerDialogOpen} />
            </Flex>
            {nav === 'catalog' && (
              <Products
                packagingType={selectPackagingTypeFromPrescriptionType(
                  prescriptionType
                )}
                onProductClicked={onProductClicked}
                viewModeProduct={ViewMode.SLIDE}
                setIsInnerDialogOpen={setIsInnerDialogOpen}
                hideDiscountPercentage
                excludeAffiliated={false}
              />
            )}

            {nav === 'favorite' && (
              <FavoritesListByPackagingTypeWrapper>
                <FavoritesListByPackagingType
                  onProductClicked={onFavoriteProductClicked}
                  addProduct={undefined}
                  takes={draft?.takes}
                  recommendedDuration={draft?.recommendedDuration || '1-month'}
                  packagingType='ORIGINAL'
                  mode={ViewMode.SLIDE}
                />
              </FavoritesListByPackagingTypeWrapper>
            )}
          </>
        )}
        {userType === 'patient' && (
          <Products
            viewModeProduct={ViewMode.GRID}
            from='productPage'
            enableFavorite={false}
            enableAddToCart={true}
            isPatientCatalog
            allowProductUrl={true}
            excludeAffiliated={true}
          />
        )}
      </Container>
    </Dialog>
  )
}

export default ProductSlider
