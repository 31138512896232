import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
`
export const ListTitle = styled.div`
  color: #080038;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const ListDescription = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: .75rem;
`
