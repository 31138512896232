import {
  Container,
  TakeLabel,
  TakeContainer,
  ProductsContainer,
  ListTitle,
} from './style'
import ProductItem from './product'
import { Spinner } from '../../ui'
import { PrescriptionProductsListProps } from './props'
import { PrescriptionTakeType } from '../../../services/api/types'
import NoProduct from './no-product'
import { useLocation } from 'react-router'
import { calculateDiscountPercentage } from '../../../utils/helpers'

const MarketplacePrescriptionProductsList = ({
  cart,
  isLoading = false,
  takes,
  abilityToManageQuantity = false,
  updateVariantToCart,
  removeVariantsToCart,
  setIsOpenDialogMoreProducts,
  isPatientCatalog,
  protocolId,
  prescriptionId,
  missingItems,
  enableLaboratoryInstructions,
}: PrescriptionProductsListProps) => {
  // Attributes
  const location = useLocation()
  const discountPercentage = calculateDiscountPercentage(
    Number(cart?.estimatedCost?.totalAmount?.amount),
    Number(cart?.estimatedCost?.subtotalAmount?.amount)
  )
  const inCureTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  const standAloneTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  // Render
  if (isLoading) return <Spinner fullScreen={undefined} />

  return (
    <Container>
      {location.pathname === '/cart' &&
        !inCureTakes?.[0]?.items?.length &&
        !standAloneTakes?.[0]?.items?.length && (
          <NoProduct
            setIsOpenDialogMoreProducts={setIsOpenDialogMoreProducts}
          />
        )}
      <ListTitle>Vos produits</ListTitle>
      {!!inCureTakes?.[0]?.items?.length && (
        <div>
          {inCureTakes.map((take, index) => (
            <TakeContainer key={index}>
              {/* Don't show the title of the take if only one take */}
              {inCureTakes.length > 1 && (
                <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
              )}
              {take?.items.map(item => {
                const edge = cart?.lines?.edges?.find(
                  edge =>
                    edge.node.merchandise.product.handle ===
                    item?.product?.handle
                )
                const isNotPrescribedItem = missingItems?.some(
                  missingItem =>
                    missingItem.node.merchandise.product?.handle ===
                    item.product?.handle
                )

                return (
                  <ProductItem
                    key={item.product?.id}
                    item={item}
                    abilityToManageQuantity={abilityToManageQuantity}
                    cartInfo={{
                      updateVariantToCart,
                      removeVariantsToCart,
                      nodeId: edge?.node?.id,
                      attributes: edge?.node?.attributes,
                    }}
                    pricing={{
                      subtotalAmount:
                        edge?.node?.estimatedCost?.subtotalAmount?.amount,
                      totalAmount:
                        edge?.node?.estimatedCost?.totalAmount?.amount,
                      discountPercentage: discountPercentage,
                    }}
                    isPatientCatalog={isPatientCatalog}
                    protocolId={protocolId}
                    prescriptionId={prescriptionId}
                    isNotPrescribedItem={
                      isNotPrescribedItem || enableLaboratoryInstructions
                    }
                  />
                )
              })}
            </TakeContainer>
          ))}
        </div>
      )}

      {!!standAloneTakes?.[0]?.items?.length && (
        <div>
          {standAloneTakes.map((take, index) => {
            return (
              <TakeContainer key={index}>
                {/* Don't show the title of the take if only one take */}
                {inCureTakes.length > 1 && (
                  <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
                )}
                <ProductsContainer>
                  {take?.items.map(item => {
                    const edge = cart?.lines?.edges?.find(
                      edge =>
                        edge.node.merchandise.product.handle ===
                        item?.product?.handle
                    )

                    const isNotPrescribedItem = missingItems?.some(
                      missingItem => {
                        const missingHandle =
                          missingItem.node.merchandise.product?.handle
                        const currentHandle = item.product?.handle
                        return missingHandle === currentHandle
                      }
                    )

                    return (
                      <ProductItem
                        key={item.product?.id}
                        item={item}
                        abilityToManageQuantity={abilityToManageQuantity}
                        cartInfo={{
                          updateVariantToCart,
                          removeVariantsToCart,
                          nodeId: edge?.node?.id,
                          attributes: edge?.node?.attributes,
                        }}
                        pricing={{
                          subtotalAmount:
                            edge?.node?.estimatedCost?.subtotalAmount?.amount,
                          totalAmount:
                            edge?.node?.estimatedCost?.totalAmount?.amount,
                          discountPercentage: discountPercentage,
                        }}
                        isPatientCatalog={isPatientCatalog}
                        protocolId={protocolId}
                        prescriptionId={prescriptionId}
                        isNotPrescribedItem={
                          isNotPrescribedItem || enableLaboratoryInstructions
                        }
                      />
                    )
                  })}
                </ProductsContainer>
              </TakeContainer>
            )
          })}
        </div>
      )}
    </Container>
  )
}

export default MarketplacePrescriptionProductsList
