import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
  padding: 1.25rem;
  flex-direction: column;
  border-radius: 0.5rem;
  background: #e0f0e9;
  border: 0.0625rem solid #c1e0d4;
`
export const Left = styled.div`
  display: flex;
  align-items: center;
`
export const RightText = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const LeftText = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
`
export const MainInfo = styled.div`
  display: flex;
  align-items: center;
`
export const Description = styled.div`
  color: #080038;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.4375rem;
`
