import styled from 'styled-components/macro'

export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const ProductTitle = styled.div`
  display: flex;
  align-items: center;
  color: #393360;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Gill Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

export const ProductUnit = styled.div`
  color: #8f95b2;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const PhaseTable = styled.table`
  width: 100%;
  border: 0.0625rem solid #65b293;
  border-radius: 0.25rem;
`
export const HeaderCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.isIntakePeriod ? 'center' : 'start')};
  padding: ${props => (props.isFirstColumn ? '.75rem' : '.75rem .5rem')};
  color: #6b6688;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Gill Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: ${props => (props.isIntakePeriod ? '600' : '400')};
  line-height: 100%;
`
export const TableCell = styled.td`
  padding: 1rem 0.5rem;
  color: ${props => (props.isFirstColumn ? '#65b293' : '#393360')};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Gill Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: ${props => (props.isFirstColumn ? 600 : 400)};
  line-height: 100%;
  border-bottom: ${props =>
    props.isLastLine ? '0' : '.0625rem dashed #e7e9f4'};
`
