import { MutableRefObject, useEffect } from 'react'
import { smoothScrollTo } from './utils'

interface useScrollDropdownIntoViewParams {
  isDropdownOpen: boolean
  dropdownRef: MutableRefObject<HTMLDivElement>
}

const useScrollDropdownIntoView = ({
  isDropdownOpen,
  dropdownRef,
}: useScrollDropdownIntoViewParams) => {
  // Scroll the dropdown into view
  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      const dropdown = dropdownRef.current
      const offset = dropdown.getBoundingClientRect().height / 2
      const targetY =
        dropdown.getBoundingClientRect().top +
        window.scrollY -
        window.innerHeight / 2 +
        offset

      smoothScrollTo(window.scrollY, targetY, 800) // 800ms duration
    }
  }, [isDropdownOpen, dropdownRef])
}

export default useScrollDropdownIntoView
