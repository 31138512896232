import { IntakeProps } from './props'

import { IntakeQuantity, IntakeWrapper, NoIntakeWrapper } from './style'
import { IntakeAnyPeriodType } from '../../../../../../../services/api/types'
import { mapIntakeAnyPeriodToLabel } from '../../../../../../../components/prescription/product-list/productCardMarketplace/product-phases/utils'

const Intake = ({ intakes, period }: IntakeProps) => {
  const intakeWithFrequencyPeriod = intakes.find(intake =>
    intake.lines.some(line =>
      Object.values(IntakeAnyPeriodType).includes(
        line.period as IntakeAnyPeriodType
      )
    )
  )

  if (intakeWithFrequencyPeriod && !period) {
    return (
      <IntakeWrapper>
        {`${intakeWithFrequencyPeriod.quantity} ${
          intakeWithFrequencyPeriod.quantity === 1 ? 'unité' : 'unités'
        } ${mapIntakeAnyPeriodToLabel(
          intakeWithFrequencyPeriod.lines[0].period as IntakeAnyPeriodType
        )}`}
      </IntakeWrapper>
    )
  }

  const intake = intakes.find(intake =>
    intake.lines.some(line => line.period === period)
  )
  if (!intake) return <NoIntakeWrapper>-</NoIntakeWrapper>

  return (
    <IntakeWrapper>
      <IntakeQuantity>{intake.quantity}</IntakeQuantity>
    </IntakeWrapper>
  )
}

export default Intake
