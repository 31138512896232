import {
  IntakeAnyPeriodType,
  PrescriptionItemPhaseIntakeDTO,
  PrescriptionItemPhaseIntakeLineDTO,
  ProtocolIntakeDTO,
  ProtocolIntakeLineDTO,
} from '../../../../../../../services/api/types'
import {
  getIntakeSpecificTimingPeriodOptions,
  mapIntakePeriodToLabel,
} from '../../utils'

// Filter out the options if an option has already been selected in a different intake.
export function getFilteredIntakeSpecificTimingPeriodOptions(
  intakes: (PrescriptionItemPhaseIntakeDTO | ProtocolIntakeDTO)[],
  intakeIndex: number
) {
  return getIntakeSpecificTimingPeriodOptions().filter(
    option =>
      !intakes.some(
        (intake, index) =>
          index !== intakeIndex &&
          intake.lines.some(line => line.period === option.value)
      )
  )
}

export function renderTriggerButtonName(
  selectedPeriods: (
    | PrescriptionItemPhaseIntakeLineDTO
    | ProtocolIntakeLineDTO
  )[]
) {
  if (selectedPeriods.length === 0) return 'Select '
  return selectedPeriods
    .map(item => mapIntakePeriodToLabel(item.period))
    .join(', ')
}

export function hasLineAnAnyIntakePeriod(
  line: PrescriptionItemPhaseIntakeLineDTO
) {
  return Object.values(IntakeAnyPeriodType).includes(
    line.period as IntakeAnyPeriodType
  )
}
