import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from '../../../../utils/hooks/getQuery'
import { toast } from 'react-toastify'
import DilaogCreateOrderForCustomer from '../../../../components/prescription/dilaog-create-order-for-customer'
import { Button } from '../../../../components/ui'
import { usePushPrescriptionStatus } from '../../../../services/api/prescriptions/prescriptions'
import { getPatient } from '../../../../services/api/patients/patients'
import {
  PrescriptionStatus,
  PushPrescriptionStatus,
} from '../../../../services/api/types'
import { getRecommendationLink, processTake } from '../../../../utils/helpers'
import { useInitCart } from '../../../../utils/hooks'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { lineItemsForCart } from '../../utils'
import { PrescriptionFooterProps } from './props'
import { ActionSection } from './style'
import { translatePatientSex } from '../../../../utils/helpers'
import axios from 'axios'

const PrescriptionFooter = ({
  prescription,
  lastStatus,
  refetchPrescription,
}: PrescriptionFooterProps) => {
  // Attributes
  const history = useHistory()
  const query = useQuery()

  const { data: prescriber } = useGetMe()

  const [isDilaogCreateOrderForCustomerOpen, setDilaogCreateOrderForCustomer] =
    useState(false)
  const [checkoutUrl, setCheckoutUrl] = useState<string>()

  const discountCode = prescription?.discount?.code
  const prescriptionRecoLink = getRecommendationLink(prescription?.id)
  const [isSendPrescriptionLoading, setIsSendPrescriptionLoading] =
    useState(false)

  async function sendPrescription(data) {
    setIsSendPrescriptionLoading(true)
    await axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .then(onPushSentStatus)
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'envoi de l'email.")
      )
      .finally(() => setIsSendPrescriptionLoading(false))
  }

  const { createCart } = useInitCart()

  const { mutateAsync: pushStatus, isLoading: pushPrescriptionStatusLoading } =
    usePushPrescriptionStatus({
      mutation: {
        onSuccess: () => {
          toast.success(
            `L'email a été envoyé à ${prescription?.patient?.email} avec succès !`
          )
          refetchPrescription()
        },
        onError: () => {
          toast.error(
            'Une erreur est survenue lors de la mise à jours du status de la prescription.'
          )
        },
      },
    })

  // Functions
  async function onPushSentStatus() {
    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const newTakes = await Promise.all(
      lastStatus.takes.map(take => processTake(take, prescription?.id))
    )

    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescription?.id,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  async function onSendPrescription() {
    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    sendPrescription({
      patient_lastname: prescription?.patient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      patient_email: prescription?.patient?.email,
      patient_firstname: prescription?.patient?.firstName,
      patient_phonenumber: prescription?.patient?.phone,
      custom_message: prescription?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      purchase_url: prescriptionRecoLink,
      promo_code: prescription?.discount?.code,
      discount: prescription?.discount?.percentage,
    })
  }

  // Effects
  useEffect(() => {
    if (
      !prescription?.id ||
      !prescriber?.giftCard ||
      !lastStatus?.takes?.length
    )
      return

    // Create shopify cart
    const lineItems = lineItemsForCart(
      prescription?.id,
      lastStatus?.takes,
      prescription?.recommendedDuration
    )
    createCart({
      variables: {
        cartInput: {
          buyerIdentity: {
            email: '',
          },
          lines: lineItems,
          attributes: [
            {
              key: 'prescription_id',
              value: `${prescription?.id}`,
            },
            {
              key: 're_ordered',
              value: prescription?.statuses?.find(
                s => s.status === PrescriptionStatus.ORDERED
              )
                ? 'true'
                : 'false',
            },
          ],
          discountCodes: [discountCode],
        },
      },
    }).then((cart: any) => {
      cart = cart?.data?.cartCreate?.cart
      // Get checkout url
      let checkoutUrl = cart?.checkoutUrl
      if (discountCode) {
        checkoutUrl += `?discount=${discountCode}`
      } else {
        checkoutUrl += `?attributes[prescription_id]=${prescription?.id}`
      }
      setCheckoutUrl(checkoutUrl)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription?.id, lastStatus, prescriber?.giftCard])

  function showButtonText() {
    if (lastStatus?.status === PrescriptionStatus.SENT) {
      return 'Renvoyer'
    }

    return 'Envoyer'
  }

  // Render
  return (
    <ActionSection>
      {query.get('from') === 'new' ? (
        <Button
          appearance='minimal'
          onClick={() =>
            history.push({
              pathname:
                prescription.type === 'COMPLIMENT'
                  ? '/compliment-prescriptions/new'
                  : '/prescriptions/new',
              search: `?step=2&prescriptionId=${prescription.id}&action=update&patientId=${prescription?.patient?.id}`,
            })
          }
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Retour
        </Button>
      ) : (
        <Button
          appearance='minimal'
          onClick={() => history.goBack()}
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Retour
        </Button>
      )}

      <DilaogCreateOrderForCustomer
        isDilaogCreateOrderForCustomerOpen={isDilaogCreateOrderForCustomerOpen}
        setDilaogCreateOrderForCustomer={setDilaogCreateOrderForCustomer}
        giftCard={prescriber?.giftCard}
        checkoutUrl={checkoutUrl}
      />

      <Button
        onClick={onSendPrescription}
        isDisabled={
          lastStatus?.status !== PrescriptionStatus.CREATED &&
          lastStatus?.status !== PrescriptionStatus.MODIFIED &&
          lastStatus?.status !== PrescriptionStatus.SENT
        }
        isLoading={isSendPrescriptionLoading || pushPrescriptionStatusLoading}
        appearance='primary'
        iconLeft={undefined}
        isActive={undefined}
      >
        {showButtonText()}
      </Button>
    </ActionSection>
  )
}

export default PrescriptionFooter
