import { useState } from 'react'
import { Container, Header, Button } from './style'
import { toast } from 'react-toastify'
import { BackButton, Dialog } from '../../ui'
import { selectPackagingTypeFromPrescriptionType } from '../../../utils/helpers'
import { PageType, ViewMode, viewModeFilter } from '../../../utils/constants'
import { IProductSLiderProps } from './props'
import Products from '../../products-v2'
import PageTitle from '../../section/page-title'
import RightElements from '../../products-v2/right-elements'
import { FavoriteProduct } from '../../products-v2/favorite/favorite-list'
import { useGetMe } from '../../../utils/hooks/getMe'

const ProductSlider2 = ({
  isDialogOpen,
  setIsDialogOpen,
  addProductToTake,
  prescriptionType,
  draft,
  page,
  type,
  currentFavoriteListName,
  favoriteId,
  favoriteLists,
  refetchCurrentFavoriteList,
}: IProductSLiderProps) => {
  // Attributes
  const [nav, setNav] = useState<'Catalogue' | 'Favoris'>('Catalogue')
  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState<boolean>(true)
  const [viewMode, setViewMode] = useState(ViewMode.GRID)

  // Queries
  const { data: prescriber } = useGetMe()

  // Functions
  const onProductClicked = (product, quantity = 1) => {
    addProductToTake(product, 0, quantity)
    toast.success('Le produit a bien été ajouté')
  }

  const onFavoriteProductClicked = (product, quantity = 1) => {
    addProductToTake(product, 0, quantity)
    toast.success('Le produit a bien été ajouté')
  }

  function handleClickNav(nav: 'Catalogue' | 'Favoris') {
    if (nav === 'Catalogue') {
      setNav('Favoris')
    } else {
      setNav('Catalogue')
    }
  }

  function getTextToDisplay(nav: 'Catalogue' | 'Favoris'): string {
    return nav === 'Catalogue' ? 'Mes favoris' : 'Catalogue'
  }

  // Render
  return (
    <Dialog
      type='aside'
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      styleContainer={null}
      width='100vw'
      closeOnOverlayClick={isInnerDialogOpen}
      noPadding={true}
    >
      <Header>
        {type === PageType.FAVORITE ? (
          <BackButton
            style={{ width: 'fit-content', padding: '1rem' }}
            onClick={() => setIsDialogOpen(false)}
            buttonText={`Retourner à mes favoris`}
          />
        ) : (
          <BackButton
            style={{ width: 'fit-content', padding: '1rem' }}
            onClick={() => setIsDialogOpen(false)}
            buttonText={`Retourner à ${
              page === 'recommandation'
                ? ' ma recommandation'
                : ' mon protocole'
            }`}
          />
        )}
      </Header>
      <Container>
        <PageTitle
          title={
            type === PageType.FAVORITE
              ? ''
              : `Bonjour ${prescriber.firstName} 👋`
          }
          subtitleInfo={
            type === PageType.FAVORITE
              ? `Ajoutez des produits ${
                  currentFavoriteListName ? 'à ' + currentFavoriteListName : ''
                }`
              : 'Que recherchez vous?'
          }
          rightSecondElements={
            type !== PageType.FAVORITE ? (
              <Button onClick={() => handleClickNav(nav)}>
                {getTextToDisplay(nav)}
              </Button>
            ) : null
          }
          rightElements={
            <RightElements viewMode={viewMode} setViewMode={setViewMode} />
          }
          titleClassName='custom-title-class'
          styleContent={{ maxWidth: '100%', padding: '0' }}
        />
        {nav === 'Catalogue' && (
          <Products
            packagingType={selectPackagingTypeFromPrescriptionType(
              prescriptionType
            )}
            onProductClicked={onProductClicked}
            viewModeProduct={ViewMode.SLIDE}
            viewModeFilter={viewModeFilter.COLUMN}
            setIsInnerDialogOpen={setIsInnerDialogOpen}
            stickyTop='-25px'
            type={type}
            favoriteId={favoriteId}
            favoriteLists={favoriteLists}
            refetchCurrentFavoriteList={refetchCurrentFavoriteList}
            excludeAffiliated={false}
          />
        )}
        {nav === 'Favoris' && (
          <FavoriteProduct
            onProductClicked={onFavoriteProductClicked}
            addProduct={undefined}
            takes={draft.takes}
            recommendedDuration={draft.recommendedDuration || '1-month'}
            packagingType='ORIGINAL'
            mode={ViewMode.SLIDE}
            setIsInnerDialogOpen={setIsInnerDialogOpen}
          />
        )}
      </Container>
    </Dialog>
  )
}

export default ProductSlider2
