import { Na } from '../style'
import { DosagePercentageRow } from './dosage-percentage-row'
import { MaxDosageCellProps } from './props'

export const MaxDosageCell = ({ dosageMax }: MaxDosageCellProps) => {
  if (!dosageMax?.value) {
    return <Na>Na</Na>
  }

  return (
    <div>
      {dosageMax?.percentages?.map((percentage, index) => (
        <DosagePercentageRow
          key={index}
          percentage={percentage}
          value={dosageMax.value}
        />
      ))}
    </div>
  )
}
