import { AffiliationText, AffiliationWrapper, Container } from './styles'
import IconLinkS from '../../../ui/icon/icons/IconLinkS'
import { AffiliationLinkProps } from './props'
import Tooltip from '../../../ui/tooltip'

const AffiliationLink = ({
  externalUrl,
}: AffiliationLinkProps) => {
  return (
    <Container>
      <Tooltip
        label='Ces produits peuvent être recommandés au patient, mais devront être achetés directement sur le site du laboratoire.'
        size={'xl'}
        placement={'bottom'}

      >
        <AffiliationWrapper>
          <IconLinkS />

          <AffiliationText
          >
            Recommandation sans achat
          </AffiliationText>
        </AffiliationWrapper>
      </Tooltip>
    </Container>
  )
}

export default AffiliationLink
