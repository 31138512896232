import { SVGProps } from 'react'

const IconMore = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      {...props}
    >
      <path
        d='M8.00001 13.4845C8.3682 13.4845 8.66668 13.186 8.66668 12.8179C8.66668 12.4497 8.3682 12.1512 8.00001 12.1512C7.63182 12.1512 7.33334 12.4497 7.33334 12.8179C7.33334 13.186 7.63182 13.4845 8.00001 13.4845Z'
        stroke='#6B6688'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00001 8.81783C8.3682 8.81783 8.66668 8.51935 8.66668 8.15116C8.66668 7.78297 8.3682 7.4845 8.00001 7.4845C7.63182 7.4845 7.33334 7.78297 7.33334 8.15116C7.33334 8.51935 7.63182 8.81783 8.00001 8.81783Z'
        stroke='#6B6688'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00001 4.1512C8.3682 4.1512 8.66668 3.85273 8.66668 3.48454C8.66668 3.11635 8.3682 2.81787 8.00001 2.81787C7.63182 2.81787 7.33334 3.11635 7.33334 3.48454C7.33334 3.85273 7.63182 4.1512 8.00001 4.1512Z'
        stroke='#6B6688'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconMore
