import styled from 'styled-components/macro'

export const Container = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0'};
`

export const AffiliationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const AffiliationText = styled.p`
  color: #393360;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
